.body footer {
  position: relative;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  background-color: #666666;
  border-top-left-radius: 80px;
  z-index: 3; }
  .body footer .row:not(.copyright) {
    justify-content: space-between; }
  .body footer .h5, .body footer p, .body footer a {
    font-size: 14px;
    font-weight: 300;
    color: #FFF;
    letter-spacing: 0;
    line-height: 26px; }
  .body footer p, .body footer a {
    display: block;
    margin-bottom: 1px; }
  .body footer .h5 {
    font-weight: 400;
    margin-bottom: 8px; }
  .body footer .inner-social a:nth-child(2), .body footer .inner-social a:nth-child(3) {
    display: inline-block;
    margin-right: 1px; }
  .body footer:after {
    content: '';
    position: absolute;
    display: block;
    width: 80px;
    height: 80px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto; }
  .body footer:after {
    background-image: url("../img/icons/footer-after.svg");
    top: -79px;
    right: 0; }
  .body footer .copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.3); }
    .body footer .copyright p, .body footer .copyright a {
      font-size: 12px;
      line-height: 22px; }
    .body footer .copyright .col-sm-8 a {
      display: flex;
      align-items: flex-end;
      line-height: 1; }
      .body footer .copyright .col-sm-8 a img {
        vertical-align: baseline; }
      .body footer .copyright .col-sm-8 a:hover {
        text-decoration: none; }
    .body footer .copyright .col-sm-8 p {
      line-height: 1; }
      .body footer .copyright .col-sm-8 p img {
        vertical-align: baseline; }
    .body footer .copyright .cards {
      margin-top: 9px; }
