.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.text-underline {
  text-align: underline !important; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -ms-transform: translateY(100px);
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px); }
  50% {
    opacity: .3; }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -ms-transform: translateY(100px);
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px); }
  50% {
    opacity: .3; }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

.fadeInUp {
  opacity: 0;
  -ms-transform: translateY(100px);
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -o-transform: translateY(100px);
  transform: translateY(100px); }

.fadeInUp.go {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.d-250 {
  -webkit-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -ms-animation-delay: 0.25s; }

.d-500 {
  -webkit-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s; }

.d-750 {
  -webkit-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s; }
