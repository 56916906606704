.page-invitation h3 {
  margin-bottom: 16px; }

.page-invitation h4 {
  line-height: 42px;
  margin-bottom: 34px; }

.page-invitation .col-sm-6 {
  margin-bottom: 22px !important; }
  .page-invitation .col-sm-6 p {
    padding: 0 15px;
    margin-bottom: 37px; }

.page-invitation .form-group-submit {
  margin-bottom: 10px !important; }
