.page-donations .has-donation {
  display: block; }

.page-donations .no-donation {
  display: none; }

.page-donations .box-container {
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
  padding-bottom: 0; }

.page-donations .donation-title {
  margin-bottom: 30px; }

.page-donations .donation-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.page-donations .donation-container:nth-child(odd) {
  position: relative;
  background-color: #FFF;
  border-radius: 0 0 80px 0;
  padding: 0 35px 12px; }

.page-donations .donation-container:nth-child(even) {
  position: relative;
  background-color: #E7F8F8;
  border-radius: 80px 0 0 0;
  padding: 25px 35px 35px;
  margin-bottom: 30px; }
  .page-donations .donation-container:nth-child(even):after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -150px;
    width: 100%;
    height: 150px;
    background-color: #E7F8F8;
    z-index: -1; }

.page-donations .donation-older {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .page-donations .donation-older .hys {
    display: none; }

.page-donations .filter-container {
  display: flex;
  align-items: center;
  margin-bottom: 13px; }
  .page-donations .filter-container p {
    font-size: 20px; }
    .page-donations .filter-container p span {
      font-size: 16px; }
  .page-donations .filter-container .buttons {
    display: flex;
    margin-left: auto; }
    .page-donations .filter-container .buttons .show-popup {
      cursor: pointer;
      display: inherit;
      margin-left: 10px; }

.page-donations .user-box {
  display: flex;
  background-color: #FFF;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
  padding: 18px 10px 13px; }
  .page-donations .user-box .left {
    display: flex;
    align-items: center;
    max-width: 83px;
    flex-basis: 83px; }
    .page-donations .user-box .left img {
      border: 2px solid #18B8C4; }
    .page-donations .user-box .left-name {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      max-width: 70px;
      min-width: 70px;
      height: 70px;
      font-size: 33px;
      font-weight: 500;
      color: rgba(24, 184, 196, 0.36);
      border: 2px solid #18B8C4;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: padding-box; }
  .page-donations .user-box .right {
    position: relative;
    max-width: calc(100% - 83px);
    flex-basis: calc(100% - 83px);
    padding-right: 30px;
    margin-top: -2px; }
    .page-donations .user-box .right .name {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: -2px; }
    .page-donations .user-box .right .country {
      font-size: 16px;
      border-bottom: 1px solid rgba(138, 138, 138, 0.5);
      padding-bottom: 3px;
      margin-bottom: 5px; }
    .page-donations .user-box .right .amount {
      font-size: 24px;
      font-weight: 500;
      color: #647AA0;
      line-height: 32px;
      padding-right: 35px;
      margin-bottom: -4px; }
    .page-donations .user-box .right .paywall-package {
      font-size: 10px;
      line-height: 1.2;
      font-weight: 600; }
    .page-donations .user-box .right .date {
      font-size: 16px;
      padding-right: 35px; }
    .page-donations .user-box .right .img-recurr {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 12px; }
      .page-donations .user-box .right .img-recurr.cursor-default {
        cursor: default !important; }
  .page-donations .user-box.show-popup {
    cursor: pointer; }
  .page-donations .user-box-container {
    width: 100%;
    margin-bottom: 20px; }

.page-donations .user-box-new .user-box {
  display: block;
  height: 100%;
  padding: 15px 10px 10px; }
  .page-donations .user-box-new .user-box .content-top, .page-donations .user-box-new .user-box .content-center {
    border-bottom: 1px solid #EFEFEF; }
  .page-donations .user-box-new .user-box .content-top {
    padding-bottom: 11px; }
  .page-donations .user-box-new .user-box .content-center {
    padding-top: 13px;
    padding-bottom: 11px; }
    .page-donations .user-box-new .user-box .content-center-inner {
      display: table;
      position: relative;
      margin-right: auto;
      margin-left: auto; }
  .page-donations .user-box-new .user-box .content-bottom {
    display: flex;
    justify-content: space-around;
    padding-top: 15px; }
    .page-donations .user-box-new .user-box .content-bottom-button {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      color: #666666;
      line-height: 1.375;
      text-align: center; }
      .page-donations .user-box-new .user-box .content-bottom-button img {
        margin-bottom: 14px; }
      .page-donations .user-box-new .user-box .content-bottom-button:hover {
        text-decoration: none; }
  .page-donations .user-box-new .user-box .img-circle {
    display: table;
    width: 50px;
    height: 50px;
    border: 2px solid #18B8C4;
    margin-right: auto;
    margin-bottom: 18px;
    margin-left: auto; }
  .page-donations .user-box-new .user-box .left-name {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    max-width: 50px;
    min-width: 50px;
    height: 50px;
    font-size: 20px;
    margin-left: auto;
    margin-bottom: 18px;
    margin-right: auto; }
  .page-donations .user-box-new .user-box .name {
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    line-height: 1; }
  .page-donations .user-box-new .user-box .country {
    font-size: 16px; }
  .page-donations .user-box-new .user-box .amount {
    font-size: 18px;
    font-weight: 700;
    color: #647AA0; }
  .page-donations .user-box-new .user-box .date {
    font-size: 16px; }
  .page-donations .user-box-new .user-box .img-recurr {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -35px; }

.page-donations-choose .profil {
  width: 100%;
  margin-right: 0;
  margin-left: 0; }

.page-donations .notyet .has-donation {
  display: none; }

.page-donations .notyet .no-donation {
  display: block; }

.page-donations .notyet .donation-outgoing:after {
  height: 130px; }

.page-donations .notyet .donation-incoming .user-box {
  background-color: transparent; }

.page-donations .notyet .user-box {
  justify-content: center;
  background-color: transparent;
  box-shadow: none; }
