@font-face {
  font-family: 'donably';
  src: url("fonts/donably.eot");
  src: url("fonts/donably.eot?#iefix") format("embedded-opentype"), url("fonts/donably.woff") format("woff"), url("fonts/donably.ttf") format("truetype"), url("fonts/donably.svg#donably") format("svg");
  font-weight: normal;
  font-style: normal; }

[class*='dona-']:before {
  display: inline-block;
  font-family: 'donably';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.dona-dashboard:before {
  content: '\0041'; }

.dona-settings:before {
  content: '\0042'; }

.dona-profil:before {
  content: '\0043'; }

.dona-Group 4232:before {
  content: '\0044'; }

.dona-logout:before {
  content: '\0045'; }

.dona-subscriptions:before {
  content: '\0046'; }

.dona-support:before {
  content: '\0047'; }

.dona-messages:before {
  content: '\0048'; }

.dona-information:before {
  content: '\0049'; }

.dona-finance:before {
  content: '\004a'; }

.dona-rocket:before {
  content: '\004b'; }

.dona-email:before {
  content: '\004c'; }

.dona-post:before {
  content: '\004d'; }

.dona-widget:before {
  content: '\004e'; }

.dona-help:before {
  content: '\004f'; }

.dona-paywall:before {
  content: '\0050'; }
