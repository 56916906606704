.page-blog-page {
  padding-top: 50px; }
  .page-blog-page-header {
    margin-bottom: 45px; }
    .page-blog-page-header h2 {
      text-align: center;
      margin-bottom: 14px; }
    .page-blog-page-header .date {
      text-align: center; }
  .page-blog-page-footer {
    display: flex;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid #8A8A8A;
    padding-top: 46px;
    padding-bottom: 54px; }
    .page-blog-page-footer a {
      margin-right: 15px; }
    .page-blog-page-footer .scroll-to-top {
      margin-right: 0;
      margin-left: auto; }
  .page-blog-page ul, .page-blog-page ol {
    color: #666666;
    list-style-position: inside; }
    .page-blog-page ul li, .page-blog-page ol li {
      display: list-item;
      margin-bottom: 0; }
  .page-blog-page ul {
    list-style-type: disc; }
  .page-blog-page ol {
    list-style-type: decimal; }
  .page-blog-page h3 {
    color: #647AA0; }
  .page-blog-page p img {
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box;
    margin-bottom: 0; }

.recommender {
  padding-top: 40px; }
  .recommender h2 {
    text-align: center;
    margin-bottom: 50px; }
  .recommender-inner {
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
    padding-bottom: 19px; }
  .recommender-picture {
    display: block;
    margin-bottom: 20px; }
    .recommender-picture img {
      display: inherit;
      width: 100%;
      -webkit-border-radius: 26px;
      -moz-border-radius: 26px;
      border-radius: 26px;
      background-clip: padding-box; }
  .recommender-title, .recommender-date {
    padding: 0 15px; }
  .recommender-title {
    display: block;
    font-weight: 500;
    color: #666666;
    margin-bottom: 21px; }
    .recommender-title:hover, .recommender-title:active, .recommender-title:focus, .recommender-title.active {
      text-decoration: none; }
  .recommender-date {
    display: block; }
