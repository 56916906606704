.news-item {
  background-color: #E7F8F8; }

.news-picture {
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center; }
  .news-picture:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(100, 122, 160, 0.5);
    z-index: 1; }
  .news-picture .hero {
    position: relative;
    display: block;
    color: #FFF;
    text-align: center;
    z-index: 2; }
    .news-picture .hero:hover {
      text-decoration: none; }

.news-content {
  position: relative;
  top: -46px;
  background-color: #FFF;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background-clip: padding-box;
  padding: 19px 20px 21px;
  z-index: 3; }
  .news-content .date {
    display: block;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 1px; }
  .news-content .link {
    display: table;
    margin-left: auto;
    margin-right: 0; }
