@media screen and (min-width: 576px) {
  .headline .h1 {
    max-width: 540px;
    margin: 0 auto;
    padding: 0 30px; }
  .container {
    max-width: 540px;
    padding: 0 15px; }
  .popup {
    max-width: 600px; }
    .popup-donation {
      max-width: 1060px; }
      .popup-donation form .col-row {
        flex-direction: row;
        justify-content: unset; }
        .popup-donation form .col-row .form-checkbox {
          max-width: 65%;
          flex-basis: 65%; }
        .popup-donation form .col-row .text-help {
          max-width: 35%;
          flex-basis: 35%;
          text-align: left;
          margin-top: 0; }
  .input-countrycode-group {
    max-width: 415px;
    width: 100%; }
  .buttons .hide-mobil {
    display: inline-block;
    margin-right: 3px; }
  .btn-secondary .btn-bg-w {
    max-width: 100%; }
  .navbar-extra {
    margin-right: 0; }
  .navbar .container {
    position: relative; }
  .navbar .menu {
    position: absolute; }
  .picture-container {
    max-width: 300px; }
  .croppie-buttons,
  .croppie-container .cr-slider-wrap {
    width: 300px; }
  .content-lead {
    max-width: 922px; }
  .page-registration-step1 .form-channellink {
    margin-top: 30px; }
    .page-registration-step1 .form-channellink .form-label {
      margin-top: -11px; }
  .page-donations-public .socials-links {
    max-width: 412px;
    margin-left: auto;
    margin-right: auto; }
  .page-help .container {
    padding: 0 15px; }
  .page-help .contact-form .form-100 .col-sm-6 {
    max-width: 50%;
    flex-basis: 50%; }
    .page-help .contact-form .form-100 .col-sm-6:last-child img {
      max-width: 100%; }
  .page-main-compare .two-column-image .btn-anim {
    margin-top: 30px; }
  .page-services-circle {
    top: -115px;
    bottom: unset;
    height: calc(100% + 115px); }
  .page-fanpage .fanpage-header {
    height: 285px; }
  .page-fanpage .fanpage-avatar {
    margin: -48px auto 31px; }
  .page-fanpage .fanpage-donators {
    text-align: left; }
  .page-fanpage .fanpage-social-left {
    display: block;
    max-width: 140px;
    flex-basis: 140px;
    text-align: left;
    margin-bottom: 0; }
  .page-fanpage .fanpage-social-right {
    max-width: calc(100% - 140px);
    flex-basis: calc(100% - 140px);
    padding-left: 17px; }
    .page-fanpage .fanpage-social-right a {
      margin: 0 11px 10px; }
      .page-fanpage .fanpage-social-right a:nth-child(4n+4) {
        margin-right: 0; }
  .page-fanpage .fanpage-social-bottom {
    max-width: 335px;
    flex-direction: row;
    align-items: unset;
    margin: 63px auto 0; }
    .page-fanpage .fanpage-social-bottom .btn-anim {
      max-width: 155px;
      margin-bottom: 0; }
  .fanpage-submenu {
    left: 0;
    -ms-transform: translate(0, 10px);
    -webkit-transform: translate(0, 10px);
    -moz-transform: translate(0, 10px);
    -o-transform: translate(0, 10px);
    transform: translate(0, 10px); }
    .fanpage-submenu.sys {
      min-width: 155px;
      -ms-transform: translate(0);
      -webkit-transform: translate(0);
      -moz-transform: translate(0);
      -o-transform: translate(0);
      transform: translate(0); }
  .post-locked img {
    margin-bottom: 26px; }
  .post-locked p {
    font-size: 24px;
    margin-bottom: 36px; }
  .profil-buttons {
    flex-direction: row;
    margin-bottom: 51px; }
    .profil-buttons a {
      margin-bottom: 0; }
      .profil-buttons a:first-child {
        margin-left: auto;
        margin-right: 15px; }
      .profil-buttons a:last-child {
        margin-right: auto;
        margin-left: 15px; }
  .profil-bottom-buttons {
    flex-direction: row;
    align-items: flex-start; }
    .profil-bottom-buttons .btn {
      margin: 0 10px; }
  .services-box {
    padding-bottom: 25px; }
  .services-content {
    padding: 0 20px 40px; }
    .services-content h2 {
      margin-bottom: 43px; }
  .services-picture {
    margin-bottom: 45px; }
  .cookie-left {
    max-width: 80%;
    flex-basis: 80%;
    margin-bottom: 0; }
  .cookie-right {
    max-width: 20%;
    flex-basis: 20%; }
  .tooltip {
    max-width: 395px;
    transform: translateX(20px); }
  .body footer .inner {
    max-width: 50%;
    flex-basis: 50%; } }
