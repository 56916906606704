#autocomplete {
  display: none;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 44px;
  width: calc(100% - 30px);
  max-height: 225px;
  overflow: auto;
  background-color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
  list-style-type: none;
  padding-left: 0;
  z-index: 100; }
  #autocomplete li {
    cursor: pointer;
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    padding: 0 10px; }
    #autocomplete li:hover {
      color: #18B8C4;
      background-color: #E7F8F8; }
    #autocomplete li:before {
      display: none; }
