.tooltip {
  position: absolute;
  width: calc(100% - 30px); }
  .tooltip:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -18px;
    width: 27px;
    height: 18px; }
  .tooltip-inner {
    position: relative;
    background-color: #FFF;
    border: 1px solid #18B8C4;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 20px; }
  .tooltip-content {
    padding: 10px; }
    .tooltip-content, .tooltip-content * {
      font-size: 12px;
      font-weight: 300;
      color: #666666;
      line-height: 14px; }
    .tooltip-content strong {
      font-weight: 600; }
    .tooltip-content ul {
      margin: 10px 0; }
    .tooltip-content a {
      color: #18B8C4; }
