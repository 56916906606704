.page-compare-twocolumn p:first-child {
  margin-bottom: 30px; }

.page-compare-content {
  max-width: 1170px;
  padding-top: 30px;
  margin: 0 auto; }
  .page-compare-content h2 {
    margin-bottom: 30px; }
  .page-compare-content .text-content {
    max-width: 960px;
    margin: 0 auto 50px; }
    .page-compare-content .text-content h2 {
      margin-bottom: 40px; }
    .page-compare-content .text-content p {
      margin-bottom: 30px; }
  .page-compare-content .row {
    margin-left: 0;
    margin-right: 0; }
    .page-compare-content .row.row-first {
      padding-bottom: 51px; }
    .page-compare-content .row-compare .col-xs-12:last-child {
      align-self: flex-end; }

.page-compare .rounded {
  -webkit-border-radius: 80px 0 80px 80px;
  -moz-border-radius: 80px 0 80px 80px;
  border-radius: 80px 0 80px 80px;
  background-clip: padding-box;
  padding-top: 47px;
  padding-bottom: 52px; }
  .page-compare .rounded:before {
    top: -79px;
    width: 80px;
    height: 80px; }

.column-title .column-row {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  color: #666666;
  text-align: right; }
  .column-title .column-row:last-child {
    margin-bottom: 0; }

.column-data {
  position: relative;
  text-align: center; }
  .column-data:before {
    content: '';
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 3px;
    height: 100%;
    background-image: url("../img/compare-column-bg.svg");
    background-position: center top;
    background-repeat: repeat-y;
    background-size: contain;
    z-index: 1; }
  .column-data .column-row {
    position: relative;
    font-weight: 500;
    z-index: 2; }
    .column-data .column-row:last-child {
      margin-bottom: 0; }

.column-row .currency {
  font-weight: 500; }

.column-row .inner {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #F8F8F8;
  border: 1px solid #ADADAD;
  padding: 5px 0; }
  .column-row .inner div {
    max-width: 100%;
    flex-basis: 100%; }

.column-row p {
  font-weight: 500;
  color: #666666; }

.column-row.white, .column-row.white .currency {
  color: #666666; }

.column-row.green, .column-row.green .currency {
  color: #18B8C4; }

.column-row.yellow, .column-row.yellow .currency {
  color: #FBCE4A; }

.column-row.pink, .column-row.pink .currency {
  color: #FFB2B2; }

.column-row.better {
  position: relative;
  color: #18B8C4; }
  .column-row.better .inner {
    background-color: #E7F8F8;
    border: none; }
    .column-row.better .inner:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-color: transparent;
      border: 1px solid #FF0000;
      margin-top: 1px; }
    .column-row.better .inner:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-color: transparent;
      border: 3px solid #FFF;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background-clip: padding-box;
      margin-top: 1px; }
  .column-row.better img {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px; }
  .column-row.better .currency {
    color: #18B8C4;
    margin-top: -1px; }

.d-tab {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1170px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto; }
  .d-tab-container {
    display: none; }
    .d-tab-container.active {
      display: block; }
    .d-tab-container:before {
      display: none; }
  .d-tab-item {
    cursor: pointer;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    max-width: calc(50% - 2px);
    flex-basis: calc(50% - 2px);
    background-color: #E7F8F8;
    border: 1px solid #E7F8F8;
    border-bottom: none;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-clip: padding-box;
    -ms-transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -o-transform: translateY(5px);
    transform: translateY(5px); }
    .d-tab-item, .d-tab-item span {
      font-weight: 300;
      color: #666666; }
    .d-tab-item.active {
      background-color: #FFF;
      border-color: #E7E9ED;
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
      .d-tab-item.active, .d-tab-item.active span {
        font-weight: 600;
        color: #18B8C4; }
