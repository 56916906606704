@media screen and (min-width: 1200px) {
  .navbar-nav li {
    margin: 0 10px; }
    .navbar-nav li.li-country {
      margin-right: 0; }
    .navbar-nav li a, .navbar-nav li .navbar-clickable {
      padding: 10px; }
  .container {
    max-width: 1140px; }
  .column-title {
    max-width: calc(100% - (3 * 280px));
    flex-basis: calc(100% - (3 * 280px)); }
  .column-data {
    max-width: 280px;
    flex-basis: 280px; }
  .page-main-support {
    padding-top: 59px; }
  .page-main-change {
    padding-top: 70px; }
  .page-main-foryou {
    padding-top: 70px;
    padding-bottom: 70px; }
  .page-services br {
    display: block; }
  .page-compare-content .text-content h2 {
    text-align: center; }
  .page-fanpage .fanpage-avatar {
    margin: -70px auto 31px; }
  .page-donations .user-box-container {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
  .post-item {
    width: 525px; }
  .post-video-thumbnail {
    min-height: 278px; }
    .post-video-thumbnail iframe {
      min-height: 278px; }
  .post-actions {
    position: absolute;
    top: 30px;
    right: 0;
    text-align: left;
    background-color: #FFF;
    -webkit-border-radius: 18px 0 0 18px;
    -moz-border-radius: 18px 0 0 18px;
    border-radius: 18px 0 0 18px;
    background-clip: padding-box;
    box-shadow: rgba(138, 138, 138, 0.5) 0 2px 15px;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    -ms-transform: translateX(150px);
    -webkit-transform: translateX(150px);
    -moz-transform: translateX(150px);
    -o-transform: translateX(150px);
    transform: translateX(150px);
    padding: 10px 10px;
    margin-bottom: 0; } }

@media screen and (min-width: 1366px) {
  .row-content {
    max-width: 998px; }
  .container {
    max-width: 1310px; }
  .page-fanpage .fanpage-header {
    height: 460px; }
  .page-fanpage .fanpage-avatar {
    width: 160px;
    height: 160px;
    margin: -86px auto 31px; }
  .page-help .gyik-content {
    max-width: 998px; }
  .page-help .help-box-text {
    font-size: 20px; }
  .post-item {
    width: 640px; }
  .post-video-thumbnail {
    min-height: 343px; }
    .post-video-thumbnail iframe {
      min-height: 343px; } }

@media screen and (min-width: 1681px) {
  .page-compare-content .row {
    padding-left: 30px;
    padding-right: 30px; }
  .column-title .column-row {
    min-height: 100px;
    font-size: 28px;
    margin-bottom: 30px; }
  .column-logo {
    min-height: 100px; }
    .column-logo img {
      height: 54px;
      margin-bottom: 15px; }
  .column-data .column-row {
    margin-bottom: 30px; }
  .column-data:before {
    top: 112px; }
  .column-row p {
    font-size: 28px; }
  .column-row .currency {
    font-size: 30px;
    margin-top: 5px; }
  .column-row .inner {
    font-size: 32px;
    min-height: 100px; }
    .column-row .inner div {
      font-size: 32px; }
      .column-row .inner div .currency {
        font-size: 30px; }
  .column-row.better .inner:before {
    border-width: 5px; }
  .column-row.better .inner:after {
    width: calc(100% - 17px);
    height: calc(100% - 17px); }
  .column-row.better img {
    width: 28px; } }
