@media screen and (min-width: 10px) {
  .hero {
    font-size: 21px; }
  h1, .h1 {
    font-size: 36px;
    line-height: 39px; }
  h2, .h2 {
    font-size: 30px; }
  h3, .h3 {
    font-size: 18px;
    line-height: 28px; }
  h4, .h4 {
    font-size: 18px; }
  html.user-loggedin .menu {
    display: none; }
    html.user-loggedin .menu-user {
      display: flex; }
  .body {
    padding-top: 60px; }
  .container {
    max-width: 100%; }
  .hide-mobil {
    display: none; }
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    min-height: 400px;
    z-index: 999;
    max-width: calc(100% - 30px);
    margin: 0 auto; }
    .popup.active {
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .popup.higher-then-window {
      position: absolute;
      -ms-transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -o-transform: translate(-50%, 0);
      transform: translate(-50%, 0); }
    .popup-login .popup-inner {
      padding: 53px 0 0; }
    .popup-login .buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 0 17px; }
      .popup-login .buttons .separator {
        max-width: 305px;
        flex-basis: 305px; }
      .popup-login .buttons .btn-secondary {
        position: relative;
        max-width: 66px;
        flex-basis: 66px;
        border: 2px solid #18B8C4;
        margin: 0 12px;
        z-index: 10; }
        .popup-login .buttons .btn-secondary .content {
          padding: 5px; }
    .popup-login .forgotpassword {
      margin-top: 46px;
      margin-bottom: 9px; }
    .popup-login form {
      margin-bottom: 31px; }
    .popup-login .input-group-submit {
      margin: -2px auto 0px; }
    .popup-reg .btn-turquoise {
      width: 100%; }
    .popup-buttons {
      flex-direction: column; }
      .popup-buttons .btn-anim {
        margin: 0 auto 20px; }
    .popup-donation .fanpage-donations-more .fanpage-donations {
      padding: 0 20px; }
    .popup-donation .btn-showall,
    .popup-donation .btn-showless {
      margin: 0 auto; }
    .popup-donation form .col-row {
      flex-direction: column;
      justify-content: center; }
      .popup-donation form .col-row .form-checkbox {
        max-width: 100%;
        flex-basis: 100%; }
      .popup-donation form .col-row .text-help {
        max-width: 100%;
        flex-basis: 100%;
        text-align: center;
        margin-top: 10px; }
    .popup-pictureupload .popup-buttons {
      flex-direction: row; }
  .container {
    padding: 0 15px; }
  .navbar {
    height: 60px;
    background-color: #FFF; }
    .navbar-logo {
      display: none; }
    .navbar-nav {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: fixed;
      top: 0;
      left: 0;
      width: 304px;
      min-height: 100vh;
      z-index: 1000;
      background-color: #18B8C4;
      -ms-transform: translateX(-304px);
      -webkit-transform: translateX(-304px);
      -moz-transform: translateX(-304px);
      -o-transform: translateX(-304px);
      transform: translateX(-304px);
      max-height: 100vh;
      overflow: auto;
      padding: 15px 0; }
      .navbar-nav li {
        width: 100%;
        height: 48px;
        /*padding: 0 38px;*/
        padding: 0;
        margin: 0 0 8px 0; }
        .navbar-nav li a, .navbar-nav li .li-title, .navbar-nav li .navbar-clickable, .navbar-nav li button {
          display: inline-flex;
          align-items: center;
          height: 100%;
          font-weight: 300;
          font-size: 18px;
          color: #FFF;
          line-height: 26px;
          padding: 0; }
        .navbar-nav li a, .navbar-nav li .navbar-clickable, .navbar-nav li button {
          width: 100%;
          padding: 0 38px; }
        .navbar-nav li .li-title {
          cursor: pointer;
          margin-right: 10px; }
        .navbar-nav li .li-downarrow {
          display: none; }
        .navbar-nav li:hover, .navbar-nav li.active {
          background-color: #FFF; }
          .navbar-nav li:hover a, .navbar-nav li:hover .li-title, .navbar-nav li:hover .navbar-clickable, .navbar-nav li:hover button, .navbar-nav li.active a, .navbar-nav li.active .li-title, .navbar-nav li.active .navbar-clickable, .navbar-nav li.active button {
            color: #18B8C4;
            text-decoration: none; }
        .navbar-nav li.search, .navbar-nav li.li-login {
          display: none; }
    .navbar-extra {
      display: flex;
      margin-right: 10px; }
      .navbar-extra:before {
        right: 27px; }
      .navbar-extra li {
        margin: 0 10px; }
        .navbar-extra li a {
          display: flex;
          align-items: center;
          height: auto;
          font-weight: 400;
          font-size: 18px;
          color: #666666;
          line-height: 26px;
          padding: 10px 0; }
          .navbar-extra li a img {
            margin-left: 5px; }
  .submenu-lang, .submenu-country {
    position: relative;
    top: 0;
    right: 0;
    width: auto;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-clip: padding-box;
    box-shadow: none;
    /*margin-left: -38px;
            margin-right: -38px;*/ }
    .submenu-lang:before, .submenu-country:before {
      display: none; }
  .headline {
    display: flex;
    align-items: center;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    min-height: 60px;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    padding: 5px 0;
    z-index: 998; }
    .headline .h1 {
      width: 100%;
      font-size: 18px;
      color: #666666;
      line-height: 1.0869;
      text-transform: uppercase;
      padding: 0 35px; }
  .hl .col-sm-4, .hl .col-sm-8 {
    display: none; }
  .breadcrumb {
    padding: 0; }
  .page-registration {
    padding-top: 40px;
    padding-bottom: 32px; }
    .page-registration .buttons {
      max-width: 245px;
      margin: 0 auto; }
      .page-registration .buttons .btn {
        margin-bottom: 14px; }
    .page-registration .btn-anim {
      margin-bottom: 20px; }
    .page-registration-step1 {
      padding-top: 50px;
      padding-bottom: 0; }
      .page-registration-step1 #setchannel {
        padding-top: 26px; }
      .page-registration-step1 .blocktemplate {
        padding-top: 29px; }
      .page-registration-step1 .form-group-regstep1,
      .page-registration-step1 .input-group-regstep1 {
        max-width: 750px;
        margin: 0 auto 34px; }
      .page-registration-step1 .form-group .edit,
      .page-registration-step1 .input-group .edit {
        top: 3px;
        right: 0; }
      .page-registration-step1 .form-group .delete,
      .page-registration-step1 .input-group .delete {
        top: 6px;
        right: auto;
        bottom: auto;
        left: 0; }
      .page-registration-step1 .form-group.input-editable,
      .page-registration-step1 .input-group.input-editable {
        padding: 0;
        margin-bottom: 0; }
      .page-registration-step1 .input-textarea {
        padding-right: 40px; }
      .page-registration-step1 .input-textarea {
        padding-right: 40px; }
      .page-registration-step1 .form-channellink {
        margin-top: 50px; }
        .page-registration-step1 .form-channellink .form-label {
          line-height: 1.5;
          margin-top: -30px; }
      .page-registration-step1 .input-checkbox-profil {
        margin-bottom: 7px; }
        .page-registration-step1 .input-checkbox-profil label {
          margin-bottom: 0; }
      .page-registration-step1 .blocktemplate {
        padding-top: 29px; }
      .page-registration-step1.page-donations-public .blocktemplate {
        padding: 29px 20px 33px; }
      .page-registration-step1 .row-content-step1 {
        padding-top: 80px;
        padding-bottom: 121px;
        margin-bottom: -80px; }
    .page-registration-step2 {
      padding-top: 40px;
      padding-bottom: 26px; }
      .page-registration-step2 .input-group {
        margin-bottom: 30px; }
      .page-registration-step2 .form-group {
        max-width: 100%;
        flex-basis: 100%; }
        .page-registration-step2 .form-group:after {
          display: none; }
      .page-registration-step2 .text {
        padding: 0 15px; }
        .page-registration-step2 .text-blue {
          word-break: break-all;
          margin-bottom: 19px; }
        .page-registration-step2 .text-help {
          margin-bottom: 38px; }
    .page-registration-step4 .box-container {
      padding: 26px 20px; }
    .page-registration-step4 p {
      margin-bottom: 27px; }
    .page-registration-step4 .input-group-submit {
      margin-bottom: 24px; }
      .page-registration-step4 .input-group-submit:last-child {
        margin-bottom: 0; }
    .page-registration-step4 img {
      max-width: 305px;
      margin: 0 auto 30px; }
  .page-donations {
    padding-top: 20px; }
    .page-donations-public .profil-desc {
      color: #ADADAD;
      margin-bottom: 49px; }
    .page-donations-public .profil .input-group-donate {
      margin-bottom: 33px; }
    .page-donations-public .donation-title, .page-donations-public .donation-monthly {
      display: block;
      text-align: center; }
    .page-donations-public .donation-title {
      font-size: 32px;
      margin-bottom: 2px; }
    .page-donations-public .donation-monthly {
      margin-bottom: 35px; }
    .page-donations-public .donation-desc {
      color: #ADADAD; }
    .page-donations-public .socials {
      display: block;
      margin-top: 14px; }
      .page-donations-public .socials-title {
        margin-bottom: 16px; }
      .page-donations-public .socials-links {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px; }
        .page-donations-public .socials-links a {
          display: inherit;
          margin: 0 15px 15px; }
  .page-dashboard {
    padding-top: 20px;
    margin: 0 auto; }
    .page-dashboard .slick-headline {
      font-size: 36px;
      margin-bottom: 35px; }
    .page-dashboard .slick-title {
      margin-bottom: 20px; }
    .page-dashboard .slick-img {
      max-width: 310px;
      margin: 0 auto 65px; }
    .page-dashboard .slick-text {
      margin-bottom: 20px; }
    .page-dashboard .slick-right .checkbarion {
      margin-bottom: 20px; }
    .page-dashboard .rounded-box-bottom {
      padding-bottom: 25px; }
    .page-dashboard .rounded-box-top {
      border-radius: 80px 0 80px 80px;
      padding: 44px 35px; }
      .page-dashboard .rounded-box-top:after {
        display: none; }
      .page-dashboard .rounded-box-top h2 {
        margin-bottom: 36px; }
      .page-dashboard .rounded-box-top .income {
        margin-bottom: 15px; }
  .page-header {
    padding-top: 50px; }
    .page-header .h1-hl {
      font-size: 30px;
      line-height: 45px;
      letter-spacing: 0; }
      .page-header .h1-hl br {
        display: none; }
    .page-header h2.h3 {
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 20px; }
    .page-header p {
      margin-bottom: 20px; }
    .page-header img {
      width: 100%; }
    .page-header .col-md-6, .page-header .col-md-5, .page-header .col-md-4 {
      align-self: center; }
      .page-header .col-md-6 .btn-anim, .page-header .col-md-5 .btn-anim, .page-header .col-md-4 .btn-anim {
        margin-bottom: 40px; }
    .page-header .col-md-6 img, .page-header .col-md-7 img, .page-header .col-md-8 img {
      display: table;
      margin: 0 auto 40px; }
    .page-header .col-sm-12 .btn-anim {
      display: flex;
      margin: 0 auto; }
    .page-header-sub .col-md-7 img, .page-header-sub .col-md-8 img {
      max-width: unset;
      margin: 0; }
    .page-header-full {
      padding-top: 0; }
      .page-header-full .col-md-5, .page-header-full .col-md-4 {
        order: 2; }
      .page-header-full .col-md-7, .page-header-full .col-md-8 {
        order: 1; }
        .page-header-full .col-md-7 img, .page-header-full .col-md-8 img {
          max-width: 100%;
          margin-bottom: 20px; }
      .page-header-full .col-lg-12 {
        order: 3; }
    .page-header.pc-header {
      display: none; }
  .page-editprofile {
    padding: 30px 0; }
  .two-column-text {
    align-self: center;
    margin-bottom: 30px; }
    .two-column-text h2 {
      margin-bottom: 20px; }
    .two-column-text p, .two-column-text ul, .two-column-text ol {
      margin-bottom: 20px; }
    .two-column-text.col-md-6 {
      padding-left: 15px; }
    .two-column-text .btn-anim {
      display: none; }
  .two-column-image {
    text-align: center; }
    .two-column-image .btn-anim {
      display: flex;
      margin: 40px auto 0; }
  .two-column-reverse .two-column-text {
    order: 1; }
  .two-column-reverse .two-column-image {
    order: 2;
    text-align: center; }
  .two-column-bottom:before {
    display: none; }
  .two-column-services-01 .two-column-text .btn-anim {
    display: flex;
    margin: 43px auto 0; }
  .two-column-services-01 .two-column-image {
    align-self: flex-start;
    margin-top: -31px; }
  .page-main-compare {
    padding-top: 30px; }
    .page-main-compare .two-column-image .btn-anim {
      margin-top: 0; }
  .page-main-support {
    position: relative;
    padding-top: 30px; }
    .page-main-support:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1200px;
      top: 0;
      left: 0;
      background-image: url("../img/bg/d_bg_bottom_tablet.png");
      background-position: center calc(100% + 190px);
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1; }
  .page-main-change {
    padding-top: 30px; }
  .page-main-foryou {
    padding-top: 30px;
    padding-bottom: 30px; }
  .page-services br {
    display: none; }
  .page-services-circle {
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto;
    height: 80%;
    z-index: -1; }
  .page-services-desktop {
    display: none !important; }
  .page-services-mobil {
    display: block;
    position: relative;
    z-index: 2; }
  .page-services .two-column .container {
    position: relative; }
  .page-services .two-column-services {
    padding-top: 59px; }
    .page-services .two-column-services:last-child {
      padding-bottom: 50px; }
    .page-services .two-column-services-01 {
      padding-top: 50px; }
      .page-services .two-column-services-01 h3 {
        font-weight: 500; }
  .page-404 {
    padding-top: 30px;
    padding-bottom: 30px; }
    .page-404 .two-column-image img {
      width: 100%; }
    .page-404-text .container {
      padding: 0; }
    .page-404-text .col-xs-12 {
      padding: 0 30px; }
    .page-404-text .rounded {
      padding-top: 30px;
      padding-bottom: 30px; }
      .page-404-text .rounded .hero {
        margin-bottom: 30px; }
        .page-404-text .rounded .hero br {
          display: none; }
  .page-howtochange-community {
    padding-top: 20px; }
  .page-howtochange-nothingelse {
    padding-top: 20px; }
  .page-howtochange-useit {
    padding-top: 20px;
    padding-bottom: 30px; }
  .page-howitworks-desc {
    position: relative;
    padding-top: 50px; }
  .page-howitworks-simple {
    padding-top: 55px; }
  .page-howitworks-forcreators {
    position: relative;
    padding-top: 70px;
    padding-bottom: 50px; }
  .page-howitworks-change {
    padding-bottom: 50px; }
  .page-404-howitworks {
    padding-top: 40px; }
    .page-404-howitworks h4 {
      margin-bottom: 20px; }
    .page-404-howitworks .col-sm-6 {
      max-width: 100%;
      flex-basis: 100%; }
      .page-404-howitworks .col-sm-6 img {
        display: table;
        max-width: 70%;
        height: auto;
        margin: 0 auto; }
  .page-404-foryou .col-sm-12 {
    padding: 0 30px; }
  .page-invitation .col-sm-6 {
    max-width: 100%;
    flex-basis: 100%; }
  .page-help {
    padding-top: 20px; }
    .page-help .container {
      padding: 0; }
    .page-help .help-box {
      height: 74px;
      -webkit-border-radius: 18px;
      -moz-border-radius: 18px;
      border-radius: 18px;
      background-clip: padding-box; }
      .page-help .help-box-parent {
        max-width: 50%;
        flex-basis: 50%;
        padding: 0 10px; }
        .page-help .help-box-parent:last-child {
          margin: 0 auto; }
      .page-help .help-box-ico {
        display: none; }
    .page-help .contact-form .form-100 .col-sm-6 {
      max-width: 100%;
      flex-basis: 100%; }
      .page-help .contact-form .form-100 .col-sm-6:last-child img {
        display: table;
        max-width: 80%;
        height: auto;
        margin: 0 auto !important; }
    .page-help .gyik-title {
      font-size: 28px;
      line-height: 1;
      margin: 30px 0 20px; }
    .page-help .gyik-content {
      max-width: 750px;
      padding: 0 15px;
      margin: 0 auto 20px auto; }
      .page-help .gyik-content .gyik-item-title {
        padding: 15px 15px 15px 40px; }
      .page-help .gyik-content .gyik-item-txt {
        padding: 0 15px; }
        .page-help .gyik-content .gyik-item-txt.active {
          padding: 15px 15px; }
  .page-tariff {
    padding-top: 20px; }
    .page-tariff-change {
      padding-bottom: 50px; }
  .page-fanpage {
    padding-bottom: 52px; }
  .page-content {
    padding-bottom: 30px; }
  .page-blog-list {
    padding-bottom: 30px; }
  .recommender {
    padding-bottom: 30px; }
  .fanpage-posts {
    padding-bottom: 30px; }
  .fanpage-pagetitle {
    margin-bottom: 30px; }
  .fanpage-header {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 188px;
    background-size: cover;
    border: 1px dashed #18B8C4;
    z-index: 1; }
    .fanpage-header.has-image {
      border: none; }
  .fanpage-avatar {
    position: relative;
    width: 100px;
    height: 100px;
    margin: -51px auto 31px;
    z-index: 2; }
  .fanpage-name {
    margin-bottom: 3px; }
  .fanpage-title {
    margin-bottom: 47px; }
  .fanpage-channelinfo {
    padding: 0 15px;
    margin-bottom: 25px; }
  .fanpage-box {
    padding-top: 20px;
    margin-bottom: 30px; }
    .fanpage-box-inner {
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2); }
  .fanpage-texthelp br {
    display: none; }
  .fanpage-social-left {
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 15px; }
  .fanpage-social-right, .fanpage-social-full {
    max-width: 100%;
    flex-basis: 100%;
    text-align: center;
    padding-left: 0; }
    .fanpage-social-right a, .fanpage-social-full a {
      margin: 0 9px 10px; }
      .fanpage-social-right a:nth-child(4n+4), .fanpage-social-full a:nth-child(4n+4) {
        margin-right: 9px; }
  .fanpage-social-bottom {
    flex-direction: column;
    align-items: center;
    margin-top: 30px; }
    .fanpage-social-bottom .btn-anim {
      max-width: 261px;
      margin-bottom: 20px; }
  .fanpage-submenu {
    position: absolute;
    top: 77px;
    left: 50%;
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms ease, transform 300ms ease;
    -ms-transform: translate(-50%, 10px);
    -webkit-transform: translate(-50%, 10px);
    -moz-transform: translate(-50%, 10px);
    -o-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px); }
    .fanpage-submenu.sys {
      min-width: 261px;
      opacity: 1;
      visibility: visible;
      transition: opacity 300ms ease, transform 300ms ease;
      -ms-transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -o-transform: translate(-50%, 0);
      transform: translate(-50%, 0); }
  .writty .toolbar {
    max-width: 316px;
    padding: 10px 15px; }
  .post-item {
    width: 100%; }
  .post-locked img {
    margin-bottom: 15px; }
  .post-locked p {
    font-size: 18px;
    line-height: 1.08333;
    margin-bottom: 15px; }
  .post-actions {
    text-align: right;
    padding-right: 20px;
    margin-bottom: 20px; }
  .post-icon {
    margin: 0 5px; }
  .page-readpost {
    padding-top: 30px;
    padding-bottom: 30px; }
  .readpost-left {
    max-width: 100%;
    flex-basis: 100%; }
  .readpost-right {
    max-width: 100%;
    flex-basis: 100%; }
  .recommender-item {
    margin-bottom: 30px; }
  .page-compare-twocolumn {
    padding-top: 20px;
    padding-bottom: 30px; }
    .page-compare-twocolumn .two-column-text {
      margin-bottom: 30px; }
    .page-compare-twocolumn .two-column-image img {
      display: table;
      margin: 0 auto; }
  .page-compare-content .row {
    padding-left: 0;
    padding-right: 0; }
    .page-compare-content .row.row-first {
      margin-top: 40px; }
    .page-compare-content .row.row-second {
      padding-left: 10px;
      padding-right: 10px; }
  .page-compare .rounded {
    margin-left: -15px;
    margin-right: -15px; }
  .page-compare-v2 {
    position: relative;
    padding-top: 26px; }
    .page-compare-v2 .pc-container {
      max-width: 960px;
      padding-bottom: 19px; }
      .page-compare-v2 .pc-container h2 {
        margin-bottom: 45px; }
      .page-compare-v2 .pc-container p {
        margin-bottom: 28px; }
      .page-compare-v2 .pc-container-table {
        position: relative;
        max-width: 1170px;
        padding: 0 30px; }
        .page-compare-v2 .pc-container-table h2 {
          padding-top: 40px;
          margin-bottom: 31px; }
        .page-compare-v2 .pc-container-table:nth-child(3):before {
          content: '';
          position: absolute;
          left: 0;
          width: 1920px;
          height: 860px;
          background-image: url("../img/bg/d_bg_bottom_alt2.png");
          background-position: left bottom;
          background-repeat: no-repeat;
          background-size: 100% auto;
          -ms-transform: translateY(-130px);
          -webkit-transform: translateY(-130px);
          -moz-transform: translateY(-130px);
          -o-transform: translateY(-130px);
          transform: translateY(-130px);
          z-index: -1; }
    .page-compare-v2 .pc-logo {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      height: 39px;
      margin-bottom: 2px; }
      .page-compare-v2 .pc-logo img {
        width: auto;
        max-height: 100%; }
    .page-compare-v2 .pc-column {
      max-width: 25%;
      flex-basis: 25%; }
      .page-compare-v2 .pc-column span, .page-compare-v2 .pc-column p {
        font-size: 12px;
        line-height: 1.2; }
      .page-compare-v2 .pc-column p {
        display: flex;
        align-items: center; }
        .page-compare-v2 .pc-column p.text-turq {
          color: #18B8C4; }
        .page-compare-v2 .pc-column p img {
          width: 15px;
          height: auto;
          margin-right: 5px; }
      .page-compare-v2 .pc-column span {
        font-weight: 500; }
      .page-compare-v2 .pc-column.best-choice {
        position: relative;
        background-color: #E7F8F8 !important; }
        .page-compare-v2 .pc-column.best-choice:before {
          content: '';
          position: absolute;
          display: block;
          top: -1px;
          left: -1px;
          width: 100%;
          height: 100%;
          border: 1px solid #FF0000; }
        .page-compare-v2 .pc-column.best-choice:after {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          display: block;
          width: calc(100% - 6px);
          height: calc(100% - 6px);
          border: 1px solid #FF0000; }
        .page-compare-v2 .pc-column.best-choice p {
          font-weight: 500;
          color: #18B8C4; }
    .page-compare-v2 .pc-title {
      margin-bottom: 7px; }
      .page-compare-v2 .pc-title .pc-title {
        display: none; }
      .page-compare-v2 .pc-title .pc-column:nth-child(2) .pc-logo img {
        width: 30px;
        height: auto; }
      .page-compare-v2 .pc-title .pc-column:nth-child(3) .pc-logo img {
        width: 30px;
        height: auto; }
      .page-compare-v2 .pc-title .pc-column:nth-child(4) .pc-logo img {
        width: 30px;
        height: auto; }
    .page-compare-v2 .pc-row {
      background-color: #F8F8F8;
      border-left: 1px solid #E1E1E1;
      border-top: 1px solid #E1E1E1;
      border-right: 1px solid #E1E1E1; }
      .page-compare-v2 .pc-row:last-child {
        border-bottom: 1px solid #E1E1E1; }
      .page-compare-v2 .pc-row .pc-column {
        max-width: 100%;
        flex-basis: 100%; }
        .page-compare-v2 .pc-row .pc-column:not(.justify-center) {
          padding: 7px 7px; }
          .page-compare-v2 .pc-row .pc-column:not(.justify-center) p {
            font-weight: 600; }
        .page-compare-v2 .pc-row .pc-column.justify-center {
          display: flex;
          justify-content: center;
          max-width: 25%;
          flex-basis: 25%;
          background-color: #FFF;
          border-top: 1px solid #E1E1E1;
          border-right: 1px solid #E1E1E1;
          padding: 12px 7px; }
          .page-compare-v2 .pc-row .pc-column.justify-center p {
            text-align: center; }
          .page-compare-v2 .pc-row .pc-column.justify-center:last-child {
            border-right: none; }
    .page-compare-v2 .two-column {
      padding-top: 14px;
      padding-bottom: 27px; }
      .page-compare-v2 .two-column h2 {
        padding-top: 0;
        margin-bottom: 39px; }
      .page-compare-v2 .two-column:before {
        height: 854px;
        -ms-transform: translateY(350px);
        -webkit-transform: translateY(350px);
        -moz-transform: translateY(350px);
        -o-transform: translateY(350px);
        transform: translateY(350px);
        background-image: url("../img/bg/d_bg_bottom_alt.png");
        background-position: left top;
        z-index: -2; }
    .page-compare-v2 .d-tab-container .pc-title {
      border-top: 1px solid #ECEDF0; }
      .page-compare-v2 .d-tab-container .pc-title .pc-column {
        border-top: 0;
        border-right: 0;
        background-color: #FFF !important; }
        .page-compare-v2 .d-tab-container .pc-title .pc-column:last-child {
          border-right: 1px solid #e1e1e1; }
      .page-compare-v2 .d-tab-container .pc-title .pc-title {
        border-top: none;
        border-left: 1px solid #e1e1e1; }
  .d-tab-item {
    height: 35px;
    padding: 0 10px; }
    .d-tab-item, .d-tab-item span {
      font-size: 13px;
      text-align: center; }
  .services-box {
    padding-top: 50px;
    padding-bottom: 25px; }
  .services-item {
    margin-bottom: 30px; }
  .services-content {
    padding: 0 13px 34px; }
    .services-content h2 {
      margin-bottom: 16px; }
  .services-picture {
    margin-bottom: 19px; }
  .page-suspended {
    padding: 30px 0; }
  .column-title, .column-data {
    padding: 0 5px; }
  .column-title {
    display: none; }
  .column-data {
    max-width: 33.33333%;
    flex-basis: 33.33333%; }
    .column-data:before {
      display: none; }
    .column-data .column-row {
      font-size: 30px;
      margin-bottom: 17px; }
      .column-data .column-row.column-full {
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        text-align: left;
        white-space: nowrap;
        margin-bottom: 8px; }
        .column-data .column-row.column-full br {
          display: none; }
    .column-data .column-title {
      padding: 0 10px; }
  .column-logo {
    min-height: 63px;
    margin-bottom: 29px; }
    .column-logo img {
      width: auto;
      height: 30px;
      margin-bottom: -1px; }
  .column-row .currency {
    font-size: 14px;
    line-height: 17px;
    margin-top: 2px;
    margin-left: 2px; }
  .column-row .inner {
    min-height: 47px;
    font-size: 16px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-clip: padding-box; }
  .column-row p {
    font-size: 19px; }
  .column-row.better .inner:before {
    width: calc(100% - 7px);
    height: calc(100% - 7px);
    border-width: 2px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-clip: padding-box;
    margin-top: -0.5px; }
  .column-row.better .inner:after {
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    border-width: 2px;
    margin-top: -1px; }
  .column-row.better img {
    right: 10px;
    width: 13px;
    height: auto; }
  .profil {
    padding: 24px 17px;
    margin-bottom: -25px; }
    .profil .edit, .profil .delete {
      width: 38px;
      height: 38px; }
    .profil-cover {
      height: 170px; }
    .profil-picture {
      width: 125px;
      height: 125px;
      bottom: -63px; }
      .profil-picture .monogram {
        font-size: 44px;
        font-weight: 500;
        color: #18B8C4; }
    .profil-header {
      margin-bottom: 111px; }
    .profil-content {
      max-width: 770px;
      padding: 0 20px 21px;
      margin: 0 auto; }
    .profil-name {
      padding: 0 35px;
      margin-bottom: 4px; }
    .profil-title {
      margin-bottom: 44px; }
    .profil-text-1 {
      margin-bottom: 40px; }
    .profil-text-2 {
      margin-top: 30px;
      margin-bottom: 24px; }
    .profil-buttons {
      display: flex;
      flex-direction: column;
      margin-bottom: 31px; }
      .profil-buttons a {
        max-width: 220px;
        margin: 0 auto 20px; }
    .profil .input-group-inline {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px; }
      .profil .input-group-inline .input-field, .profil .input-group-inline .currency {
        display: inline-block; }
      .profil .input-group-inline .input-field {
        max-width: 135px;
        flex-basis: 135px;
        height: 50px;
        font-size: 18px;
        color: #ADADAD;
        text-align: center;
        border: 1px solid #8A8A8A;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        background-clip: padding-box;
        padding: 0 10px;
        margin-right: 10px;
        margin-bottom: 0; }
        .profil .input-group-inline .input-field:hover, .profil .input-group-inline .input-field:active, .profil .input-group-inline .input-field:valid {
          border-color: #18B8C4; }
      .profil .input-group-inline .select2 {
        max-width: 70px;
        flex-basis: 70px; }
    .profil .input-group-donate {
      margin-bottom: 41px; }
    .profil .input-group .currency {
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      color: #ADADAD; }
    .profil .chk-container.reverse {
      margin-top: 4px;
      margin-bottom: 31px; }
    .profil .add-donate {
      display: table;
      margin: -9px auto 25px; }
    .profil-bottom-buttons {
      flex-direction: column;
      align-items: center; }
      .profil-bottom-buttons .btn {
        max-width: 220px;
        margin: 0 auto 20px; }
  .news {
    padding-top: 15px; }
    .news h2.h1 {
      margin-bottom: 24px; }
    .news-item {
      width: 100%;
      margin-bottom: -17px; }
    .news-picture {
      height: 212px;
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover; }
    .news-content {
      max-width: 305px;
      margin: 0 auto; }
  .content-page .box-container {
    padding: 76px 35px 21px; }
  .content-page h1 {
    margin-bottom: 40px; }
  .content-page .text-label {
    margin-bottom: 2px; }
  .content-page p {
    margin-bottom: 14px; }
    .content-page p.h2 {
      margin-bottom: 32px; }
  .content-page .buttons {
    margin-bottom: 28px; }
  .content-page .btn-secondary.btn-center {
    margin: 0 auto 14px; }
  .content-page .input-group-submit {
    margin-top: 32px;
    margin-bottom: 9px; }
  .content-page-paywall {
    padding-bottom: 30px; }
    .content-page-paywall .btn-barionblue {
      margin-bottom: 20px; }
    .content-page-paywall .text-label {
      margin-top: 60px;
      margin-bottom: 20px; }
    .content-page-paywall .thin {
      margin-bottom: 20px; }
    .content-page-paywall .img-center {
      max-width: 251px;
      margin: 0 auto !important; }
  .content-header {
    height: 212px;
    margin-top: 9px;
    margin-left: -15px;
    margin-right: -15px; }
    .content-header:before {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background-clip: padding-box; }
  .content-lead {
    max-width: 305px;
    padding: 16px 20px;
    margin: -46px auto 38px; }
    .content-lead .date, .content-lead p {
      line-height: 24px; }
    .content-lead .date {
      margin-bottom: 0; }
  .datepicker-dropdown {
    width: 250px; }
  .select2-dropdown.input-countrycode {
    max-width: unset;
    width: 100% !important; }
  .input-countrycode-group {
    max-width: calc(100% - 60px);
    width: 100%; }
  .profilpic {
    max-width: 300px;
    margin-bottom: 30px; }
  .croppie-buttons,
  .croppie-container .cr-slider-wrap {
    width: 300px; }
  .body footer {
    padding: 35px 0 32px; }
    .body footer .container {
      padding: 0 35px; }
    .body footer .inner {
      max-width: 100%;
      flex-basis: 100%;
      padding: 0 15px;
      margin-bottom: 36px; }
    .body footer .copyright {
      padding-top: 42px; }
      .body footer .copyright .col-sm-4, .body footer .copyright .col-sm-8 {
        max-width: 100%;
        flex-basis: 100%; }
      .body footer .copyright .col-sm-4 {
        margin-bottom: 30px; }
      .body footer .copyright .col-sm-8 p {
        display: inline-block;
        margin-right: 20px; }
      .body footer .copyright .col-sm-8 img {
        vertical-align: text-top;
        margin-left: 7px; }
  .form-container {
    display: flex;
    flex-wrap: wrap; }
  .form-content {
    max-width: 100%;
    flex-basis: 100%; }
  .form-group {
    max-width: 100%;
    flex-basis: 100%; }
    .form-group-cc {
      max-width: unset;
      flex-basis: unset;
      min-width: 106px; }
    .form-group-100 {
      max-width: calc(100% - 106px);
      flex-basis: calc(100% - 106px); }
    .form-group-p0 {
      padding: 0; }
    .form-group-submit {
      max-width: 300px;
      flex-basis: unset; }
      .form-group-submit:nth-child(even), .form-group-submit:nth-child(odd) {
        margin-right: auto;
        margin-left: auto; }
    .form-group-full.form-radio {
      margin-bottom: 15px; }
    .form-group-biglabel .form-label {
      left: 0; }
  .form-radio {
    margin-bottom: 15px; }
  #phone-reg .form-group.has-error:after {
    width: 100%; }
  #phone-reg .form-label {
    left: 0; }
  .cookie-left, .cookie-right {
    max-width: 100%;
    flex-basis: 100%; }
  .cookie-left {
    margin-bottom: 10px; }
  .tooltip {
    max-width: 300px; }
  .row-content {
    max-width: 750px; } }

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .page-dashboard {
    max-width: 640px; }
  .content-area p, .content-area img {
    float: none !important;
    width: 100%;
    height: auto;
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .content-area .btn-barion img {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .content-area table thead tr th, .content-area table tfoot tr th {
    padding: 21px 25px; }
  .content-area table tbody tr td {
    display: block;
    width: 100%;
    text-align: left;
    padding: 9px 25px; } }

@media only screen and (min-width: 320px) and (max-width: 575px) {
  .chart_anim_cont {
    -ms-transform: scale(0.93);
    -webkit-transform: scale(0.93);
    -moz-transform: scale(0.93);
    -o-transform: scale(0.93);
    transform: scale(0.93);
    height: auto;
    margin-top: -30px;
    margin-bottom: 10px; }
  .hide-on-mobile {
    display: none; } }

@media only screen and (min-width: 320px) and (max-width: 360px) {
  .writty {
    padding: 23px 15px 49px; } }

@media screen and (min-width: 375px) {
  .hide-mobil {
    display: block; }
  .buttons .hide-mobil {
    display: none; }
  .datepicker-dropdown {
    width: 320px; } }
