.page-suspended h2 {
  color: #647AA0;
  margin-bottom: 30px; }

.page-suspended p {
  display: block;
  width: 100%;
  margin-bottom: 30px; }

.page-suspended .img-center {
  width: 400px;
  height: auto; }

.page-suspended form .form-group {
  margin: 0 auto 41px; }

.page-suspended form .btn-anim {
  max-width: 305px;
  width: 100%;
  margin: 0 auto; }
