.page-registration-step1 .form-checkbox-oneoff {
  display: table;
  margin-right: auto;
  margin-left: auto; }

.page-registration-step1 .btn-anim.btn-big {
  max-width: 300px; }

.page-registration-step1 .row-content-step1 {
  flex-wrap: unset;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #18b8c4;
  background: -moz-linear-gradient(left, #18b8c4 0%, #647aa0 100%);
  background: -webkit-linear-gradient(left, #18b8c4 0%, #647aa0 100%);
  background: linear-gradient(to right, #18b8c4 0%, #647aa0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#18b8c4', endColorstr='#647aa0', GradientType=1);
  z-index: 1; }
  .page-registration-step1 .row-content-step1 .form-checkbox-profil .chk-container {
    color: #FFF; }
    .page-registration-step1 .row-content-step1 .form-checkbox-profil .chk-container .checkmark {
      background-color: #FFF; }
    .page-registration-step1 .row-content-step1 .form-checkbox-profil .chk-container input:checked ~ .checkmark {
      border-color: #18B8C4;
      background-color: #18B8C4; }
  .page-registration-step1 .row-content-step1 .btn-secondary .content span {
    color: #18B8C4; }
  .page-registration-step1 .row-content-step1 .text-help {
    color: #FFF;
    margin-bottom: 35px; }

.page-registration-step1 .row-content-step1-box {
  max-width: 360px; }
  .page-registration-step1 .row-content-step1-box .form-checkbox-margin-30 {
    margin-bottom: 30px !important; }

.page-registration-step1 .btn-profil {
  height: 45px; }

.page-registration-step1 .description {
  min-height: 115px; }

.page-registration-step1 .input-group-header {
  cursor: pointer;
  position: relative;
  height: 170px;
  border: 1px dashed #18B8C4;
  border-radius: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: -29px -20px 20px; }
  .page-registration-step1 .input-group-header .add-cover, .page-registration-step1 .input-group-header .add-cover0, .page-registration-step1 .input-group-header .add-cover1 {
    position: absolute;
    top: 14px;
    right: 16px; }
    .page-registration-step1 .input-group-header .add-cover img, .page-registration-step1 .input-group-header .add-cover0 img, .page-registration-step1 .input-group-header .add-cover1 img {
      display: inherit; }
  .page-registration-step1 .input-group-header.has-image {
    border: none; }

.page-registration-step2 .input-field {
  padding-right: 50px; }

.page-registration-step4 {
  background-color: #61D0D8;
  padding: 20px 0; }
  .page-registration-step4 img {
    display: table;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.16) 0 2px 48px; }

.more-inputs {
  display: none;
  flex-wrap: wrap;
  width: 100%; }
  .more-inputs.sys {
    display: flex; }
