.notification {
  position: fixed;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-start;
  left: 50%;
  bottom: 0;
  max-width: 400px;
  width: 100%;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  border-radius: 26px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 30px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -ms-transform: translate(-50%, 300%);
  -webkit-transform: translate(-50%, 300%);
  -moz-transform: translate(-50%, 300%);
  -o-transform: translate(-50%, 300%);
  transform: translate(-50%, 300%);
  padding: 15px;
  z-index: 1000; }
  .notification img {
    margin-right: 15px; }
  .notification p {
    color: #FFF; }
  .notification.syske {
    -ms-transform: translate(-50%, -50vh);
    -webkit-transform: translate(-50%, -50vh);
    -moz-transform: translate(-50%, -50vh);
    -o-transform: translate(-50%, -50vh);
    transform: translate(-50%, -50vh); }
  .notification.sys {
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: bounceInUp;
    animation-name: bounceInUp; }
  .notification-success {
    background-color: #18B8C4; }
  .notification-error {
    background-color: #FF0000; }
  .notification-dismiss {
    padding-right: 45px; }
    .notification-dismiss.sys {
      -webkit-animation-duration: 3s;
      animation-duration: 3s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: bounceInUp2;
      animation-name: bounceInUp2; }
      .notification-dismiss.sys.hys {
        -webkit-animation-name: bounceInUp2out;
        animation-name: bounceInUp2out; }
  .notification-icon {
    align-self: flex-start;
    margin-top: 3px; }
  .notification-close {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: -11px;
    z-index: 1; }

@-webkit-keyframes bounceInUp {
  0% {
    -ms-transform: translate(-50%, 300%);
    -webkit-transform: translate(-50%, 300%);
    -moz-transform: translate(-50%, 300%);
    -o-transform: translate(-50%, 300%);
    transform: translate(-50%, 300%);
    bottom: 0; }
  20% {
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 55vh; }
  50% {
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 50vh; }
  80% {
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 50vh; }
  100% {
    -ms-transform: translate(-50%, 300%);
    -webkit-transform: translate(-50%, 300%);
    -moz-transform: translate(-50%, 300%);
    -o-transform: translate(-50%, 300%);
    transform: translate(-50%, 300%);
    bottom: 0; } }

@-webkit-keyframes bounceInUp2 {
  0% {
    -ms-transform: translate(-50%, 300%);
    -webkit-transform: translate(-50%, 300%);
    -moz-transform: translate(-50%, 300%);
    -o-transform: translate(-50%, 300%);
    transform: translate(-50%, 300%);
    bottom: 0; }
  20% {
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 55vh; }
  50% {
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 50vh; }
  80% {
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 50vh; }
  100% {
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 50vh; } }

@-webkit-keyframes bounceInUp2out {
  0% {
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 50vh; }
  10% {
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 55vh; }
  30% {
    -ms-transform: translate(-50%, 300%);
    -webkit-transform: translate(-50%, 300%);
    -moz-transform: translate(-50%, 300%);
    -o-transform: translate(-50%, 300%);
    transform: translate(-50%, 300%);
    bottom: 0; }
  100% {
    -ms-transform: translate(-50%, 300%);
    -webkit-transform: translate(-50%, 300%);
    -moz-transform: translate(-50%, 300%);
    -o-transform: translate(-50%, 300%);
    transform: translate(-50%, 300%);
    bottom: 0; } }
