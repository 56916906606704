@charset "UTF-8";
.input-field, .input-textarea, .select2-search__field {
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  color: #333333;
  border: none;
  padding: 0;
  margin-bottom: 15px; }

.input-group {
  position: relative;
  border-bottom: 1px solid rgba(138, 138, 138, 0.5);
  margin-bottom: 34px; }
  .input-group.input-noborder {
    border-bottom: none; }
  .input-group.input-center {
    display: table;
    margin: 0 auto; }
  .input-group-submit {
    position: relative;
    max-width: 300px;
    width: 100%;
    border-bottom: none;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background-clip: padding-box;
    overflow: hidden;
    margin: -2px auto 18px; }
    .input-group-submit:hover .input-submit-bg {
      left: -200%; }
  .input-group.has-value .line:before {
    width: 100%; }
  .input-group-split {
    display: flex;
    flex-wrap: wrap; }
    .input-group-split label {
      max-width: 100%;
      flex-basis: 100%; }
    .input-group-split p, .input-group-split input {
      display: inline-block; }
    .input-group-split input {
      width: auto; }

.input-label {
  position: relative;
  display: block;
  font-size: 14px;
  color: #666666;
  margin-bottom: 11px; }
  .input-label .icon-info {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0; }

.input-textarea, .input-field {
  font-size: 16px; }
  .input-textarea:focus + .line:before, .input-field:focus + .line:before {
    width: 100%; }

.input-textarea {
  font-family: "Poppins", sans-serif;
  line-height: 23px;
  resize: none; }

.input-icon {
  position: absolute;
  top: 26px;
  right: 0; }

.input-checkbox {
  border-bottom: none;
  margin-bottom: 27px; }

.input-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px; }
  .input-radio p {
    max-width: calc(100% - 80px);
    flex-basis: calc(100% - 80px);
    text-align: right;
    margin-bottom: 0; }

.input-readonly {
  opacity: .3; }
  .input-readonly .input-field {
    background-color: transparent; }

.input-correct, .input-error {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -10px;
  right: 15px;
  z-index: 2; }
  .input-correct.sys, .input-error.sys {
    opacity: 1;
    visibility: visible; }

.input-submit {
  cursor: pointer;
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 100%;
  height: 66px;
  background: transparent;
  border: none;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  background-clip: padding-box; }
  .input-submit span {
    font-weight: 500;
    font-size: 19px;
    color: #FFF; }
  .input-submit .circle {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 7px;
    width: 54px;
    height: 54px;
    background-color: transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-clip: padding-box; }
  .input-submit-bg {
    position: absolute;
    left: 0;
    width: 300%;
    height: 66px;
    background: #18b8c4;
    background: -moz-linear-gradient(left, #647aa0 0%, #647aa0 1%, rgba(24, 184, 196, 0.83) 50%, rgba(24, 184, 196, 0.75) 75%, #18b8c4 100%);
    background: -webkit-linear-gradient(left, #647aa0 0%, #647aa0 1%, rgba(24, 184, 196, 0.83) 50%, rgba(24, 184, 196, 0.75) 75%, #18b8c4 100%);
    background: linear-gradient(to right, #647aa0 0%, #647aa0 1%, rgba(24, 184, 196, 0.83) 50%, rgba(24, 184, 196, 0.75) 75%, #18b8c4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#647aa0', endColorstr='#18b8c4', GradientType=1);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background-clip: padding-box;
    -moz-transition: all 400ms;
    -o-transition: all 400ms;
    -webkit-transition: all 400ms;
    transition: all 400ms; }
  .input-submit:hover {
    text-decoration: none; }

.input–placeholder {
  display: none; }

.input-content p, .input-content a {
  display: block;
  width: 100%;
  font-size: 16px;
  text-align: center;
  line-height: 27px; }

.input-content p {
  color: #666666; }

.input-content a {
  color: #49B8C0; }

.new-input-group {
  display: flex;
  align-items: stretch;
  width: 100%;
  margin-bottom: 20px; }
  .new-input-group input::-webkit-input-placeholder {
    font-style: normal;
    color: #ADADAD;
    opacity: 0.5;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .new-input-group input::-moz-placeholder {
    font-style: normal;
    color: #ADADAD;
    opacity: 0.5;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .new-input-group input:-ms-input-placeholder {
    font-style: normal;
    color: #ADADAD;
    opacity: 0.5;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .new-input-group .new-input-field {
    width: 1%;
    flex: 1 1 auto;
    padding: 6px 12px; }
  .new-input-group > .new-input-field:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .new-input-group-append {
    display: flex;
    margin-left: -1px; }
  .new-input-group-text {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    min-width: 93px;
    font-weight: 500;
    font-size: 18px;
    color: #FFF;
    background-color: #ADADAD;
    border: 1px solid #ADADAD;
    -webkit-border-radius: 0 10px 10px 0;
    -moz-border-radius: 0 10px 10px 0;
    border-radius: 0 10px 10px 0;
    background-clip: padding-box;
    padding: 6px 12px; }

.new-input-field {
  display: block;
  height: 50px;
  font-weight: 500;
  font-size: 18px;
  color: #ADADAD;
  border: 1px solid #ADADAD;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-clip: padding-box; }

form {
  width: 100%; }
  form input[type=number] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield; }
    form input[type=number]::-webkit-outer-spin-button, form input[type=number]::-webkit-inner-spin-button {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none; }
  form select {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
    width: 100%;
    height: 21px;
    border: none;
    margin-bottom: 26px; }
    form select::-webkit-outer-spin-button, form select::-webkit-inner-spin-button {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none; }
  form .line {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; }
    form .line:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 0;
      height: 1px;
      -moz-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      background-color: rgba(138, 138, 138, 0.5); }
  form.form-reg .group-container {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 305px;
    width: 100%;
    margin: 0 auto; }
  form.form-reg .input-field, form.form-reg .select2-container--default {
    margin-bottom: 0; }
  form.form-reg .input-label {
    display: none; }
  form.form-reg .input-group {
    display: inline-block;
    margin-bottom: 20px; }
    form.form-reg .input-group-select {
      max-width: 70px;
      flex-basis: 70px; }
    form.form-reg .input-group-prefix {
      max-width: 65px;
      flex-basis: 65px;
      padding-left: 15px; }
    form.form-reg .input-group-text {
      max-width: calc(100% - 140px);
      flex-basis: calc(100% - 140px);
      padding: 0 15px; }
    form.form-reg .input-group-code {
      max-width: 48px;
      flex-basis: 48px;
      padding: 0 10px;
      margin: 0 2px 20px; }
      form.form-reg .input-group-code .input-field {
        text-align: center;
        padding: 5px; }
    form.form-reg .input-group-submit {
      display: table;
      margin: 0 auto; }
  form.form-reg p, form.form-reg a {
    font-size: 13px;
    color: #666666;
    line-height: 18px;
    margin-bottom: 40px; }
  form.form-reg a {
    display: block;
    color: #647AA0;
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0; }
  form.form-reg .select2-dropdown {
    padding: 0; }
  form .input-phone {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    form .input-phone .input-field, form .input-phone .select2-container--default {
      margin-bottom: 0; }
    form .input-phone.input-margin {
      margin-bottom: 26px; }
      form .input-phone.input-margin .input-label {
        display: block;
        max-width: 100%;
        flex-basis: 100%; }
      form .input-phone.input-margin .input-group {
        margin-bottom: 0; }
      form .input-phone.input-margin .input-field, form .input-phone.input-margin .select2-container--default {
        margin-bottom: 26px; }
    form .input-phone .input-label {
      display: none; }
    form .input-phone .input-group {
      display: inline-block;
      margin-bottom: 20px; }
      form .input-phone .input-group-select {
        max-width: 70px;
        flex-basis: 70px; }
      form .input-phone .input-group-prefix {
        max-width: 65px;
        flex-basis: 65px;
        padding-left: 15px; }
      form .input-phone .input-group-text {
        max-width: calc(100% - 140px);
        flex-basis: calc(100% - 140px);
        padding: 0 15px; }
      form .input-phone .input-group-code {
        max-width: 48px;
        flex-basis: 48px;
        padding: 0 10px;
        margin: 0 2px 20px; }
        form .input-phone .input-group-code .input-field {
          text-align: center;
          padding: 5px; }
      form .input-phone .input-group-submit {
        display: table;
        margin: 0 auto; }
  form .error {
    display: none;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 1px;
    width: 28px;
    height: 28px;
    font-size: 1px;
    text-indent: -100%;
    background: #FFF;
    overflow: hidden;
    padding: 0 0 10px 0; }
    form .error:after {
      content: '';
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 10px;
      width: 18px;
      height: 18px;
      background: url(../img/icons/error.svg) no-repeat; }
    form .error:hover {
      font-size: 14px;
      font-weight: 200;
      font-style: italic;
      color: #FF0000;
      line-height: 18px;
      text-indent: 0;
      width: auto;
      height: auto;
      background-color: #FFF;
      border: solid 1px #FF0000;
      padding: 4px 38px 4px 10px;
      margin-right: -1px; }
  form#registration .form-group-flex {
    margin-bottom: 50px; }

.chk-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 30px;
  padding-left: 44px;
  margin-bottom: 12px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .chk-container:hover .checkmark {
    border-color: #18B8C4; }
  .chk-container, .chk-container a {
    font-size: 14px;
    color: #666666;
    line-height: 18px; }
    .chk-container.fontN, .chk-container a.fontN {
      font-size: 16px; }
    .chk-container.fontL, .chk-container a.fontL {
      font-size: 18px; }
  .chk-container a {
    display: contents;
    color: #18B8C4;
    text-decoration: none;
    margin: 0 3px; }
    .chk-container a:hover {
      text-decoration: underline; }
  .chk-container label {
    margin-bottom: 0; }
  .chk-container input {
    cursor: pointer;
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0; }
    .chk-container input:checked ~ .checkmark {
      border-color: #18B8C4;
      background-color: #18B8C4; }
    .chk-container input:checked ~ .checkmark:after {
      display: block; }
  .chk-container .checkmark {
    position: absolute;
    top: -3px;
    left: 0;
    width: 35px;
    height: 35px;
    border: 1px solid #B9EAED;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background-clip: padding-box; }
    .chk-container .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 0;
      left: 0;
      width: 33px;
      height: 33px;
      background-image: url("../img/icons/check-w.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 17px 13px; }
  .chk-container.reverse {
    line-height: 30px;
    padding-left: 0;
    padding-right: 40px; }
    .chk-container.reverse .checkmark {
      top: -3px;
      right: -3px;
      left: auto; }
  .chk-container-circle {
    font-size: 16px;
    line-height: 1.125; }
    .chk-container-circle .checkmark {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: padding-box; }
      .chk-container-circle .checkmark:after {
        top: 9px;
        left: 9px;
        width: 15px;
        height: 15px;
        background-color: #FFF;
        background-image: none;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-clip: padding-box; }

.chk-content {
  display: none;
  max-width: 100%;
  flex-basis: 100%;
  color: #666666;
  line-height: 1.5;
  background-color: #E7F8F8;
  -webkit-border-radius: 0 0 28px 28px;
  -moz-border-radius: 0 0 28px 28px;
  border-radius: 0 0 28px 28px;
  background-clip: padding-box;
  padding: 28px 47px;
  margin-top: 6px; }

.chk-container-rounded .checkmark {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box; }

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px; }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ADADAD;
    border-radius: 34px;
    -webkit-transition: .4s;
    transition: .4s; }
    .switch .slider:before {
      content: '';
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      left: 2px;
      background-color: white;
      border-radius: 50%;
      -webkit-transition: .4s;
      transition: .4s; }
  .switch input:checked + .slider {
    background-color: #18B8C4; }
    .switch input:checked + .slider:before {
      -ms-transform: translate(37px, -50%);
      -webkit-transform: translate(37px, -50%);
      -moz-transform: translate(37px, -50%);
      -o-transform: translate(37px, -50%);
      transform: translate(37px, -50%); }
  .switch input:focus + .slider {
    box-shadow: 0 0 1px #18B8C4; }

.form-group {
  position: relative;
  padding: 0 15px;
  margin-bottom: 41px; }
  .form-group textarea::-webkit-input-placeholder {
    font-style: normal;
    color: #666666;
    opacity: 1;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group textarea::-moz-placeholder {
    font-style: normal;
    color: #666666;
    opacity: 1;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group textarea:-ms-input-placeholder {
    font-style: normal;
    color: #666666;
    opacity: 1;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group input::-webkit-input-placeholder {
    font-style: normal;
    color: transparent;
    opacity: 0;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group input::-moz-placeholder {
    font-style: normal;
    color: transparent;
    opacity: 0;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group input:-ms-input-placeholder {
    font-style: normal;
    color: transparent;
    opacity: 0;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group:before, .form-group:after {
    content: '';
    position: absolute; }
  .form-group:before {
    bottom: 0;
    left: 15px;
    width: 0;
    height: 3px;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    background-color: #18B8C4;
    z-index: 2; }
  .form-group:after {
    display: block;
    opacity: 0;
    visibility: hidden;
    top: -2px;
    width: calc(100% - 30px);
    height: 29px;
    background-color: #FFE2E2;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    background-clip: padding-box;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
  .form-group.active:before, .form-group.has-error:before {
    width: calc(100% - 30px); }
  .form-group.active textarea::-webkit-input-placeholder, .form-group.has-error textarea::-webkit-input-placeholder {
    font-style: normal;
    color: transparent;
    opacity: 0;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group.active textarea::-moz-placeholder, .form-group.has-error textarea::-moz-placeholder {
    font-style: normal;
    color: transparent;
    opacity: 0;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group.active textarea:-ms-input-placeholder, .form-group.has-error textarea:-ms-input-placeholder {
    font-style: normal;
    color: transparent;
    opacity: 0;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group.active input::-webkit-input-placeholder, .form-group.has-error input::-webkit-input-placeholder {
    font-style: normal;
    color: #666666;
    opacity: 1;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group.active input::-moz-placeholder, .form-group.has-error input::-moz-placeholder {
    font-style: normal;
    color: #666666;
    opacity: 1;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group.active input:-ms-input-placeholder, .form-group.has-error input:-ms-input-placeholder {
    font-style: normal;
    color: #666666;
    opacity: 1;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .form-group.active .form-label, .form-group.has-value .form-label, .form-group.has-error .form-label, .form-group.has-disabled .form-label {
    top: -26px;
    font-size: 13px; }
  .form-group.active .form-label {
    color: #18B8C4; }
  .form-group.has-error .form-label {
    opacity: 0;
    visibility: hidden; }
  .form-group.has-error:before {
    background-color: #FF0000; }
  .form-group.has-error:after {
    opacity: 1;
    visibility: visible; }
  .form-group.has-error .form-error {
    opacity: 1;
    visibility: visible; }
  .form-group.has-disabled {
    pointer-events: none; }
    .form-group.has-disabled:before {
      display: none;
      background-color: #ADADAD; }
    .form-group.has-disabled .form-label, .form-group.has-disabled .form-control {
      color: #ADADAD; }
    .form-group.has-disabled .form-control {
      border-color: #ADADAD;
      padding-right: 20px; }
  .form-group.noline:before {
    display: none; }
  .form-group-split {
    position: relative;
    display: flex;
    flex-wrap: wrap; }
    .form-group-split .form-label {
      max-width: 100%;
      flex-basis: 100%; }
    .form-group-split p, .form-group-split .form-control {
      display: inline-block;
      line-height: 1; }
    .form-group-split p {
      height: 23px;
      font-size: 16px;
      padding-left: 15px; }
      .form-group-split p:before {
        content: '';
        position: absolute;
        display: block;
        left: 15px;
        bottom: 0;
        width: calc(100% - 30px);
        height: 1px;
        background-color: #8A8A8A; }
    .form-group-split .form-control {
      width: auto;
      height: 26px;
      border-bottom: none;
      padding-left: 0;
      margin-top: -6px; }
    .form-group-split .delete-url {
      cursor: pointer;
      position: absolute;
      top: -30px;
      right: 15px;
      z-index: 1; }
    .form-group-split:after {
      top: -6px; }
  .form-group-submit {
    position: relative;
    max-width: 300px;
    width: 100%;
    border-bottom: none;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background-clip: padding-box;
    overflow: hidden;
    margin: 0 auto 18px; }
    .form-group-submit:before {
      display: none; }
    .form-group-submit:hover .form-submit-bg {
      left: -200%; }
  .form-group-organization {
    display: none;
    margin-top: 20px; }
    .form-group-organization.noline {
      margin-top: -10px; }
  .form-group-flex {
    display: flex;
    flex-wrap: wrap; }
  .form-group .select2 {
    position: relative;
    line-height: 29px;
    border-bottom: 1px solid #ADADAD;
    background-color: transparent;
    padding-bottom: 8px;
    margin-bottom: 0;
    z-index: 1; }
    .form-group .select2-container--default .select2-selection--single {
      height: 21px;
      background-color: transparent;
      border: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background-clip: padding-box; }
      .form-group .select2-container--default .select2-selection--single .select2-selection__rendered {
        font-size: 16px;
        color: #666666 !important;
        -ms-transform: translateY(4px);
        -webkit-transform: translateY(4px);
        -moz-transform: translateY(4px);
        -o-transform: translateY(4px);
        transform: translateY(4px);
        padding-left: 15px; }
      .form-group .select2-container--default .select2-selection--single .select2-selection__placeholder {
        display: block;
        width: 100%;
        height: 29px; }
    .form-group .select2-container--default .select2-selection__arrow {
      width: 16px;
      height: 9px;
      top: 50%;
      right: 0;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
  .form-group .tooltip-trigger {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 15px;
    z-index: 100; }
  .form-group.input-radio:before, .form-group.input-radio:after {
    display: none; }
  .form-group-biglabel .form-label {
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    top: -26px;
    font-size: 18px !important; }
  .form-group-biglabel span.select2, .form-group-biglabel textarea {
    margin-top: 23px; }
  .form-group-biglabel textarea {
    display: initial; }

.form-label, .form-control {
  display: block;
  width: 100%;
  font-size: 16px;
  color: #666666; }

.form-label, .form-error {
  position: absolute;
  left: 30px; }

.form-label {
  cursor: pointer;
  top: 0;
  line-height: 29px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }

.form-checkbox:before {
  display: none; }

.form-control {
  position: relative;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border-bottom: 1px solid #ADADAD;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
  box-shadow: none;
  outline: none;
  padding: 2px 15px;
  z-index: 1; }
  .form-control input[type=number] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield; }
    .form-control input[type=number]::-webkit-outer-spin-button, .form-control input[type=number]::-webkit-inner-spin-button {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none; }
  .form-control-textarea {
    background-image: url("../img/icons/textarea.svg");
    background-position: right calc(100% - 7px);
    background-repeat: no-repeat;
    background-size: auto;
    border: none;
    border-bottom: 1px solid #8A8A8A;
    resize: none; }
    .form-control-textarea.input-check {
      padding-right: 55px; }
  .form-control-select {
    background-color: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-clip: padding-box;
    min-height: 29px;
    margin-bottom: 0; }

.form-control-normal {
  font-size: 16px;
  color: #666666;
  max-width: 550px;
  width: 100%;
  height: 49px;
  background-color: #FFF;
  border: 1px solid #ADADAD;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box;
  padding-left: 15px;
  padding-right: 15px; }
  .form-control-normal-link {
    background-image: url("../img/icons/link.svg");
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: auto;
    padding-left: 40px; }

.form-error {
  display: block;
  top: -26px;
  width: 100%;
  font-size: 13px;
  color: #FF0000;
  line-height: 29px;
  opacity: 0;
  visibility: hidden;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .form-error#error-phone-reg {
    width: 305px;
    top: 31px;
    left: -101px;
    line-height: 1.2; }

.form-disabled {
  pointer-events: none; }

.form-icon {
  position: absolute;
  top: -2px;
  right: 15px; }
  .form-icon-bday {
    display: none; }

.form-content p, .form-content a {
  display: block;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 27px; }

.form-content p {
  color: #666666; }

.form-content a {
  color: #49B8C0; }

.form-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row; }
  .form-radio p {
    max-width: calc(100% - 80px);
    flex-basis: calc(100% - 80px);
    text-align: right;
    margin-bottom: 0; }

.form-checkbox:after {
  display: none; }

.select2-container-countrycode {
  max-width: 305px;
  width: calc(100% - 60px); }

.select2-dropdown.form-select {
  max-width: unset;
  background-color: transparent;
  box-shadow: none;
  transform: translateY(-31px);
  padding: 0; }
  .select2-dropdown.form-select .select2-search__field {
    font-size: 16px;
    color: #666666;
    width: calc(100% - 10px) !important;
    height: 29px;
    background-color: transparent;
    border-bottom: none; }
  .select2-dropdown.form-select .select2-results {
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px; }
    .select2-dropdown.form-select .select2-results__option {
      font-size: 16px;
      color: #666666;
      padding: 6px 10px; }
  .select2-dropdown.form-select.form-countrycode {
    width: 100% !important; }
    .select2-dropdown.form-select.form-countrycode .select2-results__options li:first-child {
      display: none; }

.check-password {
  position: absolute;
  top: 38px;
  left: 15px;
  width: calc(100% - 30px);
  padding: 0 15px; }
  .check-password .row {
    margin-left: -17.5px;
    margin-right: -17.5px; }
  .check-password .col-sm-3, .check-password .col-sm-12 {
    padding: 0 2.5px; }
  .check-password .indicator {
    margin-bottom: 7px; }
    .check-password .indicator span {
      display: block;
      height: 4px;
      background-color: rgba(0, 0, 0, 0.1); }
    .check-password .indicator.active span {
      background-color: #00FF1D; }

textarea {
  min-height: 30px; }
