.page-settings-section {
  max-width: 650px;
  flex-basis: 650px;
  margin: 0 auto 50px; }

.page-settings-social p {
  font-size: 18px; }

.page-settings-tax .form-group-flex {
  margin-top: 25px;
  margin-bottom: 0; }

.page-settings-sociallogin {
  max-width: 100%;
  flex-basis: 100%; }

.page-settings .subtitle {
  font-weight: 600;
  font-size: 18px;
  color: #18B8C4;
  margin-bottom: 20px; }

.page-settings .buttons {
  max-width: 245px;
  flex-basis: 245px;
  margin: 0 auto 20px; }

.page-settings #account-delete {
  display: table;
  font-style: normal;
  color: #18B8C4;
  margin: 30px auto 0; }

.page-settings .add-page {
  display: table;
  margin: 29px auto 0; }

.page-settings .add-container .form-group-split:first-child {
  margin-top: 34px; }

.page-settings .input-urls {
  display: none; }
  .page-settings .input-urls.sys {
    display: block; }

.page-settings .edit {
  cursor: pointer;
  position: absolute;
  width: 35px;
  height: 35px;
  top: 0;
  right: 15px;
  background-image: url("../img/icons/pen-red.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain; }
  .page-settings .edit.edited {
    background-image: url("../img/icons/pen-blue.svg"); }

.page-settings .form-group.has-value .form-label {
  top: -23px; }

.page-settings .form-group-biglabel .form-label {
  top: -23px;
  font-weight: 600;
  color: #18B8C4; }

.page-settings .form-group-biglabel span.select2, .page-settings .form-group-biglabel textarea {
  margin-top: 18px; }

.page-settings .form-group-full {
  margin-bottom: 0; }
  .page-settings .form-group-full.form-radio {
    margin-bottom: 10px; }
    .page-settings .form-group-full.form-radio:last-child {
      margin-bottom: 0; }

.page-settings .form-group.form-group-flex {
  margin-left: auto;
  margin-right: auto; }
  .page-settings .form-group.form-group-flex label {
    margin: 0 15px; }
  .page-settings .form-group.form-group-flex p {
    max-width: unset;
    flex-basis: unset;
    font-size: 16px; }

.page-settings .form-group-split {
  margin-bottom: 30px; }
  .page-settings .form-group-split.has-value .form-label {
    top: -26px; }
  .page-settings .form-group-split p {
    font-size: 16px; }
  .page-settings .form-group-split .delete-url {
    top: -13px; }
    .page-settings .form-group-split .delete-url img {
      width: 30px;
      height: auto; }

.page-settings .tab-other-billing-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px; }
  .page-settings .tab-other-billing-item .chk-container-circle {
    padding-left: 50px;
    margin-bottom: 0; }
  .page-settings .tab-other-billing-item:last-child {
    margin-bottom: 0; }

.page-settings .tab-other-billing-box {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ADADAD;
  margin-right: 35px; }
  .page-settings .tab-other-billing-box .form-group {
    margin-top: 20px; }

.page-settings .tab-other-billing-text {
  flex-wrap: wrap; }
  .page-settings .tab-other-billing-text p {
    font-size: 18px; }
  .page-settings .tab-other-billing-text .form-group {
    margin-top: 20px; }

.page-settings .tab-other-billing-item-flex .tab-other-billing-box {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center; }
  .page-settings .tab-other-billing-item-flex .tab-other-billing-box img {
    max-width: 50px; }
  .page-settings .tab-other-billing-item-flex .tab-other-billing-box p {
    font-weight: 500;
    color: #18B8C4; }

.page-settings .tab-other-billing-item-flex .tab-other-billing-text {
  display: flex; }
  .page-settings .tab-other-billing-item-flex .tab-other-billing-text p {
    max-width: 100%;
    flex-basis: 100%; }
  .page-settings .tab-other-billing-item-flex .tab-other-billing-text .form-group {
    max-width: 50%;
    flex-basis: 50%;
    margin-left: 0;
    margin-right: 0; }

@media screen and (min-width: 10px) {
  .page-registration .buttons,
  .page-settings .buttons {
    display: flex;
    justify-content: space-between; }
  .page-settings {
    padding: 20px 0 40px; }
    .page-settings .row-center {
      justify-content: center;
      flex-direction: column; }
    .page-settings .clone-area label {
      text-transform: capitalize; }
    .page-settings .separator {
      margin-bottom: 17px; }
    .page-settings .btn-add {
      margin-top: -25px; }
    .page-settings .add-input-website {
      margin-bottom: 28px; }
    .page-settings .tab-other-billing-item .chk-container-circle {
      margin-top: 15px; }
    .page-settings .tab-other-billing-item-flex {
      padding-left: 0; }
      .page-settings .tab-other-billing-item-flex .tab-other-billing-box {
        max-width: 100%;
        flex-basis: 100%;
        min-width: 100%; }
        .page-settings .tab-other-billing-item-flex .tab-other-billing-box img {
          margin-left: auto;
          margin-right: 10px;
          margin-bottom: 0; }
        .page-settings .tab-other-billing-item-flex .tab-other-billing-box p {
          margin-right: auto; }
    .page-settings .tab-other-billing-box {
      max-width: calc(100% - 60px);
      flex-basis: calc(100% - 60px);
      min-width: calc(100% - 60px);
      height: 60px;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 20px; }
      .page-settings .tab-other-billing-box img {
        max-width: unset;
        width: auto;
        height: 30px; }
    .page-settings .tab-other-billing-text {
      max-width: 100%;
      flex-basis: 100%; } }

@media screen and (min-width: 768px) {
  .page-settings .tab-other-billing-item {
    flex-wrap: unset; }
    .page-settings .tab-other-billing-item .chk-container-circle {
      margin-top: 52px; }
  .page-settings .tab-other-billing-item-flex {
    padding-left: 50px;
    margin-bottom: 0; }
    .page-settings .tab-other-billing-item-flex .tab-other-billing-box {
      max-width: 140px;
      flex-basis: 140px;
      min-width: 140px;
      height: 140px; }
      .page-settings .tab-other-billing-item-flex .tab-other-billing-box img {
        margin-left: unset;
        margin-right: unset;
        margin-bottom: 15px; }
      .page-settings .tab-other-billing-item-flex .tab-other-billing-box p {
        margin-right: unset; }
  .page-settings .tab-other-billing-box {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    max-width: 140px;
    flex-basis: 140px;
    min-width: 140px;
    height: 140px;
    border: 2px dashed #ADADAD;
    margin-right: 35px;
    margin-bottom: 0; }
    .page-settings .tab-other-billing-box img {
      max-width: 80%;
      height: auto; }
  .page-settings .tab-other-billing-text {
    max-width: calc(100% - 140px);
    flex-basis: calc(100% - 140px); }
    .page-settings .tab-other-billing-text p {
      font-size: 18px; } }

@media screen and (min-width: 992px) {
  .page-settings {
    padding-top: 0; }
    .page-settings form#settings {
      display: flex;
      flex-wrap: wrap; }
      .page-settings form#settings #account-delete {
        max-width: 100%;
        flex-basis: 100%; }
      .page-settings form#settings .buttons {
        margin-top: 20px; }
      .page-settings form#settings #account-delete {
        text-align: center; } }
