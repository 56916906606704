h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  display: block;
  width: 100%;
  font-weight: 600;
  -webkit-font-smoothing: antialiased; }

.hero {
  font-weight: 500;
  line-height: 1.5; }

h1, .h1 {
  font-size: 44px;
  color: #647AA0;
  line-height: 1.0869; }

h2, .h2 {
  font-size: 32px;
  color: #647AA0;
  line-height: 45px;
  letter-spacing: -0.5px; }

h3, .h3 {
  font-size: 28px;
  color: #666666;
  line-height: 42px; }

h4, .h4 {
  font-size: 20px;
  color: #666666;
  line-height: 28px; }

h5, .h5 {
  font-size: 24px;
  line-height: 26px; }

h6, .h6 {
  font-size: 20px;
  font-weight: 400;
  color: #666666;
  line-height: 28px; }

a, .a, p, .p, span, ul.ul, ul, ol {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  -webkit-font-smoothing: antialiased; }

p, .p, span, ul.ul {
  color: #666666; }
