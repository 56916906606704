.page-content {
  padding-top: 50px; }
  .page-content .content-area {
    padding-bottom: 0;
    margin-bottom: -29px; }

.content-header {
  position: relative; }
  .content-header:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(100, 122, 160, 0.5);
    z-index: 1; }
  .content-header h1 {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    color: #FFF;
    text-align: center;
    z-index: 1; }

.content-lead {
  position: relative;
  width: 100%;
  background-color: #FFF;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  border-radius: 26px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
  z-index: 2; }
  .content-lead .date {
    display: block;
    font-weight: 600;
    margin-bottom: 3px; }

.content-area {
  max-width: 750px;
  padding-bottom: 11px;
  margin: 0 auto; }
  .content-area h3 {
    color: #647AA0; }
  .content-area h2, .content-area h3, .content-area h4, .content-area h5, .content-area h6, .content-area p, .content-area a:not(.input-submit):not(.btn), .content-area table, .content-area ul, .content-area ol, .content-area iframe, .content-area img:not(.content-img),
  .content-area div:not(.input-group):not(.input-submit-bg):not(.btn-big):not(.btn-bg):not(.btn-bg-w):not(.content) {
    margin-bottom: 29px; }
  .content-area p a {
    margin-bottom: 0; }
  .content-area ul {
    padding-left: 20px; }
    .content-area ul li {
      position: relative;
      font-size: 18px;
      color: #666666;
      line-height: 24px; }
      .content-area ul li:before {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        left: -15px;
        width: 7px;
        height: 7px;
        background-color: #666666;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-clip: padding-box; }
  .content-area table {
    width: 100%;
    color: #666666;
    border-collapse: collapse;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    background-clip: padding-box;
    border: 1px solid #B9EAED;
    box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
    overflow: hidden;
    background-color: #FFF; }
    .content-area table thead tr th, .content-area table tfoot tr th {
      font-weight: 500; }
    .content-area table thead tr th {
      font-size: 32px; }
    .content-area table tbody tr td {
      font-size: 18px; }
    .content-area table tfoot tr th {
      font-size: 18px; }
      .content-area table tfoot tr th a {
        text-decoration: underline; }
    .content-area table tr {
      border-bottom: 1px solid #B9EAED; }
      .content-area table tr td div, .content-area table tr td span, .content-area table tr td a, .content-area table tr td p {
        margin-bottom: 0; }

.content-buttons {
  display: flex; }
