@media screen and (min-width: 992px) {
  .hero {
    font-size: 28px; }
  h2, .h2 {
    font-size: 32px;
    line-height: 45px; }
  h3, .h3 {
    font-size: 28px;
    line-height: 42px; }
  h4, .h4 {
    font-size: 20px; }
  a, .a, p, .p, span, ul.ul, ul, ol {
    font-size: 20px; }
  .body {
    padding-top: 75px; }
  html .menu-nav {
    display: none; }
  html.user-loggedin .menu-user {
    display: none; }
  html.user-loggedin .navbar-nav li.li-login {
    display: none; }
  html.user-loggedin .navbar-nav li.li-profil {
    display: flex; }
  .navbar {
    height: 75px; }
    .navbar-logo {
      display: inherit; }
    .navbar-logo-mobil {
      display: none; }
    .navbar-nav {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      position: static;
      top: unset;
      left: unset;
      width: unset;
      min-height: unset;
      background-color: transparent;
      -ms-transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      max-height: unset;
      overflow: visible; }
      .navbar-nav li {
        width: unset;
        height: auto;
        padding: 0;
        margin: 0 5px; }
        .navbar-nav li:hover, .navbar-nav li.active {
          background-color: #FFF; }
          .navbar-nav li:hover a, .navbar-nav li:hover .navbar-clickable, .navbar-nav li.active a, .navbar-nav li.active .navbar-clickable {
            color: #18B8C4;
            text-decoration: none; }
        .navbar-nav li a, .navbar-nav li .navbar-clickable {
          cursor: pointer;
          height: auto;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 26px;
          padding: 10px 0; }
        .navbar-nav li .li-title {
          display: none; }
        .navbar-nav li .li-downarrow {
          display: block; }
        .navbar-nav li.li-country {
          margin-right: 5px; }
          .navbar-nav li.li-country .li-country-flag {
            margin-right: 5px; }
        .navbar-nav li.li-login-mobil {
          display: none; }
        .navbar-nav li.search, .navbar-nav li.li-login {
          display: block; }
        .navbar-nav li.li-lang {
          display: inline-block; }
    .navbar-extra {
      display: none; }
  .submenu-lang, .submenu-country {
    position: absolute;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
    margin-left: 0;
    margin-right: 0; }
    .submenu-lang:before, .submenu-country:before {
      display: block; }
  .submenu-lang {
    top: 64px;
    right: -4px;
    width: 190px; }
  .submenu-country {
    top: 59px;
    right: -35px;
    width: 267px; }
  .breadcrumb {
    padding: 30px 0 0; }
  .page-header {
    padding-top: 30px; }
    .page-header .h1-hl {
      font-size: 50px;
      line-height: 60px; }
      .page-header .h1-hl br {
        display: block; }
    .page-header h2.h6 {
      font-size: 20px;
      margin-top: 43px;
      margin-bottom: 40px; }
      .page-header h2.h6 br {
        display: block; }
    .page-header h2.h3 {
      font-size: 28px;
      margin-bottom: 40px; }
    .page-header p {
      margin-bottom: 54px; }
    .page-header .col-md-6 .btn-anim, .page-header .col-md-5 .btn-anim, .page-header .col-md-4 .btn-anim {
      margin-bottom: 0; }
    .page-header .col-md-6 img, .page-header .col-md-7 img, .page-header .col-md-8 img {
      max-width: 720px;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: auto; }
    .page-header .col-sm-12 .btn-anim {
      display: none; }
    .page-header-full .col-md-5, .page-header-full .col-md-4 {
      order: 1; }
    .page-header-full .col-md-7, .page-header-full .col-md-8 {
      order: 2; }
    .page-header.pc-header {
      display: block; }
  .page-registration {
    padding-bottom: 44px; }
    .page-registration form {
      display: flex;
      flex-wrap: wrap; }
    .page-registration .btn-anim {
      margin-top: 28px;
      margin-bottom: 43px; }
    .page-registration-step1 .template-container, .page-registration-step1 #templateholder {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
    .page-registration-step1 .blocktemplate, .page-registration-step1 #template {
      position: relative;
      max-width: 370px;
      flex-basis: 100%;
      -webkit-border-radius: 18px;
      -moz-border-radius: 18px;
      border-radius: 18px;
      background-clip: padding-box;
      box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
      padding: 29px 20px 95px;
      margin-bottom: 25px; }
      .page-registration-step1 .blocktemplate:nth-child(even), .page-registration-step1 #template:nth-child(even) {
        margin-left: auto; }
      .page-registration-step1 .blocktemplate.new-blocktemplate, .page-registration-step1 #template.new-blocktemplate {
        padding-bottom: 75px; }
      .page-registration-step1 .blocktemplate .input-group.input-noborder.input-group-inline.input-center, .page-registration-step1 #template .input-group.input-noborder.input-group-inline.input-center {
        position: static; }
        .page-registration-step1 .blocktemplate .input-group.input-noborder.input-group-inline.input-center .edit, .page-registration-step1 #template .input-group.input-noborder.input-group-inline.input-center .edit {
          top: 29px;
          right: 15px; }
      .page-registration-step1 .blocktemplate .input-group.input-editable, .page-registration-step1 #template .input-group.input-editable {
        position: static;
        border-bottom: none; }
        .page-registration-step1 .blocktemplate .input-group.input-editable .line, .page-registration-step1 #template .input-group.input-editable .line {
          display: none; }
        .page-registration-step1 .blocktemplate .input-group.input-editable .edit, .page-registration-step1 #template .input-group.input-editable .edit {
          top: auto;
          bottom: 15px;
          right: 15px; }
        .page-registration-step1 .blocktemplate .input-group.input-editable .input-textarea, .page-registration-step1 #template .input-group.input-editable .input-textarea {
          padding-right: 0; }
    .page-registration-step1 #template {
      height: 100%;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background-clip: padding-box;
      box-shadow: none;
      padding: 0; }
      .page-registration-step1 #template .blocktemplate {
        flex-basis: unset;
        max-width: unset; }
    .page-registration-step1 .form-group .delete, .page-registration-step1 .input-group .delete {
      top: auto;
      bottom: 15px;
      left: 15px; }
  .page-photoupload .buttons {
    display: block; }
    .page-photoupload .buttons .btn-secondary:nth-child(2) {
      margin: 0 auto 14px; }
  .page-editprofile {
    padding: 70px 0; }
    .page-editprofile form {
      display: flex;
      flex-wrap: wrap; }
      .page-editprofile form .form-group-submit {
        max-width: 300px;
        flex-basis: unset; }
  .page-dashboard {
    padding-top: 0; }
    .page-dashboard .slick-slide {
      display: flex;
      flex-wrap: wrap; }
    .page-dashboard .slick-headline {
      max-width: 100%;
      flex-basis: 100%; }
    .page-dashboard .slick-left, .page-dashboard .slick-right {
      max-width: 50%;
      flex-basis: 50%; }
    .page-dashboard .slick-right {
      align-self: center;
      padding: 0 30px; }
    .page-dashboard .slick-img {
      max-width: 70%;
      margin-bottom: 0; }
  .page-donations {
    padding-top: 50px; }
    .page-donations .user-box .left {
      max-width: 100px;
      flex-basis: 100px; }
      .page-donations .user-box .left img {
        width: 100%;
        height: auto; }
    .page-donations .user-box .right {
      max-width: calc(100% - 100px);
      flex-basis: calc(100% - 100px);
      padding-left: 9px; }
    .page-donations .user-box-container {
      max-width: 50%;
      flex-basis: 50%;
      padding: 0 15px; }
    .page-donations .notyet .user-box-container {
      max-width: 100%;
      flex-basis: 100%; }
    .page-donations .donation-container {
      display: flex;
      flex-wrap: wrap; }
      .page-donations .donation-container .filter-container {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 51px; }
        .page-donations .donation-container .filter-container p {
          font-size: 28px; }
          .page-donations .donation-container .filter-container p span {
            font-size: 24px; }
    .page-donations-public .donation-monthly {
      min-height: 27px; }
  .page-paywall .form-checkbox {
    max-width: 750px;
    flex-basis: 750px;
    margin: 0 auto; }
  .page-howtochange-community {
    padding-top: 50px; }
  .page-howtochange-nothingelse {
    padding-top: 55px; }
  .page-howtochange-useit {
    padding-top: 70px;
    padding-bottom: 70px; }
  .page-suspended {
    padding: 70px 0; }
  .page-invitation .col-sm-6 {
    max-width: 50%;
    flex-basis: 50%; }
  .page-tariff {
    padding-top: 50px;
    padding-bottom: 17px; }
  .page-compare-twocolumn {
    padding-top: 60px;
    padding-bottom: 70px; }
  .page-compare-content .text-content h2 {
    text-align: left; }
  .page-compare-content .row {
    padding-left: 15px;
    padding-right: 15px; }
    .page-compare-content .row.row-second {
      padding-left: 15px;
      padding-right: 15px; }
  .page-compare .rounded {
    margin-left: 0;
    margin-right: 0; }
  .page-fanpage .fanpage-header {
    height: 390px; }
  .page-fanpage .fanpage-social-bottom {
    max-width: 458px; }
    .page-fanpage .fanpage-social-bottom .btn-anim {
      max-width: 221px; }
  .fanpage-submenu.sys {
    min-width: 223px; }
  .two-column-text {
    align-self: center;
    margin-bottom: 0; }
    .two-column-text h2 {
      margin-bottom: 35px; }
    .two-column-text p, .two-column-text ul, .two-column-text ol {
      margin-bottom: 28px; }
    .two-column-text.col-md-6 {
      padding-left: 50px; }
    .two-column-text .btn-anim {
      display: flex;
      margin-top: 40px; }
  .two-column-image {
    text-align: unset; }
    .two-column-image .btn-anim {
      display: none; }
  .two-column-reverse .two-column-text {
    order: 2;
    padding-left: 0; }
  .two-column-reverse .two-column-image {
    order: 1;
    text-align: unset; }
    .two-column-reverse .two-column-image.col-md-6 {
      padding-left: 50px; }
  .page-services-circle {
    display: none; }
  .page-services-desktop {
    display: table !important; }
  .page-services-mobil {
    display: none !important; }
  .page-services .two-column-image {
    align-self: flex-start; }
  .page-services .two-column-services {
    padding-top: 59px; }
    .page-services .two-column-services:last-child {
      padding-bottom: 70px; }
    .page-services .two-column-services-01 {
      padding-top: 70px; }
      .page-services .two-column-services-01 h3 {
        font-weight: 500; }
      .page-services .two-column-services-01 img {
        max-width: unset; }
  .page-services .two-column-services-01 .two-column-text .btn-anim {
    margin: 40px 0 0; }
  .page-services .two-column-services-01 .two-column-image {
    align-self: center;
    margin-top: 0; }
  .services-box {
    padding-top: 70px;
    padding-bottom: 30px; }
  .services-item {
    margin-bottom: 40px; }
  .services-content {
    padding: 0 20px 40px; }
    .services-content h2 {
      font-size: 24px;
      margin-bottom: 33px; }
  .services-picture {
    margin-bottom: 35px; }
  .page-compare-v2 {
    padding-top: 63px; }
    .page-compare-v2 .pc-container {
      padding-bottom: 13px; }
      .page-compare-v2 .pc-container h2 {
        margin-bottom: 50px; }
      .page-compare-v2 .pc-container-table h2 {
        padding-top: 50px;
        margin-bottom: 40px; }
    .page-compare-v2 .pc-logo {
      height: 50px;
      margin-bottom: 7px; }
    .page-compare-v2 .pc-column {
      max-width: 20%;
      flex-basis: 20%;
      border-top: 1px solid #E1E1E1;
      border-right: 1px solid #E1E1E1; }
      .page-compare-v2 .pc-column:nth-child(even) {
        background-color: #F8F8F8; }
      .page-compare-v2 .pc-column:nth-child(odd) {
        background-color: #FFF; }
      .page-compare-v2 .pc-column span, .page-compare-v2 .pc-column p {
        font-size: 12px;
        line-height: 1.2; }
      .page-compare-v2 .pc-column p {
        font-size: 18px; }
        .page-compare-v2 .pc-column p img {
          width: 27px; }
      .page-compare-v2 .pc-column span {
        font-size: 22px;
        font-weight: 400; }
      .page-compare-v2 .pc-column.best-choice p {
        font-size: 24px; }
    .page-compare-v2 .pc-title {
      background-color: transparent;
      margin-bottom: 0; }
      .page-compare-v2 .pc-title .pc-title {
        display: flex;
        border-top: none; }
      .page-compare-v2 .pc-title .pc-column {
        padding-top: 14px;
        padding-bottom: 14px; }
        .page-compare-v2 .pc-title .pc-column:nth-child(2) .pc-logo img {
          width: 47px;
          height: auto; }
        .page-compare-v2 .pc-title .pc-column:nth-child(3) .pc-logo img {
          width: 45px;
          height: auto; }
        .page-compare-v2 .pc-title .pc-column:nth-child(4) .pc-logo img {
          width: 45px;
          height: auto; }
    .page-compare-v2 .pc-row {
      background-color: transparent;
      border-left: none;
      border-top: none;
      border-right: none; }
      .page-compare-v2 .pc-row:last-child {
        border-bottom: none; }
        .page-compare-v2 .pc-row:last-child .pc-column {
          border-bottom: 1px solid #E1E1E1; }
      .page-compare-v2 .pc-row .pc-column {
        display: flex;
        align-items: center;
        max-width: 20%;
        flex-basis: 20%;
        min-height: 63px; }
        .page-compare-v2 .pc-row .pc-column:first-child {
          border-left: 1px solid #E1E1E1; }
        .page-compare-v2 .pc-row .pc-column:not(.justify-center), .page-compare-v2 .pc-row .pc-column.justify-center {
          padding: 7px 13px; }
        .page-compare-v2 .pc-row .pc-column:not(.justify-center) p {
          font-weight: 400;
          hyphens: auto; }
        .page-compare-v2 .pc-row .pc-column.justify-center {
          max-width: 20%;
          flex-basis: 20%; }
          .page-compare-v2 .pc-row .pc-column.justify-center:nth-child(even) {
            background-color: #F8F8F8; }
          .page-compare-v2 .pc-row .pc-column.justify-center:nth-child(odd) {
            background-color: #FFF; }
          .page-compare-v2 .pc-row .pc-column.justify-center:last-child {
            border-right: 1px solid #E1E1E1; }
    .page-compare-v2 .two-column {
      padding-top: 0;
      padding-bottom: 42px;
      margin-top: -13px; }
  .d-tab-item {
    font-size: 20px;
    height: 58px; }
    .d-tab-item span {
      display: inline-block; }
  .page-help .help-box {
    height: 145px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-clip: padding-box; }
    .page-help .help-box-text {
      font-size: 15px;
      min-height: 56px; }
    .page-help .help-box-ico {
      display: flex; }
    .page-help .help-box-parent {
      max-width: 20%;
      flex-basis: 20%; }
  .page-help .gyik-content {
    padding: 0;
    margin: 0 auto 50px auto; }
    .page-help .gyik-content .gyik-title {
      font-size: 32px;
      margin: 50px 0 30px; }
    .page-help .gyik-content .gyik-item-title {
      padding: 25px 0 20px 40px; }
    .page-help .gyik-content .gyik-item-txt {
      padding: 0 40px; }
      .page-help .gyik-content .gyik-item-txt.active {
        padding: 20px 40px 37px; }
  .page-404 {
    padding-top: 70px;
    padding-bottom: 70px; }
    .page-404-text .container {
      padding: 0 15px; }
    .page-404-text .col-xs-12 {
      padding: 0; }
    .page-404-text .rounded {
      padding-top: 61px;
      padding-bottom: 71px; }
      .page-404-text .rounded .hero {
        margin-bottom: 58px; }
        .page-404-text .rounded .hero br {
          display: block; }
  .page-readpost {
    padding-bottom: 70px; }
  .page-content {
    padding-bottom: 70px; }
  .page-blog-list {
    padding-bottom: 70px; }
  .recommender {
    padding-bottom: 70px; }
  .fanpage-posts {
    padding-bottom: 70px; }
  .fanpage-header {
    border: 2px dashed #18B8C4; }
  .fanpage-avatar {
    margin: -101px auto 31px; }
  .fanpage-name {
    margin-bottom: 3px; }
  .fanpage-title {
    margin-bottom: 47px; }
  .fanpage-channelinfo {
    padding: 0 55px;
    margin-bottom: 5px; }
  .fanpage-donations {
    padding: 40px 55px 0; }
  .fanpage-box {
    padding-top: 0;
    margin-bottom: 0; }
    .fanpage-box-inner {
      box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px; }
  .fanpage-texthelp br {
    display: block; }
  .readpost-left {
    max-width: 67%;
    flex-basis: 67%; }
  .readpost-right {
    max-width: 33%;
    flex-basis: 33%; }
  .writty .toolbar {
    max-width: 400px;
    padding: 10px 40px; }
  .post-item {
    width: 465px; }
  .recommender-item {
    margin-bottom: 0; }
  .steps-title h3, .steps-title .h3 {
    font-size: 32px; }
  .steps-01 {
    padding-bottom: 70px; }
    .steps-01 .steps-right {
      display: flex;
      align-items: center; }
      .steps-01 .steps-right .steps-clock {
        margin-right: 30px; }
  .steps-02 .rounded {
    padding-bottom: 69px; }
  .steps-02 .steps-left {
    display: flex;
    align-items: center; }
    .steps-02 .steps-left .steps-clock {
      margin-left: -44px; }
    .steps-02 .steps-left-01 {
      padding-top: 29px; }
  .steps-02 .steps-right-01 {
    padding-top: 76px; }
  .steps-03 {
    padding: 70px 0 66px; }
    .steps-03 .steps-right {
      display: flex;
      align-items: center; }
      .steps-03 .steps-right .steps-clock {
        margin-right: 52px; }
  .steps-04 .steps-left {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .steps-04 .steps-left .steps-clock {
      margin-left: 20px;
      margin-right: 10px; }
  .steps-04 .steps-right {
    padding-top: 82px;
    padding-bottom: 66px; }
  .column-title, .column-data {
    padding: 0 15px; }
  .column-title {
    display: block;
    max-width: calc(100% - (3 * 230px));
    flex-basis: calc(100% - (3 * 230px)); }
    .column-title .column-row {
      min-height: 68px;
      font-size: 22px;
      margin-bottom: 17px; }
  .column-data {
    max-width: 230px;
    flex-basis: 230px;
    overflow: hidden; }
    .column-data:before {
      display: block;
      top: 78px; }
    .column-data .column-row {
      font-size: 30px;
      margin-bottom: 17px; }
      .column-data .column-row.column-full {
        display: none; }
    .column-data .column-title {
      padding: 0 10px; }
  .column-logo {
    min-height: 68px;
    margin-bottom: 29px; }
    .column-logo img {
      height: 40px;
      margin-bottom: -4px; }
  .column-row .inner {
    min-height: 68px;
    font-size: 35px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-clip: padding-box; }
    .column-row .inner div {
      font-size: 28px; }
      .column-row .inner div .currency {
        font-size: 23px; }
  .column-row .currency {
    font-size: 30px;
    margin-top: 4px;
    margin-left: 7px; }
  .column-row p {
    font-size: 24px; }
  .column-row.better .inner:before {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-width: 3px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-clip: padding-box; }
  .column-row.better .inner:after {
    width: calc(100% - 18px);
    height: calc(100% - 18px);
    border-width: 3px; }
  .column-row.better img {
    width: 25px;
    right: 20px; }
  .profil .edit, .profil .delete {
    width: 45px;
    height: 45px; }
  .profil-cover {
    height: 460px; }
  .profil-picture {
    width: 220px;
    height: 220px;
    bottom: -111px; }
    .profil-picture .monogram {
      font-size: 80px; }
  .profil-header {
    margin-bottom: 164px; }
  .profil-content {
    padding: 0 0 21px; }
  .profil .delete {
    top: auto;
    right: auto;
    bottom: 15px;
    left: 15px; }
  .row-form .form-group {
    margin-bottom: -13px; }
  .content-header {
    height: 400px;
    margin-left: 0;
    margin-right: 0; }
    .content-header, .content-header:before {
      -webkit-border-radius: 26px;
      -moz-border-radius: 26px;
      border-radius: 26px;
      background-clip: padding-box; }
  .content-lead {
    padding: 30px;
    margin: -114px auto 38px; }
    .content-lead .date, .content-lead p {
      font-size: 24px;
      line-height: 28px; }
  .content-area p, .content-area img {
    width: auto;
    height: auto; }
  .content-page-paywall .text-label {
    margin-top: 20px;
    margin-bottom: 20px; }
  .input-placeholder {
    display: block; }
  .container {
    max-width: 960px; }
  .body footer {
    padding: 31px 0 32px; }
    .body footer .container {
      padding: 0 15px; }
    .body footer .inner {
      max-width: unset;
      flex-basis: unset; }
    .body footer .copyright {
      padding-top: 35px; }
      .body footer .copyright .col-sm-4 {
        max-width: 33.33333%;
        flex-basis: 33.33333%;
        margin-bottom: 0; }
      .body footer .copyright .col-sm-8 {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        max-width: 66.66667%;
        flex-basis: 66.66667%; }
        .body footer .copyright .col-sm-8 p, .body footer .copyright .col-sm-8 a {
          margin-left: 20px;
          margin-right: 0; }
  .hide-desktop {
    display: none; }
  .hide-on-desktop {
    display: none; }
  .form-group {
    max-width: 50%;
    flex-basis: 50%; }
    .form-group-submit {
      max-width: 300px;
      flex-basis: unset; }
      .form-group-submit:nth-child(even), .form-group-submit:nth-child(odd) {
        margin-right: auto;
        margin-left: auto; }
    .form-group-cc {
      max-width: unset;
      flex-basis: unset;
      min-width: 100px; }
    .form-group-100 {
      max-width: calc(100% - 106px);
      flex-basis: calc(100% - 106px); }
    .form-group-full {
      max-width: 750px;
      flex-basis: 750px;
      margin: 0 auto 41px; }
    .form-group-biglabel .subtitle {
      font-size: 15px;
      line-height: 1.2;
      -ms-transform: translateY(10px);
      -webkit-transform: translateY(10px);
      -moz-transform: translateY(10px);
      -o-transform: translateY(10px);
      transform: translateY(10px);
      margin-top: 50px;
      margin-bottom: 0; }
  .form-radio {
    margin-bottom: 0; }
  .form-checkbox {
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 3px; }
  .popup-donation .fanpage-donations-more .fanpage-donations {
    padding: 40px 20px 0; }
  .popup-donation .btn-showall,
  .popup-donation .btn-showless {
    margin: 40px auto 0; }
  .popup-donation form .col-row .form-checkbox {
    max-width: 65%;
    flex-basis: 65%; }
  .popup-donation form .col-row .text-help {
    max-width: 35%;
    flex-basis: 35%; } }

@media screen and (min-width: 1024px) {
  .page-main-support:before {
    display: none; }
  .two-column-bottom:before {
    display: block;
    background-image: url("../img/bg/d_bg_bottom.png"); } }
