.page-message {
  padding: 30px 0; }
  .page-message h2, .page-message p {
    margin-bottom: 30px; }
  .page-message form {
    display: flex;
    flex-wrap: wrap;
    max-width: 750px;
    margin: 0 auto; }
  .page-message .form-control-textarea {
    min-height: 150px; }
  .page-message .form-group.noline.form-label {
    position: static;
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
    margin-bottom: 20px; }
  .page-message .form-group-full-last {
    margin-top: 41px; }
  .page-message .btn-anim {
    max-width: 300px;
    width: 100%;
    margin: 0 auto; }
  .page-message #form-support {
    margin-top: 41px; }
    .page-message #form-support .form-group {
      display: none; }
  .page-message .page-writepost.float-toolbar .toolbar {
    position: relative;
    top: unset;
    left: unset;
    transform: none; }
  .page-message .page-writepost.float-toolbar .writty-content {
    padding-top: 50px; }
  .page-message .writty {
    min-height: unset;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-clip: padding-box;
    box-shadow: none;
    padding: 0 15px;
    margin: 0 auto 40px; }
    .page-message .writty-content {
      min-height: 300px; }
