.cookie {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #E7F8F8;
  box-shadow: rgba(0, 0, 0, 0.16) 0 2px 20px;
  padding: 10px 0;
  z-index: 2000; }
  .cookie .container {
    min-height: 75px;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .cookie-left {
    height: 100%;
    display: flex;
    align-items: center; }
    .cookie-left img {
      margin-right: 15px; }
    .cookie-left p, .cookie-left a {
      font-size: 12px;
      line-height: 14px; }
  .cookie-right {
    height: 100%; }
    .cookie-right .btn {
      max-width: 100px;
      height: 24px; }
      .cookie-right .btn span {
        font-size: 12px;
        color: #FFF;
        z-index: 1; }
