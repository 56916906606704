.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.text-underline {
  text-align: underline !important; }

* {
  box-sizing: border-box; }

.clear {
  min-height: 0; }
  .clear:before, .clear:after {
    content: "";
    display: table; }
  .clear:after {
    clear: both; }

.container {
  margin: 0 auto; }
  .container, .container-fluid {
    width: 100%; }

.fluid {
  max-width: 100%;
  flex-basis: 100%; }

.backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("../images/empty.gif");
  background-position: center center;
  background-repeat: repeat;
  background-size: auto;
  z-index: 998;
  background-color: rgba(102, 102, 102, 0.6);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
  .backdrop.sys {
    display: block; }
  .backdrop.menu-open {
    background-color: rgba(0, 0, 0, 0); }

.img-resp {
  max-width: 100%;
  height: auto; }

.img-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box; }

.img-center {
  display: table;
  margin: 0 auto; }

.text-help {
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  color: #666666;
  line-height: 21px; }

.text-additional {
  font-weight: 300;
  font-size: 18px;
  color: #666666;
  line-height: 27px; }

.text-blue {
  color: #647AA0; }

.separator {
  position: relative;
  max-width: 305px;
  flex-basis: 305px;
  margin: 0 auto 17px; }
  .separator:before {
    content: '';
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: rgba(138, 138, 138, 0.5); }
  .separator span {
    position: relative;
    display: table;
    font-size: 14px;
    color: #666666;
    background-color: #FFF;
    padding: 12px 10px;
    margin: 0 auto;
    z-index: 2; }

.disable {
  pointer-events: none; }

ul.ul {
  list-style-type: none;
  padding-left: 15px; }
  ul.ul li:before {
    content: "\2022";
    display: inline-block;
    color: #18B8C4;
    width: 15px;
    margin-left: -15px; }
  ul.ul-dark li {
    font-weight: 400; }
    ul.ul-dark li:before {
      color: #647AA0; }

ol.ol {
  counter-reset: my-awesome-counter;
  padding-left: 53px; }
  ol.ol li {
    position: relative;
    counter-increment: my-awesome-counter;
    font-weight: 400;
    margin-bottom: 20px; }
    ol.ol li:before {
      content: counter(my-awesome-counter);
      position: absolute;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      top: -6px;
      left: -53px;
      color: #647AA0;
      font-size: 20px;
      border: 2px solid #647AA0;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: padding-box; }

.rounded {
  position: relative;
  background-color: #E7F8F8;
  z-index: 1; }
  .rounded:before {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    background-image: url("../img/icons/content-before-turq.svg");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: contain; }
  .rounded-bottom:before {
    right: auto;
    left: 0;
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.apple-login-parent {
  position: relative; }
  .apple-login-parent .btn {
    margin-left: 0; }
  .apple-login-parent #appleid-signin,
  .apple-login-parent #appleid-signin-reg,
  .apple-login-parent #appleid-signin-ex,
  .apple-login-parent #appleid-signin-popup {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 100; }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive-16by9::before {
  display: block;
  content: "";
  padding-top: 56.25%; }

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }
