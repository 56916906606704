.page-fanpage {
  color: #666666;
  padding-top: 15px; }
  .page-fanpage .btn-rss {
    cursor: pointer;
    margin-left: 9px; }
  .page-fanpage .btn-showall, .page-fanpage .btn-showless {
    display: none;
    max-width: 261px;
    width: 100%;
    margin: 40px auto 0; }
    .page-fanpage .btn-showall .btn-arrow, .page-fanpage .btn-showless .btn-arrow {
      margin-left: 15px; }
    .page-fanpage .btn-showall.sys, .page-fanpage .btn-showless.sys {
      display: flex; }
  .page-fanpage .fanpage-donations {
    padding-top: 20px; }

.fanpage-header {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  background-clip: padding-box;
  overflow: hidden; }

.fanpage-avatar {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
  border: 5px solid #FFF; }

.fanpage-name, .fanpage-title {
  text-align: center; }

.fanpage-name {
  letter-spacing: 0; }

.fanpage-title {
  font-size: 18px; }

.fanpage-channelinfo {
  font-size: 20px;
  font-weight: 400;
  text-align: center; }

.fanpage-donations {
  justify-content: center; }
  .fanpage-donations.row {
    display: none; }
    .fanpage-donations.row.slick-initialized {
      display: flex; }
  .fanpage-donations .slick-list {
    width: 100%; }
  .fanpage-donations .slick-track {
    padding: 20px 0; }
    .fanpage-donations .slick-track .fanpage-box {
      padding-right: 15px;
      padding-left: 15px; }
  .fanpage-donations-main .fanpage-box:nth-child(4), .fanpage-donations-main .fanpage-box:nth-child(5), .fanpage-donations-main .fanpage-box:nth-child(6) {
    margin-top: 30px; }
  .fanpage-donations-more {
    display: none; }
    .fanpage-donations-more .fanpage-donations {
      justify-content: flex-start; }
    .fanpage-donations-more .btn-showall, .fanpage-donations-more .btn-showless {
      display: none !important; }
    .fanpage-donations-more .fanpage-box {
      margin-bottom: 40px; }
  .fanpage-donations.hys .fanpage-box {
    -moz-transition: all 600ms ease-in-out;
    -o-transition: all 600ms ease-in-out;
    -webkit-transition: all 600ms ease-in-out;
    transition: all 600ms ease-in-out;
    -ms-transform: translateY(100px);
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
    visibility: hidden; }
    .fanpage-donations.hys .fanpage-box:nth-child(1), .fanpage-donations.hys .fanpage-box:nth-child(2), .fanpage-donations.hys .fanpage-box:nth-child(3) {
      -moz-transition: none;
      -o-transition: none;
      -webkit-transition: none;
      transition: none;
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
      visibility: visible; }
  .fanpage-donations.sys .fanpage-box {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible; }
  .fanpage-donations.sys.showless .fanpage-box {
    -moz-transition: all 600ms ease-in-out;
    -o-transition: all 600ms ease-in-out;
    -webkit-transition: all 600ms ease-in-out;
    transition: all 600ms ease-in-out;
    -ms-transform: translateY(100px);
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
    visibility: hidden; }
    .fanpage-donations.sys.showless .fanpage-box:nth-child(1), .fanpage-donations.sys.showless .fanpage-box:nth-child(2), .fanpage-donations.sys.showless .fanpage-box:nth-child(3) {
      -moz-transition: none;
      -o-transition: none;
      -webkit-transition: none;
      transition: none;
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
      visibility: visible; }

.fanpage-box {
  min-width: 399.98px;
  text-align: center; }
  .fanpage-box-inner {
    height: 100%;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    background-clip: padding-box; }
    .fanpage-box-inner .btn-anim {
      max-width: 100%;
      margin: 0 auto 20px; }
  .fanpage-box-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px; }
  .fanpage-box-cover {
    display: block;
    width: 100%;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    background-clip: padding-box; }
  .fanpage-box-title {
    color: #647AA0;
    padding-top: 24px; }

.fanpage-amount {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 30px; }
  .fanpage-amount.d-flex {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    margin: 19px 0; }
    .fanpage-amount.d-flex input {
      max-width: 132px;
      height: 40px;
      -moz-appearance: textfield;
      -webkit-appearance: textfield;
      appearance: textfield;
      padding: 5px 15px;
      margin-right: 15px; }
      .fanpage-amount.d-flex input::-webkit-outer-spin-button, .fanpage-amount.d-flex input::-webkit-inner-spin-button {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none; }

.fanpage-monthly {
  font-size: 18px; }

.fanpage-texthelp {
  font-size: 14px;
  line-height: 1.4285;
  margin-bottom: 21px; }

.fanpage-description {
  position: relative;
  font-size: 18px;
  text-align: left;
  line-height: 1.4;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  transition-delay: -300ms; }
  .fanpage-description.hys {
    height: 170px;
    overflow: hidden; }
    .fanpage-description.hys:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 83px;
      -moz-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      z-index: 1; }
  .fanpage-description.sys {
    overflow: visible;
    margin-bottom: 30px; }
    .fanpage-description.sys:before {
      -ms-transform: translatey(300%);
      -webkit-transform: translatey(300%);
      -moz-transform: translatey(300%);
      -o-transform: translatey(300%);
      transform: translatey(300%);
      opacity: 0;
      visibility: hidden; }

.fanpage-donators span {
  display: block;
  font-size: 20px;
  margin-top: -2px; }

.fanpage-social {
  display: flex;
  flex-wrap: wrap;
  max-width: 460px;
  margin: 50px auto 0; }
  .fanpage-social-right a, .fanpage-social-full a {
    display: inline-block; }
    .fanpage-social-right a img, .fanpage-social-full a img {
      display: inherit; }
  .fanpage-social-bottom {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 100%;
    flex-basis: 100%; }
    .fanpage-social-bottom .btn-anim {
      width: 100%;
      margin: 0 4px; }

.fanpage-submenu {
  background-color: #FFF;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.16) 0 2px 48px;
  padding: 33px 0;
  z-index: 999; }
  .fanpage-submenu li {
    padding: 10px 15px; }
    .fanpage-submenu li:hover {
      background-color: #18B8C4; }
      .fanpage-submenu li:hover a {
        color: #FFF; }
    .fanpage-submenu li a {
      display: flex;
      align-items: center;
      font-size: 18px; }
      .fanpage-submenu li a:hover {
        text-decoration: none; }
  .fanpage-submenu-circle {
    display: inline-block;
    min-width: 25px;
    max-width: 25px;
    flex-basis: 25px;
    height: 25px;
    background-color: #FFF;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-clip: padding-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    margin-right: 8px; }
  .fanpage-submenu-facebook {
    background-image: url("../img/icons/facebook-circle-mini.svg"); }
  .fanpage-submenu-instagram {
    background-image: url("../img/icons/instagram-circle-mini.svg"); }
  .fanpage-submenu-messenger {
    background-image: url("../img/icons/messenger-circle-mini.svg"); }
  .fanpage-submenu-email {
    background-image: url("../img/icons/email-mini.svg"); }
  .fanpage-submenu-twitter {
    background-image: url("../img/icons/twitter-circle-mini.svg"); }
  .fanpage-submenu-youtube {
    background-image: url("../img/icons/youtube-circle-mini.svg"); }
  .fanpage-submenu-email {
    background-image: url("../img/icons/email-mini.svg"); }
  .fanpage-submenu:before {
    content: '';
    position: absolute;
    top: -15px;
    right: 60px;
    width: 0;
    height: 0;
    border-color: transparent transparent #FFF;
    border-style: solid;
    border-width: 0 12px 15px;
    z-index: 1; }

.fanpage-group {
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-clip: padding-box;
  background-color: #F5F4F2; }

.fanpage-control {
  background-color: transparent; }

.show-less,
.show-more {
  cursor: pointer;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  font-weight: 400;
  color: #18B8C4;
  text-align: center;
  z-index: 2; }
  .show-less:hover,
  .show-more:hover {
    color: #647AA0; }
  .show-less.hys,
  .show-more.hys {
    display: none; }
  .show-less.h4,
  .show-more.h4 {
    color: #18B8C4; }

.show-less {
  bottom: -30px; }

@media screen and (max-width: 1365px) {
  .fanpage-box {
    min-width: unset; } }

@media screen and (max-width: 767px) {
  .fanpage-box-cover {
    width: 100%; } }
