.breadcrumb li + li:before {
  content: '/';
  display: inline-block;
  font-size: 14px;
  color: #666666;
  padding-right: 8px;
  padding-left: 4px; }

.breadcrumb li {
  display: inline-block; }
  .breadcrumb li a {
    font-size: 14px;
    color: #666666; }
  .breadcrumb li.active a {
    color: #666666; }
