@charset "UTF-8";
.creators {
  padding-top: 73px; }
  .creators h2 {
    margin-bottom: 10px; }

.creator-item {
  padding: 0 15px; }
  .creator-item a:hover {
    text-decoration: none; }

.creator-slider {
  padding: 0 15px;
  display: none; }
  .creator-slider.slick-initialized {
    display: block; }
  .creator-slider .slick-list {
    width: 100%; }
  .creator-slider .slick-track {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px; }

.creator-inner {
  position: relative;
  height: 100%;
  background-color: #FFF;
  /*box-shadow: 0 2px 48px rgba(0, 0, 0, .08);*/
  box-shadow: 0 2px 19px rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  background-clip: padding-box; }

.creator-header {
  height: 136px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  background-clip: padding-box; }

.creator-profilpicture {
  position: absolute;
  top: 116px;
  left: 20px;
  width: 83px;
  height: 83px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 4px solid #E7F8F8;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  z-index: 1; }

.creator-follower {
  padding-right: 20px;
  padding-top: 4px; }
  .creator-follower p {
    font-size: 14px;
    line-height: 2;
    text-align: right; }
    .creator-follower p:first-child {
      margin-bottom: -5px; }

.creator-content {
  padding: 21px 20px 28px; }

.creator-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 19px;
  color: #666666;
  line-height: 1.2631578947;
  margin-bottom: 17px; }

.creator-desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  max-height: 84px;
  min-height: 84px;
  font-size: 18px;
  line-height: 1.5555555556; }

@media screen and (max-width: 1199px) {
  .creator-profilpicture {
    left: 10px; } }

@media screen and (max-width: 991px) {
  .creator-item {
    max-width: 100%;
    flex-basis: 100%; }
    .creator-item:not(:last-child) {
      margin-bottom: 30px; }
  .creator–inner {
    height: unset; }
  .creator-desc {
    min-height: unset;
    max-height: unset; } }

@media screen and (min-width: 575px) {
  .creator-slider {
    padding: 0 42px; } }
