.slick-arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
  background: transparent;
  border: none;
  box-shadow: none;
  text-indent: -9999px;
  z-index: 1;
  width: 45px;
  height: 45px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto; }

.slick-disabled {
  opacity: 0.5; }

.slick-prev {
  left: -26px;
  background-image: url("../img/icons/arrow-left-circle-full.svg"); }
  .slick-prev.slick-disabled {
    cursor: default;
    opacity: 1;
    background-image: url("../img/icons/arrow-left-circle-empty.svg"); }

.slick-next {
  right: -26px;
  background-image: url("../img/icons/arrow-right-circle-full.svg"); }
  .slick-next.slick-disabled {
    cursor: default;
    opacity: 1;
    background-image: url("../img/icons/arrow-right-circle-empty.svg"); }

.slick-dots {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px; }
  .slick-dots li {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin: 0 6px; }
    .slick-dots li button {
      position: relative;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 0;
      text-indent: -9999px;
      z-index: 2; }
      .slick-dots li button:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 18px;
        height: 18px;
        background-color: #18B8C4;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-clip: padding-box;
        z-index: 2; }
      .slick-dots li button:before {
        content: '';
        display: none;
        width: 28px;
        height: 28px;
        background-color: transparent;
        border: 2px solid #18B8C4;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-clip: padding-box;
        z-index: 1; }
    .slick-dots li.slick-active button:before {
      display: block; }
