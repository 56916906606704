.readpost-left, .readpost-right {
  padding: 0 15px; }

.readpost-left-inner {
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px; }

.readpost-right .btn-anim {
  max-width: 313px;
  margin: 0 auto 30px; }

.readpost-right h3 {
  font-weight: 500;
  color: #647AA0;
  margin-bottom: 15px; }

.readpost-header {
  margin-bottom: 33px; }
  .readpost-header img {
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    background-clip: padding-box; }
    .readpost-header img.img-resp {
      width: 100%;
      height: auto; }
  .readpost-header .music-player {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    -ms-transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0); }
  .readpost-header .music-cover {
    border: 2px dashed #18B8C4;
    margin-right: 0; }
    .readpost-header .music-cover img {
      display: inherit;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background-clip: padding-box; }
  .readpost-header p, .readpost-header h2 {
    padding: 0 20px; }
  .readpost-header p {
    margin-top: 27px;
    margin-bottom: 14px; }
  .readpost-header h2 {
    font-weight: 500;
    color: #666666; }
  .readpost-header .post-video-thumbnail .img-resp {
    width: 100%; }

.readpost-content {
  padding: 0 20px 20px; }

.readpost-share {
  padding: 0 20px 20px; }
  .readpost-share a:not(:last-child) {
    margin-right: 15px; }
  .readpost-share a:hover {
    text-decoration: none; }

.readpost-shadow {
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
  padding: 0 20px; }

.readpost-avatar {
  padding-top: 20px;
  padding-bottom: 13px;
  margin-bottom: 30px; }
  .readpost-avatar img {
    display: table;
    margin: 0 auto 23px; }
  .readpost-avatar p {
    font-weight: 500; }
  .readpost-avatar span {
    display: block;
    font-size: 18px; }

.readpost-donation {
  padding-top: 27px;
  padding-bottom: 20px;
  margin-bottom: 41px; }

.readpost-price {
  margin-bottom: 2px; }

.readpost-monthly {
  font-size: 18px;
  margin-bottom: 19px; }

.readpost-links {
  padding-top: 22px;
  padding-bottom: 20px; }

.readpost-link {
  margin-bottom: 18px; }
  .readpost-link a {
    display: block;
    font-weight: 500;
    color: #666666; }
  .readpost-link span {
    font-size: 18px; }
  .readpost-link:last-child {
    margin-bottom: 0; }
