html {
  overflow-x: hidden; }
  html.menu-open .navbar-nav {
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  html.menu-open main, html.menu-open .menu, html.menu-open footer, html.menu-open .navbar-extra, html.menu-open .navbar-logo-mobil {
    -ms-transform: translateX(304px);
    -webkit-transform: translateX(304px);
    -moz-transform: translateX(304px);
    -o-transform: translateX(304px);
    transform: translateX(304px); }
  html.menu-open.user-loggedin .navbar-menu {
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  html.menu-open.user-loggedin .navbar-nav {
    -ms-transform: translateX(-304px);
    -webkit-transform: translateX(-304px);
    -moz-transform: translateX(-304px);
    -o-transform: translateX(-304px);
    transform: translateX(-304px); }
  html.user-loggedin .navbar-logo-mobil {
    left: 104px; }
  html.user-loggedin .navbar-menu li.profil {
    margin-top: 0; }
  html.page-loaded .tr {
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
    html.page-loaded .tr-600 {
      -moz-transition: all 600ms ease-in-out;
      -o-transition: all 600ms ease-in-out;
      -webkit-transition: all 600ms ease-in-out;
      transition: all 600ms ease-in-out; }
