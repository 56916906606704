.select2-search__field {
  width: calc(100% - 30px) !important;
  font-size: 18px;
  color: #333333;
  border-width: 0 0 1px 0 !important;
  border-color: rgba(138, 138, 138, 0.5) !important;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
  margin: 0 auto 15px; }
  .select2-search__field::-webkit-outer-spin-button, .select2-search__field::-webkit-inner-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; }

.select2-selection--single .select2-selection__rendered {
  font-size: 18px;
  color: #000 !important;
  line-height: 21px !important; }

.select2-container--default {
  background-color: transparent !important;
  margin-bottom: 26px; }
  .select2-container--default .select2-selection--single {
    border: none; }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      padding-left: 0; }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    width: 16px;
    height: 9px;
    border: none;
    background-image: url("../img/icons/arrow-down.svg");
    background-position: no-repeat;
    background-repeat: center center;
    background-size: auto;
    margin-left: -7px;
    margin-top: -7px; }
  .select2-container--default .select2-results__option {
    background-color: transparent; }
  .select2-container--default .select2-selection__placeholder {
    font-style: italic;
    font-weight: 400;
    color: #ADADAD !important; }
  .select2-container--default .select2-results__option,
  .select2-container--default .select2-results__option--selectable {
    color: #ADADAD;
    font-size: 18px;
    font-weight: 400; }
  .select2-container--default .select2-results__option {
    padding: 11.5px 20px; }
  .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #E7F8F8;
    color: #18B8C4; }

.select2-dropdown {
  max-width: 320px;
  background-color: #FFF;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.15) 0 8px 24px;
  -ms-transform: translateY(27px);
  -webkit-transform: translateY(27px);
  -moz-transform: translateY(27px);
  -o-transform: translateY(27px);
  transform: translateY(27px);
  padding: 20px 0; }
  .select2-dropdown.input-countrycode {
    padding: 10px 5px; }
  .select2-dropdown.amount-of-donate {
    left: -10px;
    width: 90px !important;
    padding: 10px; }
    .select2-dropdown.amount-of-donate .select2-search {
      display: none; }

.select2-search--dropdown {
  padding: 0; }

.input-group-inline .select2 {
  margin-bottom: 0; }
  .input-group-inline .select2-selection--single .select2-selection__rendered {
    font-size: 24px;
    color: #666666 !important;
    line-height: 27px !important; }
  .input-group-inline .select2-selection--single .select2-selection__arrow b {
    margin-top: -4px; }

.select2-dropdown.filter-name {
  background-color: transparent;
  box-shadow: none;
  padding: 0; }
  .select2-dropdown.filter-name .select2-search--dropdown {
    margin-bottom: 27px; }
  .select2-dropdown.filter-name .select2-search__field {
    width: 100% !important;
    height: 28px;
    /*background-color: transparent;*/
    border: none;
    padding: 0;
    margin-top: -28px;
    margin-bottom: 0; }
  .select2-dropdown.filter-name .select2-results {
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.15) 0 8px 24px; }
