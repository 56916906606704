.popup {
  opacity: 0;
  visibility: hidden;
  -ms-transform: translate(-50%, -500%);
  -webkit-transform: translate(-50%, -500%);
  -moz-transform: translate(-50%, -500%);
  -o-transform: translate(-50%, -500%);
  transform: translate(-50%, -500%); }
  .popup.active {
    opacity: 1;
    visibility: visible; }
  .popup-back, .popup-close {
    cursor: pointer;
    position: absolute;
    top: 15px;
    font-size: 25px;
    font-weight: 500;
    color: #000; }
    .popup-back:hover, .popup-close:hover {
      color: #FF0000; }
  .popup-close {
    right: 15px;
    z-index: 2; }
  .popup-back {
    left: 15px;
    opacity: 0;
    visibility: hidden;
    -ms-transform: translateX(386px);
    -webkit-transform: translateX(386px);
    -moz-transform: translateX(386px);
    -o-transform: translateX(386px);
    transform: translateX(386px);
    -moz-transition: transform 300ms ease-in-out;
    -o-transition: transform 300ms ease-in-out;
    -webkit-transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    z-index: 1; }
    .popup-back.sys {
      -ms-transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
      visibility: visible; }
  .popup-dismiss {
    font-style: normal;
    margin: 0 auto; }
  .popup-inner {
    display: block;
    width: 100%;
    background-color: #FFF;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    padding: 53px 18px 30px;
    overflow-x: hidden; }
    .popup-inner .btn-anim {
      max-width: 305px;
      margin: 0 auto; }
  .popup-content {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden; }
    .popup-content .h1, .popup-content h2 {
      margin-bottom: 38px; }
    .popup-content h2 {
      font-size: 26px;
      line-height: 1.2;
      color: #647AA0; }
    .popup-content a:not(.btn) {
      color: #18B8C4; }
      .popup-content a:not(.btn).text-help {
        color: #647AA0; }
    .popup-content p, .popup-content ul {
      margin-bottom: 20px; }
    .popup-content .popup-logo {
      display: table;
      margin: 0 auto 20px; }
    .popup-content .popup-title {
      margin-bottom: 20px; }
    .popup-content .popup-text-help {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 40px; }
    .popup-content .forms {
      position: relative; }
      .popup-content .forms.show-activation #phone-reg {
        -ms-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%); }
      .popup-content .forms.show-activation #smscode {
        -ms-transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0); }
      .popup-content .forms .form-reg {
        -moz-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        -webkit-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out; }
      .popup-content .forms #smscode {
        position: absolute;
        top: 0;
        left: 0;
        -ms-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%); }
    .popup-content .input-radio {
      margin-bottom: 14px; }
      .popup-content .input-radio p {
        margin-bottom: 0; }
    .popup-content .fanpage-donations.row {
      display: flex; }
      .popup-content .fanpage-donations.row .fanpage-box {
        min-width: unset; }
  .popup-buttons {
    display: flex; }
    .popup-buttons .btn-anim {
      margin: 0 10px; }
    .popup-buttons .form-group {
      max-width: calc(50% - 10px);
      flex-basis: calc(50% - 10px);
      margin-left: 0;
      margin-right: auto; }
      .popup-buttons .form-group:last-child {
        margin-left: auto;
        margin-right: 0; }
        .popup-buttons .form-group:last-child:hover span {
          text-decoration: underline; }
        .popup-buttons .form-group:last-child span {
          color: #18B8C4; }
  .popup-prereg .btn-anim, .popup-prereg-email .btn-anim, .popup-prereg-sms .btn-anim {
    max-width: 275px;
    width: 100%;
    margin: 0 auto 30px; }
  .popup-prereg .group-container, .popup-prereg-email .group-container, .popup-prereg-sms .group-container {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 305px;
    width: 100%;
    margin: 0 auto; }
  .popup-prereg .popup-content, .popup-prereg-email .popup-content, .popup-prereg-sms .popup-content {
    position: relative;
    overflow: hidden; }
    .popup-prereg .popup-content-right, .popup-prereg .popup-content-left, .popup-prereg-email .popup-content-right, .popup-prereg-email .popup-content-left, .popup-prereg-sms .popup-content-right, .popup-prereg-sms .popup-content-left {
      -moz-transition: all 600ms ease-in-out;
      -o-transition: all 600ms ease-in-out;
      -webkit-transition: all 600ms ease-in-out;
      transition: all 600ms ease-in-out; }
    .popup-prereg .popup-content-right, .popup-prereg-email .popup-content-right, .popup-prereg-sms .popup-content-right {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      -ms-transform: translate(200%, 0);
      -webkit-transform: translate(200%, 0);
      -moz-transform: translate(200%, 0);
      -o-transform: translate(200%, 0);
      transform: translate(200%, 0); }
    .popup-prereg .popup-content.doanim .popup-content-left, .popup-prereg-email .popup-content.doanim .popup-content-left, .popup-prereg-sms .popup-content.doanim .popup-content-left {
      -ms-transform: translateX(-200%);
      -webkit-transform: translateX(-200%);
      -moz-transform: translateX(-200%);
      -o-transform: translateX(-200%);
      transform: translateX(-200%); }
    .popup-prereg .popup-content.doanim .popup-content-right, .popup-prereg-email .popup-content.doanim .popup-content-right, .popup-prereg-sms .popup-content.doanim .popup-content-right {
      -ms-transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      -moz-transform: translate(-50%, 0);
      -o-transform: translate(-50%, 0);
      transform: translate(-50%, 0); }
  .popup-prereg h2, .popup-prereg-email h2, .popup-prereg-sms h2 {
    margin-bottom: 55px; }
  .popup-prereg .buttons, .popup-prereg-email .buttons, .popup-prereg-sms .buttons {
    display: flex;
    justify-content: center;
    max-width: 300px;
    margin: 0 auto 15px; }
    .popup-prereg .buttons .btn-circle, .popup-prereg-email .buttons .btn-circle, .popup-prereg-sms .buttons .btn-circle {
      width: 66px;
      height: 66px;
      margin: 0 10px; }
      .popup-prereg .buttons .btn-circle .content, .popup-prereg-email .buttons .btn-circle .content, .popup-prereg-sms .buttons .btn-circle .content {
        height: 35px; }
  .popup-prereg .separator, .popup-prereg-email .separator, .popup-prereg-sms .separator {
    margin: 0 auto 20px; }
  .popup-prereg .resendemailcode,
  .popup-prereg .resendsmscode, .popup-prereg-email .resendemailcode,
  .popup-prereg-email .resendsmscode, .popup-prereg-sms .resendemailcode,
  .popup-prereg-sms .resendsmscode {
    display: block;
    color: #647AA0;
    font-size: 16px;
    font-style: italic;
    text-align: center;
    line-height: 18px;
    margin: 10px 0; }
    .popup-prereg .resendemailcode:hover, .popup-prereg .resendemailcode:active, .popup-prereg .resendemailcode:focus, .popup-prereg .resendemailcode.active,
    .popup-prereg .resendsmscode:hover,
    .popup-prereg .resendsmscode:active,
    .popup-prereg .resendsmscode:focus,
    .popup-prereg .resendsmscode.active, .popup-prereg-email .resendemailcode:hover, .popup-prereg-email .resendemailcode:active, .popup-prereg-email .resendemailcode:focus, .popup-prereg-email .resendemailcode.active,
    .popup-prereg-email .resendsmscode:hover,
    .popup-prereg-email .resendsmscode:active,
    .popup-prereg-email .resendsmscode:focus,
    .popup-prereg-email .resendsmscode.active, .popup-prereg-sms .resendemailcode:hover, .popup-prereg-sms .resendemailcode:active, .popup-prereg-sms .resendemailcode:focus, .popup-prereg-sms .resendemailcode.active,
    .popup-prereg-sms .resendsmscode:hover,
    .popup-prereg-sms .resendsmscode:active,
    .popup-prereg-sms .resendsmscode:focus,
    .popup-prereg-sms .resendsmscode.active {
      text-decoration: none; }
  .popup-prereg-email form {
    max-width: 305px;
    margin: 0 auto 20px; }
  .popup-prereg-email .popup-text-help {
    padding: 0 15px; }
  .popup-barion h2 {
    padding: 0 15px; }
  .popup-barion .input-group.input-center {
    margin: 0 auto 34px; }
  .popup-barion p {
    position: relative; }
  .popup-barion .icon-info {
    position: absolute;
    bottom: -11px;
    -ms-transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px); }
  .popup-barion form input::-webkit-input-placeholder,
  .popup-barion form textarea::-webkit-input-placeholder {
    font-style: normal;
    color: #ADADAD;
    opacity: 1;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .popup-barion form input::-moz-placeholder,
  .popup-barion form textarea::-moz-placeholder {
    font-style: normal;
    color: #ADADAD;
    opacity: 1;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .popup-barion form input:-ms-input-placeholder,
  .popup-barion form textarea:-ms-input-placeholder {
    font-style: normal;
    color: #ADADAD;
    opacity: 1;
    -moz-transition: opacity 300ms ease-in-out;
    -o-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out; }
  .popup-barion .input-barion {
    text-align: center;
    margin-bottom: 0; }
  .popup-pay-success .popup-inner {
    padding: 53px 0 0; }
  .popup-pay-success .popup-content {
    padding: 0 30px; }
  .popup-pay-success .rounded {
    border-top-left-radius: 60px;
    padding: 52px 30px 43px;
    margin: 0 -30px; }
    .popup-pay-success .rounded .h4 {
      font-weight: 500;
      line-height: 34px;
      margin-bottom: 25px; }
    .popup-pay-success .rounded .input-group-submit {
      margin: 0 auto; }
    .popup-pay-success .rounded:before {
      top: -60px;
      width: 60px;
      height: 60px; }
  .popup-pay-success .icons {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 43px auto 30px; }
    .popup-pay-success .icons a {
      margin: 0 5px 10px; }
      .popup-pay-success .icons a .inner {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto; }
  .popup-transaction h1 {
    margin-bottom: 38px; }
  .popup-transaction .text-group {
    display: block;
    width: calc(100% - 30px);
    margin: 0 auto 32px; }
  .popup-transaction .text-label {
    margin-bottom: 2px; }
  .popup-transaction .download-bill {
    display: flex;
    width: calc(100% - 30px);
    align-items: center;
    flex-direction: column;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
    padding: 22px 0 15px;
    margin: 0 auto 29px; }
    .popup-transaction .download-bill img {
      margin-bottom: 6px; }
    .popup-transaction .download-bill p {
      margin-bottom: 0; }
    .popup-transaction .download-bill:hover {
      text-decoration: none; }
  .popup-login form {
    max-width: 350px;
    width: calc(100% - 30px);
    margin: 0 auto; }
  .popup-login .buttons .btn {
    margin: 0 10px 14px; }
  .popup-login .forgotpassword {
    display: table;
    color: #18B8C4;
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
    margin-left: auto; }
  .popup-login .form-checkbox {
    margin: -15px 0 20px; }
  .popup-login .text-help {
    display: table;
    margin-left: 15px;
    margin-bottom: 20px; }
  .popup-login .chk-container {
    min-height: 20px;
    font-size: 14px;
    line-height: 1;
    padding-left: 30px; }
    .popup-login .chk-container .checkmark {
      top: 2px;
      width: 20px;
      height: 20px; }
  .popup-login .reg-notyet {
    position: relative;
    background-color: #647AA0;
    -webkit-border-radius: 70px 0 30px 30px;
    -moz-border-radius: 70px 0 30px 30px;
    border-radius: 70px 0 30px 30px;
    background-clip: padding-box;
    padding-top: 42px;
    padding-bottom: 30px; }
    .popup-login .reg-notyet p {
      font-size: 24px;
      color: #FFF;
      text-align: center;
      margin-bottom: 42px; }
    .popup-login .reg-notyet .btn-anim {
      background-color: #FFF;
      margin: 0 auto; }
    .popup-login .reg-notyet:before, .popup-login .reg-notyet:after {
      content: '';
      position: absolute;
      display: block;
      top: -79px;
      right: 0;
      width: 80px;
      height: 80px;
      background-image: url("../img/icons/popup-before.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto; }
  .popup-filters form, .popup-export form {
    position: relative; }
    .popup-filters form .form-group, .popup-export form .form-group {
      max-width: 100%;
      flex-basis: 100%; }
  .popup-facebookalbumok #templateholder_albumok,
  .popup-facebookalbumok #templateholder_album {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
  .popup-facebookalbumok .popup-content {
    margin-bottom: -30px; }
  .popup-facebookalbumok .row {
    margin-left: 0;
    margin-right: 0; }
    .popup-facebookalbumok .row .text-center {
      max-width: 100%;
      flex-basis: 100%; }
    .popup-facebookalbumok .row .col-sm-12 {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px; }
      .popup-facebookalbumok .row .col-sm-12 a:hover {
        text-decoration: none; }
      .popup-facebookalbumok .row .col-sm-12 p {
        margin-bottom: 0; }
      .popup-facebookalbumok .row .col-sm-12 img {
        width: 17px;
        height: auto;
        vertical-align: middle;
        margin-right: 5px; }
      .popup-facebookalbumok .row .col-sm-12.preloader img {
        width: auto;
        height: auto;
        margin: 0 auto; }
  .popup-facebookalbumok .album-item {
    position: relative;
    z-index: 10;
    margin-bottom: 30px; }
    .popup-facebookalbumok .album-item .inner {
      display: flex;
      display: -webkit-flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 18px;
      -moz-border-radius: 18px;
      border-radius: 18px;
      background-clip: padding-box;
      background-color: #FFF;
      box-shadow: rgba(138, 138, 138, 0.5) 0 2px 15px;
      padding: 15px; }
      .popup-facebookalbumok .album-item .inner:hover {
        text-decoration: none; }
    .popup-facebookalbumok .album-item img {
      margin-bottom: 15px; }
      .popup-facebookalbumok .album-item img.fb-pic {
        -webkit-border-radius: 18px;
        -moz-border-radius: 18px;
        border-radius: 18px;
        background-clip: padding-box;
        margin-bottom: 0; }
    .popup-facebookalbumok .album-item p {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0; }
    .popup-facebookalbumok .album-item span {
      font-size: 13px;
      line-height: 1; }
  .popup-pictureupload .popup-content h2 {
    padding: 0 10px; }
  .popup-pictureupload .popup-content .hide-on-load {
    display: none; }
    .popup-pictureupload .popup-content .hide-on-load.sys {
      display: block; }
    .popup-pictureupload .popup-content .hide-on-load.popup-buttons.sys {
      display: flex; }
  .popup-pictureupload .popup-content .buttons .btn {
    height: 45px;
    margin: 0 auto 20px; }
    .popup-pictureupload .popup-content .buttons .btn span.text {
      font-size: 16px; }
  .popup-pictureupload .popup-buttons {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto; }
    .popup-pictureupload .popup-buttons .btn {
      max-width: 50px;
      height: 50px;
      margin: 0; }
      .popup-pictureupload .popup-buttons .btn.megsem, .popup-pictureupload .popup-buttons .btn.delete-picture {
        margin-left: auto; }
      .popup-pictureupload .popup-buttons .btn.btn-marginright {
        margin-right: 10px; }
      .popup-pictureupload .popup-buttons .btn.btn-anim:last-child {
        margin-left: 10px; }
  .popup-crm .popup-inner {
    padding-left: 30px;
    padding-right: 30px; }
  .popup-donation .popup-inner {
    padding: 53px 0 30px; }
  .popup-donation .buttons .btn {
    margin: 0 10px 14px; }
    .popup-donation .buttons .btn-anim.btn-all, .popup-donation .buttons .btn-anim.btn-showless {
      margin-top: 40px; }
  .popup-donation .fanpage-donations {
    justify-content: center;
    padding: 0 28px; }
  .popup-donation .fanpage-amount, .popup-donation .fanpage-monthly, .popup-donation .fanpage-texthelp {
    color: #666666; }
  .popup-donation .fanpage-box-inner .btn-anim {
    max-width: 231px;
    margin: 0 auto 25px; }
  .popup-donation .btn-showall,
  .popup-donation .btn-showless {
    display: none;
    max-width: 261px; }
    .popup-donation .btn-showall .btn-arrow,
    .popup-donation .btn-showless .btn-arrow {
      margin-left: 15px; }
    .popup-donation .btn-showall.sys,
    .popup-donation .btn-showless.sys {
      display: flex; }
  .popup-donation form {
    padding: 0 14px;
    margin-top: 40px; }
    .popup-donation form, .popup-donation form .col-row {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row; }
    .popup-donation form .btn-turquoise, .popup-donation form .col-row {
      max-width: 318px; }
    .popup-donation form .col-row {
      align-content: center;
      padding: 0;
      margin: 25px auto 0; }
      .popup-donation form .col-row .form-checkbox .chk-container {
        font-size: 16px;
        margin-bottom: 0; }
      .popup-donation form .col-row .text-help {
        color: #647AA0;
        line-height: 30px; }
  .popup-billingaddress form {
    margin-top: 40px; }
    .popup-billingaddress form p {
      text-align: left; }
    .popup-billingaddress form .form-group-organization {
      margin-bottom: 20px; }
      .popup-billingaddress form .form-group-organization.noline {
        margin-top: 0; }
  .popup-billingaddress p {
    font-size: 16px;
    text-align: center; }

@media screen and (max-width: 575px) {
  .popup-content .fanpage-description {
    height: auto !important; }
  .popup-pictureupload .popup-content h2 {
    padding: 0;
    margin-bottom: 20px; } }
