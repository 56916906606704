.profil {
  position: relative;
  background-color: #E7F8F8;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  border-radius: 26px;
  background-clip: padding-box;
  margin-top: 39px;
  margin-right: -15px;
  margin-left: -15px;
  z-index: 2; }
  .profil .edit, .profil .delete {
    cursor: pointer;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
  .profil .edit {
    background-image: url("../img/icons/pen-red.svg"); }
    .profil .edit.edited {
      background-image: url("../img/icons/pen-blue.svg"); }
  .profil .delete {
    cursor: pointer;
    background-image: url("../img/icons/delete-red.svg"); }
  .profil-inner {
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box;
    background-color: #FFF; }
  .profil-header {
    position: relative;
    padding: 10px; }
  .profil-cover, .profil-picture {
    cursor: pointer; }
  .profil-cover {
    position: relative;
    width: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box;
    border: 1px dashed #18B8C4;
    overflow: hidden; }
    .profil-cover .add-cover, .profil-cover .add-cover0, .profil-cover .add-cover1 {
      cursor: pointer;
      position: absolute;
      top: 22px;
      right: 22px;
      z-index: 1; }
    .profil-cover.edited {
      border-style: solid; }
  .profil-picture {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #FFF;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-clip: padding-box;
    border: 1px dashed #18B8C4; }
    .profil-picture .add-picture {
      cursor: pointer;
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 45px;
      height: 45px;
      z-index: 1; }
      .profil-picture .add-picture a {
        display: inherit; }
    .profil-picture.edited {
      border-style: solid; }
  .profil-name {
    position: relative;
    font-weight: 500;
    color: #666666;
    line-height: 26px; }
    .profil-name .edit {
      top: 0;
      right: 15px; }
  .profil-title {
    color: #666666; }
  .profil-text {
    font-size: 18px;
    color: #666666;
    line-height: 27px; }
  .profil-bottom-buttons {
    display: flex;
    justify-content: center; }
  .profil-additional-settings {
    display: none;
    max-width: 500px;
    padding: 30px 15px 0;
    margin: 0 auto; }
    .profil-additional-settings .form-checkbox {
      margin-bottom: 10px; }
  .profil .blocktemplate {
    padding-top: 30px; }
    .profil .blocktemplate .delete-donation {
      top: auto;
      bottom: 15px; }
    .profil .blocktemplate.custom-blocktemplate {
      display: none; }
  .profil .new-blocktemplate .delete-donation {
    top: auto;
    bottom: 18px;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
