.page-dashboard .box-container {
  background-color: transparent; }

.page-dashboard .dashboard-slider {
  display: none; }
  .page-dashboard .dashboard-slider.slick-initialized {
    display: block; }

.page-dashboard .slick-headline {
  font-size: 44px;
  color: #647AA0;
  text-align: center; }

.page-dashboard .slick-title {
  font-weight: 500;
  text-transform: uppercase;
  text-align: center; }
  .page-dashboard .slick-title-big {
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: #f00;
    margin-bottom: 30px; }

.page-dashboard .slick-right .btn-anim {
  margin-bottom: 20px; }
