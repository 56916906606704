.writty {
  position: relative;
  display: table;
  max-width: 1170px;
  min-height: 503px;
  width: 100%;
  margin: 0 auto 50px; }
  .writty-popup, .writty .toolbar-button {
    width: 20px;
    height: 20px;
    text-align: center;
    margin: 0 5px; }
  .writty .toolbar {
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    min-height: 44px;
    background-color: #FFF;
    border: 1px solid #ADADAD;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box;
    margin: 0 auto;
    z-index: 100; }
    .writty .toolbar-button {
      cursor: pointer;
      font-size: 16px;
      padding: 0; }
      .writty .toolbar-button img {
        display: table;
        margin: 0 auto; }
      .writty .toolbar-button:hover, .writty .toolbar-button.active {
        color: #18B8C4; }
      .writty .toolbar-button#format-p {
        display: none; }
  .writty-popup {
    display: inline-block;
    position: relative; }
    .writty-popup .toolbar-button {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      margin: 0; }
    .writty-popup-button {
      cursor: pointer;
      display: flex;
      color: #000;
      font-size: 16px;
      text-align: left; }
    .writty-popup-window {
      display: none;
      position: absolute;
      background-color: #FFF;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background-clip: padding-box;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      padding: 3px 15px 10px 15px;
      z-index: 1; }
    .writty-popup-item {
      cursor: pointer;
      width: 100%;
      color: #000;
      background-color: #FFF;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background-clip: padding-box;
      padding: 6px 3px 6px 3px; }
      .writty-popup-item:hover, .writty-popup-item.active {
        color: #18B8C4; }
    .writty-popup:hover .writty-popup-window, .writty-popup.active .writty-popup-window {
      display: block;
      color: #18B8C4; }
  .writty-content {
    position: relative;
    display: inline-block;
    width: 100%;
    min-height: 98px;
    background-color: #FFF;
    color: #000;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-clip: padding-box;
    font-size: 18px;
    line-height: 1.5555;
    overflow-wrap: break-word;
    padding-top: 50px; }
  .writty-editor {
    position: relative;
    display: block;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid #8A8A8A;
    padding: 12px 15px;
    margin: auto; }
    .writty-editor:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 3px;
      background-color: #18B8C4;
      -moz-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      z-index: 2; }
    .writty-editor.active:before, .writty-editor.has-value:before {
      width: 100%; }
  .writty-emoji {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden; }
  .writty-icon {
    display: block;
    width: 20px;
    height: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto; }
    .writty-icon-bold {
      background-image: url("../img/icons/writty/bold.svg"); }
      .writty-icon-bold:hover {
        background-image: url("../img/icons/writty/bold-act.svg"); }
    .writty-icon-italic {
      background-image: url("../img/icons/writty/italic.svg"); }
      .writty-icon-italic:hover {
        background-image: url("../img/icons/writty/italic-act.svg"); }
    .writty-icon-ul {
      background-image: url("../img/icons/writty/ul.svg"); }
      .writty-icon-ul:hover {
        background-image: url("../img/icons/writty/ul-act.svg"); }
    .writty-icon-underline {
      background-image: url("../img/icons/writty/underline.svg"); }
      .writty-icon-underline:hover {
        background-image: url("../img/icons/writty/underline-act.svg"); }
    .writty-icon.active.writty-icon-bold {
      background-image: url("../img/icons/writty/bold-act.svg"); }
    .writty-icon.active.writty-icon-italic {
      background-image: url("../img/icons/writty/italic-act.svg"); }
    .writty-icon.active.writty-icon-ul {
      background-image: url("../img/icons/writty/ul-act.svg"); }
    .writty-icon.active.writty-icon-underline {
      background-image: url("../img/icons/writty/underline-act.svg"); }
