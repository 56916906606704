.icon {
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto; }
  .icon-menu {
    min-width: 26px;
    width: 26px;
    height: 26px;
    background-image: url("../img/icons/sprite-menu.svg"); }
  .icon-donation {
    background-position: -10px -8px; }
  .icon-income {
    background-position: -43px -8px; }
  .icon-profil {
    background-position: -75px -8px; }
  .icon-settings {
    background-position: -109px -8px; }
  .icon-finance {
    background-position: -144px -7px; }
  .icon-logout {
    background-position: -180px -7px; }
  .icon-dashboard {
    background-position: -252px -8px; }
  .icon-help {
    background-position: -216px -7px; }
  .icon-howworks {
    background-position: -288px -8px; }
  .icon-prices {
    background-position: -324px -8px; }
  .icon-start {
    background-position: -360px -8px; }
  .icon-contact {
    background-position: -394px -7px; }
  .icon-message {
    background-position: -427px -7px; }
  .icon-post {
    background-position: -463px -8px; }
  .icon-button {
    background-position: -499px -8px; }
  .icon-menu-reverse.icon-donation {
    background-position: -10px -45px; }
  .icon-menu-reverse.icon-income {
    background-position: -43px -45px; }
  .icon-menu-reverse.icon-profil {
    background-position: -75px -45px; }
  .icon-menu-reverse.icon-settings {
    background-position: -109px -45px; }
  .icon-menu-reverse.icon-finance {
    background-position: -144px -44px; }
  .icon-menu-reverse.icon-logout {
    background-position: -180px -44px; }
  .icon-menu-reverse.icon-help {
    background-position: -216px -45px; }
  .icon-menu-reverse.icon-dashboard {
    background-position: -252px -45px; }
  .icon-menu-reverse.icon-message {
    background-position: -427px -45px; }
  .icon-menu-reverse.icon-post {
    background-position: -463px -45px; }
  .icon-menu-reverse.icon-start {
    background-position: -360px -45px; }
  .icon-menu-reverse.icon-button {
    background-position: -498px -45px; }
  .icon-social {
    width: 16px;
    height: 13px;
    background-image: url("../img/icons/sprite-social.svg"); }
  .icon-facebook {
    background-position: -5px -10px; }
  .icon-instagram {
    background-position: -25px -9px; }
  .icon-youtube {
    background-position: -48px -7px; }
  .icon-twitter {
    background-position: -70px -8px; }
