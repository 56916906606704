.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; }
  .navbar .container {
    height: 100%; }
  .navbar-logo {
    position: absolute;
    top: 14px;
    left: 0;
    height: 53px;
    z-index: 1001; }
  .navbar-logo-mobil {
    position: absolute;
    top: 10px;
    left: 64px;
    z-index: 1001; }
    .navbar-logo-mobil img {
      display: block;
      width: 44px;
      height: auto; }
  .navbar-nav {
    display: flex;
    align-items: center;
    height: 100%; }
    .navbar-nav li {
      position: relative;
      display: inline-block;
      white-space: nowrap;
      margin: 0 10px; }
      .navbar-nav li a {
        white-space: nowrap; }
      .navbar-nav li .btn-navlogin {
        min-width: 130px; }
        .navbar-nav li .btn-navlogin, .navbar-nav li .btn-navlogin .btn-bg {
          height: 35px; }
        .navbar-nav li .btn-navlogin span {
          position: relative;
          font-size: 16px;
          color: #FFF;
          letter-spacing: 0;
          text-align: center; }
      .navbar-nav li .btn-red {
        min-width: 160px;
        height: 35px; }
        .navbar-nav li .btn-red span {
          font-size: 16px; }
      .navbar-nav li.li-profil {
        display: none; }
        .navbar-nav li.li-profil a {
          display: flex;
          align-items: center; }
          .navbar-nav li.li-profil a:hover {
            color: #666666;
            text-decoration: none; }
          .navbar-nav li.li-profil a .pic,
          .navbar-nav li.li-profil a .monogram {
            margin-right: 6px; }
          .navbar-nav li.li-profil a .monogram {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            color: #18B8C4;
            border: 1px solid #18B8C4;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            background-clip: padding-box; }
      .navbar-nav li.li-lang {
        margin-left: 0; }
        .navbar-nav li.li-lang img {
          margin-left: 5px; }
      .navbar-nav li.li-login-mobil {
        margin-top: 33px; }
      .navbar-nav li .submenu-profil a, .navbar-nav li .submenu-profil .navbar-clickable,
      .navbar-nav li .submenu-general a,
      .navbar-nav li .submenu-general .navbar-clickable {
        font-size: 18px;
        color: #18B8C4;
        padding: 10px 35px; }
        .navbar-nav li .submenu-profil a:hover, .navbar-nav li .submenu-profil .navbar-clickable:hover,
        .navbar-nav li .submenu-general a:hover,
        .navbar-nav li .submenu-general .navbar-clickable:hover {
          color: #FFF;
          background-color: #18B8C4; }
  .navbar-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 304px;
    min-height: 100vh;
    z-index: 1000;
    background-color: #18B8C4;
    -ms-transform: translateX(-304px);
    -webkit-transform: translateX(-304px);
    -moz-transform: translateX(-304px);
    -o-transform: translateX(-304px);
    transform: translateX(-304px);
    max-height: 100vh;
    overflow: auto; }
    .navbar-menu li {
      height: 48px;
      padding: 0 38px;
      margin-bottom: 8px; }
      .navbar-menu li:hover, .navbar-menu li.active {
        background-color: #FFF; }
        .navbar-menu li:hover a, .navbar-menu li.active a {
          color: #18B8C4;
          text-decoration: none; }
      .navbar-menu li.profil {
        height: auto;
        text-align: center;
        background-color: #FFF;
        -webkit-border-radius: 0 0 60px 0;
        -moz-border-radius: 0 0 60px 0;
        border-radius: 0 0 60px 0;
        background-clip: padding-box;
        padding-top: 30px;
        padding-bottom: 17px;
        margin-left: 0;
        margin-bottom: 13px;
        margin-right: 0; }
        .navbar-menu li.profil p.h5 {
          font-weight: 500; }
        .navbar-menu li.profil .monogram {
          display: flex;
          display: -webkit-flex;
          align-items: center;
          justify-content: center;
          width: 125px;
          height: 125px;
          color: #18B8C4;
          border: 1px solid #18B8C4;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          background-clip: padding-box;
          margin: 0 auto 20px; }
        .navbar-menu li.profil img {
          margin-bottom: 17px; }
      .navbar-menu li.divider {
        height: 20px;
        margin-top: 16px; }
        .navbar-menu li.divider:hover {
          background-color: transparent; }
        .navbar-menu li.divider span {
          display: block;
          width: 100%;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.3); }
      .navbar-menu li a {
        display: flex;
        align-items: center;
        height: 100%;
        font-weight: 300;
        color: #FFF;
        line-height: 26px;
        padding: 0; }
        .navbar-menu li a i {
          font-size: 26px;
          margin-right: 20px; }
    .navbar-menu.notloggedin li:nth-child(2):before {
      display: none; }
    .navbar-menu .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .navbar-menu .mCSB_scrollTools:hover .mCSB_dragger .mCSB_dragger_bar,
    .navbar-menu .mCSB_scrollTools.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
      background-color: #FFF; }
  .navbar-extra {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    margin-left: auto;
    margin-right: 0; }
    .navbar-extra li a:hover {
      text-decoration: none; }
    .navbar-extra li .submenu a {
      font-size: 18px;
      color: #18B8C4;
      padding: 10px 35px; }
      .navbar-extra li .submenu a:hover {
        color: #FFF;
        background-color: #18B8C4; }
      .navbar-extra li .submenu a img {
        margin-left: 5px; }
    .navbar-extra li .submenu-lang {
      right: 170px; }
      .navbar-extra li .submenu-lang:before {
        right: 25px; }
    .navbar-extra li .submenu-country {
      right: 195px; }
    .navbar-extra li .btn-anim {
      min-width: 133px;
      height: 40px; }
      .navbar-extra li .btn-anim span {
        font-size: 14px; }

.menu {
  cursor: pointer;
  display: table;
  position: fixed;
  top: 19px;
  left: 15px;
  width: 30px;
  height: 22px;
  background-image: url("../img/icons/mobilmenu.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1001; }
  .menu-user {
    display: none;
    background-image: none;
    align-items: center; }
    .menu-user .monogram {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      min-width: 34px;
      height: 34px;
      font-size: 14px;
      color: #18B8C4;
      border: 1px solid #18B8C4;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: padding-box;
      margin-right: 6px; }
    .menu-user img.pic {
      margin-right: 6px;
      margin-left: 0; }

.submenu {
  position: absolute;
  display: none;
  top: 37px;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0 2px 48px;
  z-index: 999; }
  .submenu.sys {
    display: flex; }
  .submenu-login:before, .submenu-profil:before, .submenu-general:before {
    content: '';
    position: absolute;
    top: -15px;
    right: 60px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 15px 12px;
    border-color: transparent transparent #FFF transparent;
    z-index: 1; }
  .submenu-login {
    flex-wrap: wrap;
    width: 610px;
    top: 58px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box; }
    .submenu-login:before {
      right: 30px; }
    .submenu-login .left, .submenu-login .right {
      max-width: 50%;
      flex-basis: 50%;
      box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px; }
      .submenu-login .left a, .submenu-login .right a {
        display: flex; }
      .submenu-login .left .btn-anim, .submenu-login .right .btn-anim {
        max-width: 275px;
        height: 50px; }
    .submenu-login .left {
      background-color: #647AA0;
      -webkit-border-radius: 26px 0 0 26px;
      -moz-border-radius: 26px 0 0 26px;
      border-radius: 26px 0 0 26px;
      background-clip: padding-box;
      padding: 19px 15px 39px; }
      .submenu-login .left h2 {
        font-size: 24px;
        font-weight: 400;
        color: #FFF;
        margin-bottom: 11px; }
      .submenu-login .left img {
        display: table;
        margin: 0 auto 58px; }
    .submenu-login .right {
      background-color: #FFF;
      -webkit-border-radius: 0 26px 26px 0;
      -moz-border-radius: 0 26px 26px 0;
      border-radius: 0 26px 26px 0;
      background-clip: padding-box;
      padding-top: 31px; }
      .submenu-login .right .buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 15px;
        margin: 0 auto; }
        .submenu-login .right .buttons .btn {
          width: 66px;
          height: 66px;
          padding: 0; }
        .submenu-login .right .buttons .apple-login-parent {
          position: relative;
          width: 66px; }
          .submenu-login .right .buttons .apple-login-parent .btn {
            margin-left: 0; }
        .submenu-login .right .buttons .separator {
          margin-top: 14px;
          margin-bottom: 28px; }
      .submenu-login .right .btn-turquoise {
        margin-top: -2px; }
      .submenu-login .right form .text-help {
        cursor: pointer;
        display: table;
        color: #647AA0;
        text-align: center;
        padding: 7px 10px;
        margin: 0 auto; }
      .submenu-login .right form .show-popup[data-target="forgotpassword"] {
        font-size: 14px; }
      .submenu-login .right form .btn-turquoise-mini {
        display: flex; }
      .submenu-login .right form .form-checkbox {
        margin-top: -10px;
        margin-bottom: 30px; }
        .submenu-login .right form .form-checkbox .chk-container {
          font-size: 14px;
          line-height: 1;
          min-height: unset;
          padding-left: 29px; }
          .submenu-login .right form .form-checkbox .chk-container .checkmark {
            width: 20px;
            height: 20px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            background-clip: padding-box; }
            .submenu-login .right form .form-checkbox .chk-container .checkmark:after {
              width: 20px;
              height: 20px;
              background-size: 13px 9px; }
  .submenu-profil {
    top: 64px;
    right: -4px;
    flex-direction: column;
    width: 304px;
    background-color: #FFF;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box;
    box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
    padding: 29px 0 15px; }
    .submenu-profil a, .submenu-profil .navbar-clickable {
      margin-bottom: 10px; }
      .submenu-profil a i, .submenu-profil .navbar-clickable i {
        font-size: 26px;
        margin-right: 20px; }
      .submenu-profil a .icon, .submenu-profil .navbar-clickable .icon {
        margin-right: 20px; }
  .submenu-general {
    top: 64px;
    right: -26px;
    flex-direction: column;
    width: 304px;
    background-color: #FFF;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-clip: padding-box;
    padding: 29px 0 15px; }
    .submenu-general a {
      margin-bottom: 10px; }
    .submenu-general:before {
      right: 37px; }
  .submenu-lang {
    top: 64px;
    width: 190px; }
    .submenu-lang:before {
      right: 25px; }
  .submenu-country {
    width: 267px; }
    .submenu-country img {
      margin-right: 10px; }
    .submenu-country.sys {
      display: flex; }

@media screen and (max-height: 720px) and (max-width: 1440px) {
  .submenu-profil {
    max-height: calc(100vh - 100px);
    overflow: auto; } }

@media screen and (max-width: 991px) {
  .submenu-profil {
    max-height: unset;
    overflow: visible; } }
