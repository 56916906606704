.two-column p.h4 {
  font-weight: 400; }

.two-column-image {
  align-self: center; }
  .two-column-image img {
    display: table;
    max-width: 485px;
    width: 100%;
    margin: 0 auto; }

.two-column .bg {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: -1; }

.two-column-bottom:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 950px;
  bottom: 0;
  left: 0;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1; }

.page-main-compare {
  position: relative; }

.page-main-change {
  position: relative; }

.page-main-foryou {
  position: relative; }

/*Chart anim*/
.chart_anim_cont {
  max-width: 393px;
  max-height: 393px;
  width: 100%;
  height: 100%;
  margin: 0 auto; }

#chart_anim {
  width: 100%;
  margin: 0 auto;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden; }

.chart1 {
  display: block;
  position: absolute;
  width: 50%;
  top: 0px;
  left: 0px;
  z-index: 1;
  overflow: hidden;
  background: url("../img/chart/chart.svg") no-repeat left top;
  background-size: 200% auto; }

.chart1 img {
  width: 200%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%; }

.chart2 {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 0px;
  z-index: 3;
  overflow: hidden;
  opacity: 0;
  background: url("../img/chart/chart.svg") no-repeat left bottom;
  background-size: 200% 200%; }

.chart2 img {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%; }

.chart_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 1;
  z-index: 2;
  transform: rotate(20deg); }

.chart_mask_bg {
  position: absolute;
  width: 50%;
  height: 100%;
  background: url("../img/chart/chart_bg.svg") no-repeat left bottom;
  background-size: 100% 100%;
  top: 0px;
  left: -2px;
  opacity: 1; }

.chart_v1 {
  position: absolute;
  top: 62%;
  left: 53%;
  width: 46px;
  height: 32px;
  transform: scale(0, 0);
  background: url("../img/chart/v1.svg") no-repeat left center;
  background-size: 100% auto; }
  .chart_v1.dona_59 {
    background: url("../img/chart/v1_59.svg") no-repeat left center; }

.chart_v2 {
  position: absolute;
  top: 76%;
  left: 53%;
  width: 46px;
  height: 32px;
  transform: scale(0, 0);
  background: url("../img/chart/v2.svg") no-repeat left center;
  background-size: 100% auto; }
  .chart_v2.dona_67 {
    background: url("../img/chart/v2_67.svg") no-repeat left center; }

.chart_v3 {
  position: absolute;
  top: 90%;
  left: 53%;
  width: 46px;
  height: 32px;
  transform: scale(0, 0);
  background: url("../img/chart/v3.svg") no-repeat left center;
  background-size: 100% auto; }
  .chart_v3.dona_90 {
    background: url("../img/chart/v3_90.svg") no-repeat left center;
    background-size: contain; }

.s1 .chart_mask {
  -webkit-transition: all 3s linear;
  transition: all 3s linear;
  transform: rotate(360deg); }

.s1 .chart1 {
  -webkit-animation: chart1 1s forwards;
  -webkit-animation-delay: .6s;
  -moz-animation: chart1 1s forwards;
  -moz-animation-delay: .6s;
  -ms-animation: chart1 1s forwards;
  -ms-animation-delay: .6s;
  animation: chart1 1s forwards;
  animation-delay: .6s; }

.s1 .chart1 img {
  -webkit-animation: chart1img 1s forwards;
  -webkit-animation-delay: .6s;
  -moz-animation: chart1img 1s forwards;
  -moz-animation-delay: .6s;
  -ms-animation: chart1img 1s forwards;
  -ms-animation-delay: .6s;
  animation: chart1img 1s forwards;
  animation-delay: .6s; }

.s1 .chart2 {
  -webkit-animation: chart2 1s forwards;
  -webkit-animation-delay: .6s;
  -moz-animation: chart2 1s forwards;
  -moz-animation-delay: .6s;
  -ms-animation: chart2 1s forwards;
  -ms-animation-delay: .6s;
  animation: chart2 1s forwards;
  animation-delay: .6s; }

.s1 .chart_v1 {
  -webkit-transition: all .8s;
  transition: all .8s;
  transform: scale(1, 1);
  -webkit-transition-delay: 2.5s;
  transition-delay: 2.5s;
  z-index: 5; }

.s1 .chart_v2 {
  -webkit-transition: all .8s;
  transition: all .8s;
  transform: scale(1, 1);
  -webkit-transition-delay: 2.7s;
  transition-delay: 2.7s;
  z-index: 5; }

.s1 .chart_v3 {
  -webkit-transition: all .8s;
  transition: all .8s;
  transform: scale(1, 1);
  -webkit-transition-delay: 2.9s;
  transition-delay: 2.9s;
  z-index: 5; }

@keyframes chart1 {
  0% {
    width: 50%;
    height: 100%;
    background-size: 200% auto; }
  39% {
    width: 50%;
    height: 100%;
    background-size: 200% auto; }
  40% {
    width: 100%;
    height: 50%;
    background-size: 100% auto; }
  99% {
    width: 100%;
    height: 50%;
    background-size: 100% auto; }
  100% {
    width: 100%;
    height: 100%;
    background-size: 100% auto; } }

@-moz-keyframes chart1 {
  0% {
    width: 50%;
    height: 100%;
    background-size: 200% auto; }
  39% {
    width: 50%;
    height: 100%;
    background-size: 200% auto; }
  40% {
    width: 100%;
    height: 50%;
    background-size: 100% auto; }
  99% {
    width: 100%;
    height: 50%;
    background-size: 100% auto; }
  100% {
    width: 100%;
    height: 100%;
    background-size: 100% auto; } }

@-webkit-keyframes chart1 {
  0% {
    width: 50%;
    height: 100%;
    background-size: 200% auto; }
  39% {
    width: 50%;
    height: 100%;
    background-size: 200% auto; }
  40% {
    width: 100%;
    height: 50%;
    background-size: 100% auto; }
  99% {
    width: 100%;
    height: 50%;
    background-size: 100% auto; }
  100% {
    width: 100%;
    height: 100%;
    background-size: 100% auto; } }

@-ms-keyframes chart1 {
  0% {
    width: 50%;
    height: 100%;
    background-size: 200% auto; }
  39% {
    width: 50%;
    height: 100%;
    background-size: 200% auto; }
  40% {
    width: 100%;
    height: 50%;
    background-size: 100% auto; }
  99% {
    width: 100%;
    height: 50%;
    background-size: 100% auto; }
  100% {
    width: 100%;
    height: 100%;
    background-size: 100% auto; } }

@keyframes chart2 {
  0% {
    opacity: 1; }
  99% {
    opacity: 1;
    width: 50%;
    height: 50%;
    top: 50%;
    background-size: 200% 200%; }
  100% {
    opacity: 1;
    width: 50%;
    height: 100%;
    top: 0;
    background-size: 200% 100%; } }

@-moz-keyframes chart2 {
  0% {
    opacity: 1; }
  99% {
    opacity: 1;
    width: 50%;
    height: 50%;
    top: 50%;
    background-size: 200% 200%; }
  100% {
    opacity: 1;
    width: 50%;
    height: 100%;
    top: 0;
    background-size: 200% 100%; } }

@-webkit-keyframes chart2 {
  0% {
    opacity: 1; }
  99% {
    opacity: 1;
    width: 50%;
    height: 50%;
    top: 50%;
    background-size: 200% 200%; }
  100% {
    opacity: 1;
    width: 50%;
    height: 100%;
    top: 0;
    background-size: 200% 100%; } }

@-ms-keyframes chart2 {
  0% {
    opacity: 1; }
  99% {
    opacity: 1;
    width: 50%;
    height: 50%;
    top: 50%;
    background-size: 200% 200%; }
  100% {
    opacity: 1;
    width: 50%;
    height: 100%;
    top: 0;
    background-size: 200% 100%; } }
