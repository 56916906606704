.steps .row {
  position: relative;
  max-width: 1000px;
  margin: 0 auto; }

.steps-title {
  display: flex; }
  .steps-title h3, .steps-title .h3 {
    color: #647AA0; }

.steps-content-flex {
  display: flex; }

.steps-content .h6 {
  font-weight: 500; }

.steps-tooltip {
  background-color: #FFF;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-clip: padding-box;
  padding: 10px 13px; }
  .steps-tooltip p {
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    line-height: 18px; }

.steps-clock {
  position: relative;
  width: 100px;
  height: 100px; }
  .steps-clock-min {
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 20px;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    text-align: center; }

.steps-arrow {
  position: absolute;
  z-index: 2; }

.steps-01 {
  padding-top: 29px; }
  .steps-01 .steps-title {
    margin-bottom: 31px; }
    .steps-01 .steps-title img {
      margin-right: 15px; }
  .steps-01 .steps-arrow {
    right: 60px;
    bottom: -146px; }

.steps-02 .steps-title {
  margin-top: -6px;
  margin-bottom: 32px; }
  .steps-02 .steps-title img {
    margin-right: 10px; }

.steps-02 .steps-content {
  margin-bottom: 29px; }
  .steps-02 .steps-content-bottom {
    margin-bottom: 0; }
    .steps-02 .steps-content-bottom p {
      font-size: 20px;
      font-weight: 600; }

.steps-02 .steps-tooltip {
  margin-bottom: 37px; }

.steps-02 .steps-arrow {
  bottom: -148px;
  left: calc(50% - 11px);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }

.steps-02 .steps-right-02 .steps-graph-03 {
  display: table;
  margin: 0 auto; }

.steps-03 .steps-left {
  padding-top: 17px; }

.steps-03 .steps-title {
  margin-bottom: 34px; }
  .steps-03 .steps-title img {
    margin-right: 11px; }

.steps-03 .steps-content p {
  margin-bottom: 28px; }
  .steps-03 .steps-content p:last-child {
    margin-bottom: 0; }

.steps-04 .steps-title {
  margin-bottom: 28px; }
  .steps-04 .steps-title img {
    margin-right: 8px; }

.steps-04 .steps-content p {
  margin-bottom: 28px; }
  .steps-04 .steps-content p:last-child {
    margin-bottom: 0; }

.steps-04 .steps-arrow-03 {
  left: 18px;
  top: -40px; }

.steps-05 {
  padding-top: 60px;
  padding-bottom: 71px; }
  .steps-05 p {
    font-weight: 500;
    margin-bottom: 38px; }
  .steps-05 .btn-anim {
    margin: 0 auto; }

.steps .rounded {
  -webkit-border-radius: 80px 0 80px 80px;
  -moz-border-radius: 80px 0 80px 80px;
  border-radius: 80px 0 80px 80px;
  background-clip: padding-box; }
  .steps .rounded:before {
    top: -79px;
    width: 80px;
    height: 80px; }

@media screen and (max-width: 991px) {
  .steps .h2 {
    font-size: 26px;
    line-height: 1.2; }
  .steps .steps-clock {
    width: 70px;
    height: 70px;
    margin-right: auto;
    margin-left: auto; }
    .steps .steps-clock img {
      max-width: 100%;
      height: auto; }
    .steps .steps-clock .steps-clock-min {
      font-size: 13px;
      bottom: 10px; }
  .steps .steps-title img {
    width: 70px;
    height: auto; }
  .steps-01 {
    padding-bottom: 30px; }
    .steps-01 .steps-arrow {
      width: 50px;
      height: auto;
      bottom: -76px; }
    .steps-01 .steps-clock {
      margin-bottom: 20px; }
    .steps-01 .steps-graph-01 {
      display: table;
      width: 200px;
      height: auto;
      margin-right: auto;
      margin-left: auto; }
  .steps-02 .steps-graph-02 {
    display: table;
    width: 250px;
    height: auto;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto; }
  .steps-02 .steps-title {
    margin-top: 30px; }
  .steps-02 .steps-clock {
    margin-top: 20px; }
  .steps-02 .steps-content-bottom p {
    font-size: 18px; }
  .steps-02 .steps-right-02 .steps-graph-03 {
    width: 300px;
    height: auto;
    margin-bottom: 30px; }
  .steps-02 .steps-arrow {
    width: 100px;
    height: auto;
    bottom: -58px;
    left: calc(50% - -16px); }
  .steps-03 {
    padding-top: 30px; }
    .steps-03 .steps-clock {
      margin-bottom: 30px; }
    .steps-03 .step-graph-04 {
      display: table;
      width: 180px;
      margin-left: auto;
      margin-right: auto; }
  .steps-04 .row {
    padding-top: 30px;
    padding-bottom: 30px; }
  .steps-04 .steps-left {
    display: flex;
    flex-direction: column; }
    .steps-04 .steps-left .img-resp {
      order: 2;
      display: table;
      width: 250px;
      margin-right: auto;
      margin-left: auto; }
    .steps-04 .steps-left .steps-clock {
      margin-bottom: 30px; }
  .steps-04 .steps-arrow-03 {
    width: 40px;
    height: auto;
    left: 258px;
    top: -50px; } }

@media screen and (max-width: 768px) {
  .steps .steps-arrow {
    display: none; }
  .steps .col-sm-6 {
    max-width: 100%;
    flex-basis: 100%; }
  .steps-01 .steps-left {
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 30px; }
  .steps-02 .steps-left-01 {
    order: 2;
    display: flex;
    flex-direction: column; }
    .steps-02 .steps-left-01 .steps-graph-02 {
      order: 3; }
  .steps-02 .steps-right-01 {
    order: 1;
    padding-left: 15px;
    padding-right: 15px; }
  .steps-02 .steps-left-02 {
    order: 3;
    padding: 20px 15px; }
  .steps-02 .steps-right-02 {
    order: 4; }
  .steps-02 .steps-clock {
    margin-top: 0; }
  .steps-03 .steps-right {
    margin-top: 30px; }
  .steps-03 .step-graph-04 {
    margin-bottom: -30px; }
  .steps-04 .steps-left {
    order: 2;
    margin-top: 30px;
    padding-right: 15px;
    padding-left: 15px; }
  .steps-04 .steps-right {
    order: 1;
    padding-right: 15px;
    padding-left: 15px; } }
