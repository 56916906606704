.row {
  box-sizing: border-box;
  margin-left: -15px;
  margin-right: -15px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap; }
  .row-content {
    margin-left: auto;
    margin-right: auto; }
  .row-grid {
    flex-grow: 1; }

.col {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0 15px; }

.col-xs {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0 15px; }

.col-xs-1 {
  box-sizing: border-box;
  flex-basis: 8.33333%;
  max-width: 8.33333%;
  padding: 0 15px; }

.col-xs-offset-1 {
  margin-left: 8.33333%; }

.col-xs-2 {
  box-sizing: border-box;
  flex-basis: 16.66667%;
  max-width: 16.66667%;
  padding: 0 15px; }

.col-xs-offset-2 {
  margin-left: 16.66667%; }

.col-xs-3 {
  box-sizing: border-box;
  flex-basis: 25%;
  max-width: 25%;
  padding: 0 15px; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-4 {
  box-sizing: border-box;
  flex-basis: 33.33333%;
  max-width: 33.33333%;
  padding: 0 15px; }

.col-xs-offset-4 {
  margin-left: 33.33333%; }

.col-xs-5 {
  box-sizing: border-box;
  flex-basis: 41.66667%;
  max-width: 41.66667%;
  padding: 0 15px; }

.col-xs-offset-5 {
  margin-left: 41.66667%; }

.col-xs-6 {
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: 50%;
  padding: 0 15px; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-7 {
  box-sizing: border-box;
  flex-basis: 58.33333%;
  max-width: 58.33333%;
  padding: 0 15px; }

.col-xs-offset-7 {
  margin-left: 58.33333%; }

.col-xs-8 {
  box-sizing: border-box;
  flex-basis: 66.66667%;
  max-width: 66.66667%;
  padding: 0 15px; }

.col-xs-offset-8 {
  margin-left: 66.66667%; }

.col-xs-9 {
  box-sizing: border-box;
  flex-basis: 75%;
  max-width: 75%;
  padding: 0 15px; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-10 {
  box-sizing: border-box;
  flex-basis: 83.33333%;
  max-width: 83.33333%;
  padding: 0 15px; }

.col-xs-offset-10 {
  margin-left: 83.33333%; }

.col-xs-11 {
  box-sizing: border-box;
  flex-basis: 91.66667%;
  max-width: 91.66667%;
  padding: 0 15px; }

.col-xs-offset-11 {
  margin-left: 91.66667%; }

.col-xs-12 {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 15px; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media only screen and (min-width: 575px) {
  .col-sm {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0 15px; }
  .col-sm-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0 15px; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-2 {
    box-sizing: border-box;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
    padding: 0 15px; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0 15px; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-4 {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-5 {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0 15px; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 15px; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-7 {
    box-sizing: border-box;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    padding: 0 15px; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-8 {
    box-sizing: border-box;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    padding: 0 15px; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0 15px; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-10 {
    box-sizing: border-box;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
    padding: 0 15px; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-11 {
    box-sizing: border-box;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
    padding: 0 15px; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 15px; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media only screen and (min-width: 992px) {
  .col-md {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0 15px; }
  .col-md-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0 15px; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-2 {
    box-sizing: border-box;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
    padding: 0 15px; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0 15px; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-4 {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-5 {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0 15px; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 15px; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-7 {
    box-sizing: border-box;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    padding: 0 15px; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-8 {
    box-sizing: border-box;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    padding: 0 15px; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0 15px; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-10 {
    box-sizing: border-box;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
    padding: 0 15px; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-11 {
    box-sizing: border-box;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
    padding: 0 15px; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 15px; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media only screen and (min-width: 1200px) {
  .col-lg {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0 15px; }
  .col-lg-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0 15px; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-2 {
    box-sizing: border-box;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
    padding: 0 15px; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0 15px; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-4 {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-5 {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0 15px; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 15px; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-7 {
    box-sizing: border-box;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    padding: 0 15px; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-8 {
    box-sizing: border-box;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    padding: 0 15px; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0 15px; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-10 {
    box-sizing: border-box;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
    padding: 0 15px; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-11 {
    box-sizing: border-box;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
    padding: 0 15px; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 15px; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

@media only screen and (min-width: 1921px) {
  .col-xl {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0 15px; }
  .col-xl-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0 15px; }
  .col-xl-offset-1 {
    margin-left: 8.33333%; }
  .col-xl-2 {
    box-sizing: border-box;
    flex-basis: 16.66667%;
    max-width: 16.66667%;
    padding: 0 15px; }
  .col-xl-offset-2 {
    margin-left: 16.66667%; }
  .col-xl-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0 15px; }
  .col-xl-offset-3 {
    margin-left: 25%; }
  .col-xl-4 {
    box-sizing: border-box;
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px; }
  .col-xl-offset-4 {
    margin-left: 33.33333%; }
  .col-xl-5 {
    box-sizing: border-box;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
    padding: 0 15px; }
  .col-xl-offset-5 {
    margin-left: 41.66667%; }
  .col-xl-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 15px; }
  .col-xl-offset-6 {
    margin-left: 50%; }
  .col-xl-7 {
    box-sizing: border-box;
    flex-basis: 58.33333%;
    max-width: 58.33333%;
    padding: 0 15px; }
  .col-xl-offset-7 {
    margin-left: 58.33333%; }
  .col-xl-8 {
    box-sizing: border-box;
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    padding: 0 15px; }
  .col-xl-offset-8 {
    margin-left: 66.66667%; }
  .col-xl-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0 15px; }
  .col-xl-offset-9 {
    margin-left: 75%; }
  .col-xl-10 {
    box-sizing: border-box;
    flex-basis: 83.33333%;
    max-width: 83.33333%;
    padding: 0 15px; }
  .col-xl-offset-10 {
    margin-left: 83.33333%; }
  .col-xl-11 {
    box-sizing: border-box;
    flex-basis: 91.66667%;
    max-width: 91.66667%;
    padding: 0 15px; }
  .col-xl-offset-11 {
    margin-left: 91.66667%; }
  .col-xl-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 15px; }
  .col-xl-offset-12 {
    margin-left: 100%; } }
