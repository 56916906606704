.services-item a {
  display: block;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px; }
  .services-item a:hover {
    text-decoration: none; }

.services-item h2, .services-item p {
  color: #666666; }

.services-item img {
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background-clip: padding-box; }

.services-picture {
  overflow: hidden;
  position: relative; }
  .services-picture:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 62.5%; }
  .services-picture img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }
