* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  *:focus, *:hover, *:active, *:active:focus {
    outline: none; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, button, pre, a, abbr, acronym, address, big, cite, code, input,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form,
label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header,
hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  border: 0; }

main {
  transition: all 300ms ease-in-out; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  min-width: 320px;
  font-size: 16px;
  line-height: 1;
  vertical-align: baseline;
  overflow-x: hidden; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

strong {
  font-weight: 500; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  color: #18B8C4;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

button {
  background-color: transparent;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  border: 1px solid #FFF;
  border-bottom: 1px solid #8A8A8A;
  -webkit-text-fill-color: #666;
  box-shadow: 0 0 0 1000px white inset;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  transition: background-color 5000s; }
