.datepicker table tr td {
  cursor: pointer;
  position: relative; }
  .datepicker table tr td span.active.active, .datepicker table tr td span.active.active:hover, .datepicker table tr td span.active.active:hover:hover, .datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active:hover:hover, .datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today:hover:hover {
    background-image: none;
    background-color: #FFF;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-clip: padding-box; }
    .datepicker table tr td span.active.active:after, .datepicker table tr td span.active.active:hover:after, .datepicker table tr td span.active.active:hover:hover:after, .datepicker table tr td.active:after, .datepicker table tr td.active:hover:after, .datepicker table tr td.active:hover:hover:after, .datepicker table tr td.today:after, .datepicker table tr td.today:hover:after, .datepicker table tr td.today:hover:hover:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 10px 10px;
      border-color: transparent transparent #647AA0 transparent; }
  .datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today:hover:hover {
    background-color: #FFF; }
    .datepicker table tr td.today:after, .datepicker table tr td.today:hover:after, .datepicker table tr td.today:hover:hover:after {
      border-color: transparent transparent #647AA0 transparent; }
  .datepicker table tr td.day:hover {
    background-color: transparent; }
    .datepicker table tr td.day:hover:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 10px 10px;
      border-color: transparent transparent #2CB9B0 transparent; }
  .datepicker table tr td span.focused {
    position: relative;
    color: #FFF;
    background-color: #2CB9B0; }
    .datepicker table tr td span.focused:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 10px 10px;
      border-color: transparent transparent #647AA0 transparent; }
  .datepicker table tr td span.focused, .datepicker table tr td span.focused:hover, .datepicker table tr td span.focused:hover:hover, .datepicker table tr td.active.active, .datepicker table tr td.active.active:hover, .datepicker table tr td.active.active:hover:hover {
    color: #000;
    background-color: #FFF; }
    .datepicker table tr td span.focused:after, .datepicker table tr td span.focused:hover:after, .datepicker table tr td span.focused:hover:hover:after, .datepicker table tr td.active.active:after, .datepicker table tr td.active.active:hover:after, .datepicker table tr td.active.active:hover:hover:after {
      border-color: transparent transparent #2CB9B0 transparent; }

.datepicker table thead tr:last-child th {
  color: #647AA0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box; }

.datepicker td, .datepicker th {
  width: 14.285714285714286%;
  height: 40px;
  font-weight: 100;
  font-size: 13px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box; }

.datepicker-dropdown {
  position: absolute;
  background-color: #FFF;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.15) 0 8px 24px;
  padding: 20px;
  margin-top: 16px; }
  .datepicker-dropdown .table-condensed {
    width: 100%; }
  .datepicker-dropdown:before, .datepicker-dropdown:after {
    display: none; }
