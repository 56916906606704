.rounded-box-bottom:after, .rounded-box-top:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 150px;
  background-color: #E7F8F8;
  z-index: -1; }

.rounded-box-bottom {
  position: relative;
  background-color: #FFF;
  border-radius: 0 0 80px 0;
  padding: 0 35px 12px; }

.rounded-box-top {
  position: relative;
  background-color: #E7F8F8;
  border-radius: 80px 0 0 0;
  padding: 25px 35px 35px; }
  .rounded-box-top:after {
    bottom: -150px; }

.rounded-box-small {
  display: flex;
  align-items: center;
  height: 55px;
  background-color: #FFF;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background-clip: padding-box;
  padding: 0 15px;
  margin-bottom: 20px; }
  .rounded-box-small p {
    max-width: 155px;
    flex-basis: 155px; }
  .rounded-box-small .stat-negative {
    color: #FF0000; }
  .rounded-box-small .stat-positive {
    color: #18B8C4; }
  .rounded-box-small .icon {
    margin-left: auto; }
