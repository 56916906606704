.page-tariff {
  margin-bottom: -30px; }
  .page-tariff .tariff-item {
    margin-bottom: 30px; }
    .page-tariff .tariff-item .inner {
      box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
      -webkit-border-radius: 18px;
      -moz-border-radius: 18px;
      border-radius: 18px;
      background-clip: padding-box;
      overflow: hidden;
      padding-top: 31px; }
  .page-tariff .tariff-logo {
    width: 60px;
    height: 60px;
    background-image: url("../img/icons/sprite-tariff.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
    margin: 0 auto 20px; }
  .page-tariff .tariff-title {
    font-weight: 500;
    margin-bottom: 16px; }
  .page-tariff .tariff-commission {
    font-size: 24px;
    color: #FFF;
    padding: 8px 0;
    margin-bottom: 28px; }
  .page-tariff .tariff-list-title {
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    margin-bottom: 26px; }
  .page-tariff .tariff-list li {
    position: relative;
    font-size: 18px;
    color: #666666;
    padding: 11px 11px 11px 50px; }
    .page-tariff .tariff-list li:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 10px;
      width: 26px;
      height: 24px;
      background-image: url("../img/icons/sprite-check-mini.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto; }
  .page-tariff .tariff-list-disable li {
    color: #ADADAD; }
  .page-tariff .tariff-text {
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    margin-top: 26px;
    margin-bottom: 22px; }
  .page-tariff .tariff-creator .tariff-logo {
    background-position: -10px -10px; }
  .page-tariff .tariff-creator .tariff-title {
    color: #647AA0; }
  .page-tariff .tariff-creator .tariff-commission {
    background-color: #18B8C4; }
  .page-tariff .tariff-creator .tariff-list li:nth-child(odd) {
    background-color: #E7F8F8; }
  .page-tariff .tariff-creator .tariff-list li:before {
    background-position: -10px -10px; }
  .page-tariff .tariff-nonprofit .tariff {
    /*&-title {
					color: $blue;
				}

				&-commission {
					background-color: $red;
				}

				&-list {
					li {
						&:nth-child(odd) {
							background-color: rgba(255, 0, 0, .5);

						}

						&:before {
							background-position: -46px -10px;
						}
					}
				}*/ }
    .page-tariff .tariff-nonprofit .tariff-logo {
      background-position: -80px -10px; }
  .page-tariff .tariff-organization .tariff {
    /*&-title {
					color: $blue;
				}

				&-commission {
					background-color: $yellow;
				}

				&-list {
					li {
						&:nth-child(odd) {
							background-color: rgba(251, 206, 74, .5);

						}

						&:before {
							background-position: -82px -10px;
						}
					}
				}*/ }
    .page-tariff .tariff-organization .tariff-logo {
      background-position: -150px -10px; }
