.page-help h2 {
  color: #FFF;
  padding: 0 15px;
  margin-bottom: 15px; }

.page-help .help-search {
  max-width: 100%;
  flex-basis: 100%; }
  .page-help .help-search:before {
    background-color: #FFF; }
  .page-help .help-search:before, .page-help .help-search:after {
    display: none !important; }
  .page-help .help-search .form-label, .page-help .help-search .form-control {
    color: #FFF; }
  .page-help .help-search .form-label {
    top: 0;
    left: 15px;
    font-size: 16px;
    font-weight: 100; }
  .page-help .help-search .form-control {
    border-color: rgba(255, 255, 255, 0.5);
    padding-left: 102px;
    padding-right: 15px; }
  .page-help .help-search .form-error {
    display: none;
    color: #FFE2E2;
    background-color: transparent; }
  .page-help .help-search.active .form-label {
    top: 0;
    left: 15px;
    font-size: 16px;
    color: #FFF; }
  .page-help .help-search.has-error:before {
    background-color: #FF0000; }
  .page-help .help-search.has-error .form-control {
    color: #FFF; }
  .page-help .help-search.has-error .form-label {
    opacity: 1;
    visibility: visible; }

.page-help .help-box {
  font-size: 18px;
  color: #2CB9B0;
  text-align: center;
  background-color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.16) 0 2px 48px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  margin-bottom: 30px; }
  .page-help .help-box a {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666666;
    height: 100%; }
    .page-help .help-box a:hover {
      color: #18B8C4;
      text-decoration: none; }
  .page-help .help-box-ico {
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    max-height: 45px;
    text-align: center;
    margin-bottom: 15px;
    overflow: hidden; }
  .page-help .help-box-text {
    padding: 0 15px; }
  .page-help .help-box-container {
    background-color: #B9EAED;
    padding-top: 50px;
    padding-bottom: 20px; }

.page-help .gyik-header {
  background: #18B8C4;
  background: -moz-linear-gradient(left, #18B8C4 0%, #647AA0 100%);
  background: -webkit-linear-gradient(left, #18B8C4 0%, #647AA0 100%);
  background: linear-gradient(to right, #18B8C4 0%, #647AA0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#18b8c4', endColorstr='#647aa0', GradientType=1);
  padding-top: 43px;
  padding-bottom: 9px; }

.page-help .gyik-content .gyik-title {
  font-weight: 600;
  color: #647AA0;
  text-align: center; }

.page-help .gyik-content .gyik-item-title {
  cursor: pointer;
  font-size: 20px;
  color: #666666;
  border-bottom: solid 1px #e2e2e2;
  position: relative; }
  .page-help .gyik-content .gyik-item-title-ico {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
    width: 26px;
    height: 26px;
    border: solid 1px #B9EAED;
    border-radius: 50%;
    background: #F8FFFF; }
    .page-help .gyik-content .gyik-item-title-ico:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 12px;
      width: 16px;
      height: 1px;
      background: #18B8C4; }
    .page-help .gyik-content .gyik-item-title-ico:before {
      content: '';
      position: absolute;
      left: 4px;
      top: 12px;
      width: 16px;
      height: 1px;
      background: #18B8C4;
      transform: rotate(90deg);
      -moz-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out; }
  .page-help .gyik-content .gyik-item-title.active {
    color: #18B8C4;
    border-bottom: solid 2px #18B8C4; }
    .page-help .gyik-content .gyik-item-title.active .gyik-item-title-ico:before {
      transform: rotate(0deg); }

.page-help .gyik-content .gyik-item-txt {
  display: none;
  background: #E7F8F8;
  border-radius: 0 0 28px 28px;
  padding: 0 40px;
  margin-top: -1px; }
  .page-help .gyik-content .gyik-item-txt p {
    margin-bottom: 28px; }
    .page-help .gyik-content .gyik-item-txt p:last-child {
      margin-bottom: 0; }
  .page-help .gyik-content .gyik-item-txt.active {
    max-height: 800px; }

.page-help .contact-form {
  padding: 30px 0 0 0; }
  .page-help .contact-form .form-100 .form-group {
    max-width: 100%;
    flex-basis: 100%; }
  .page-help .contact-form-support .form-submit-bg ~ div {
    margin-bottom: 0 !important; }

.page-help.hide-icons .help-box {
  height: 74px; }
  .page-help.hide-icons .help-box-ico {
    max-height: 0;
    margin-bottom: 0; }
  .page-help.hide-icons .help-box-text {
    min-height: unset; }
