.post-item {
  display: block;
  float: left;
  padding: 0 15px;
  margin-bottom: 33px; }
  .post-item.sys {
    z-index: 1; }
  .post-item:not(.post-item-disable) {
    cursor: pointer; }
    .post-item:not(.post-item-disable):hover .post-title {
      color: #647AA0; }

.post-inner {
  position: relative;
  background-color: #FFF;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0 2px 48px;
  overflow: hidden; }
  .post-inner:hover .post-actions {
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }

.post-preload {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  height: 150px; }

.post-header {
  position: relative;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background-clip: padding-box;
  overflow: hidden;
  margin-bottom: 20px; }
  .post-header a:not(.btn-anim) {
    display: block; }
  .post-header-empty {
    height: 320px;
    background-color: #666666; }
  .post-header.post-lock .post-video-play {
    display: none; }
  .post-header.post-lock .music-player {
    display: none; }
  .post-header.post-lock .btn-login {
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    color: #FFF;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    margin-top: 20px; }
  .post-header-image {
    display: inherit;
    width: 100%; }
  .post-header .music-player {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    -ms-transform: translate(0);
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
    margin-top: 20px; }
  .post-header .music-cover {
    border: 2px dashed #18B8C4;
    margin-right: 0; }
    .post-header .music-cover img {
      display: inherit; }

.post-locked {
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%; }
  .post-locked img {
    display: table;
    margin-left: auto;
    margin-right: auto; }
  .post-locked p {
    color: #FFF;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.6) 0 4px 8px; }
  .post-locked .btn {
    max-width: 245px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 4px 8px;
    margin: 0 auto; }

.post-video-thumbnail {
  cursor: pointer;
  position: relative;
  width: 100%; }
  .post-video-thumbnail img {
    display: inherit;
    width: 100%; }
  .post-video-thumbnail iframe {
    display: inherit;
    width: 100%; }
  .post-video-thumbnail.sys-full .img-resp {
    width: 100%; }

.post-video-iframe {
  display: none;
  position: relative;
  max-width: 500px;
  margin: 30px auto 0; }
  .post-video-iframe iframe {
    display: table;
    max-width: 500px;
    width: 100%;
    height: calc(500px / 1.77777771); }

.post-video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1; }

.post-content {
  padding: 0 20px 16px; }

.post-date {
  display: block;
  margin-bottom: 14px; }

.post-title {
  font-weight: 600;
  color: #666666;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  margin-bottom: 33px; }
  .post-title:hover {
    text-decoration: none; }

.post-text {
  max-height: 138px;
  overflow: hidden;
  margin-bottom: 28px; }
  .post-text.hys {
    height: 300px;
    overflow: hidden; }
    .post-text.hys:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 83px;
      -moz-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      background-image: url("../img/bg/fanpage-gradient.svg");
      background-position: left top;
      background-repeat: repeat;
      background-size: auto;
      z-index: 1; }
  .post-text.sys {
    overflow: visible; }
    .post-text.sys:before {
      display: none; }

.post-reactions {
  display: flex;
  margin-bottom: 12px; }

.post-comment, .post-like {
  font-size: 16px;
  color: #ADADAD; }

.post-comment {
  margin-right: 15px; }

.post-share {
  display: flex; }
  .post-share a {
    margin-right: 15px; }
    .post-share a:last-child {
      margin-right: 0;
      margin-left: auto; }

.post-icon {
  cursor: pointer;
  height: 34px; }
  .post-icon img {
    display: inherit; }

.post-text,
.writty-content,
.page-blog-page-content,
.readpost-content {
  position: relative; }
  .post-text h2, .post-text h3, .post-text h4, .post-text h5, .post-text h6, .post-text p, .post-text a, .post-text span, .post-text div, .post-text table, .post-text ul, .post-text ol, .post-text blockquote,
  .writty-content h2,
  .writty-content h3,
  .writty-content h4,
  .writty-content h5,
  .writty-content h6,
  .writty-content p,
  .writty-content a,
  .writty-content span,
  .writty-content div,
  .writty-content table,
  .writty-content ul,
  .writty-content ol,
  .writty-content blockquote,
  .page-blog-page-content h2,
  .page-blog-page-content h3,
  .page-blog-page-content h4,
  .page-blog-page-content h5,
  .page-blog-page-content h6,
  .page-blog-page-content p,
  .page-blog-page-content a,
  .page-blog-page-content span,
  .page-blog-page-content div,
  .page-blog-page-content table,
  .page-blog-page-content ul,
  .page-blog-page-content ol,
  .page-blog-page-content blockquote,
  .readpost-content h2,
  .readpost-content h3,
  .readpost-content h4,
  .readpost-content h5,
  .readpost-content h6,
  .readpost-content p,
  .readpost-content a,
  .readpost-content span,
  .readpost-content div,
  .readpost-content table,
  .readpost-content ul,
  .readpost-content ol,
  .readpost-content blockquote {
    margin-bottom: 20px; }
  .post-text div,
  .writty-content div,
  .page-blog-page-content div,
  .readpost-content div {
    font-size: 20px;
    color: #666666; }
  .post-text img,
  .writty-content img,
  .page-blog-page-content img,
  .readpost-content img {
    display: table;
    max-width: 100%;
    height: auto;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    background-clip: padding-box;
    margin: 0 auto; }
  .post-text blockquote,
  .writty-content blockquote,
  .page-blog-page-content blockquote,
  .readpost-content blockquote {
    position: relative;
    display: block;
    width: calc(100% - 60px);
    font-size: 20px;
    font-weight: 300;
    color: #666666;
    line-height: 1.4;
    border-left: 4px solid #18B8C4;
    padding-left: 25px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1; }
    .post-text blockquote:before,
    .writty-content blockquote:before,
    .page-blog-page-content blockquote:before,
    .readpost-content blockquote:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 7px;
      width: 38px;
      height: 25px;
      background-image: url("../img/icons/blockquote.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: auto;
      z-index: -1; }
  .post-text * a,
  .writty-content * a,
  .page-blog-page-content * a,
  .readpost-content * a {
    font-size: inherit;
    margin-bottom: 0; }
  .post-text ul, .post-text ol,
  .writty-content ul,
  .writty-content ol,
  .page-blog-page-content ul,
  .page-blog-page-content ol,
  .readpost-content ul,
  .readpost-content ol {
    list-style-position: outside;
    padding-left: 30px; }
    .post-text ul li, .post-text ol li,
    .writty-content ul li,
    .writty-content ol li,
    .page-blog-page-content ul li,
    .page-blog-page-content ol li,
    .readpost-content ul li,
    .readpost-content ol li {
      display: list-item;
      color: #666666; }
  .post-text ul,
  .writty-content ul,
  .page-blog-page-content ul,
  .readpost-content ul {
    list-style-type: disc; }
  .post-text ol,
  .writty-content ol,
  .page-blog-page-content ol,
  .readpost-content ol {
    list-style-type: decimal; }
