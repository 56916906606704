.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 305px;
  width: 100%;
  height: 45px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  border-radius: 26px;
  background-clip: padding-box;
  padding: 5px 12px; }
  .btn, .btn:active, .btn:hover, .btn:focus {
    text-decoration: none;
    box-shadow: none;
    outline: none; }
  .btn-black:active, .btn-black:hover, .btn-black:focus {
    color: #FFF;
    background-color: #000; }
  .btn-default {
    border-color: #18B8C4; }
  .btn-anim {
    position: relative;
    height: 66px;
    overflow: hidden; }
    .btn-anim span.text {
      position: relative;
      display: block;
      width: 100%;
      font-weight: 500;
      font-size: 20px;
      color: #FFF;
      text-align: center;
      line-height: 1;
      pointer-events: none;
      z-index: 2; }
    .btn-anim span.bg {
      --size: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: var(--size);
      height: var(--size);
      transform: translate(-50%, -50%);
      transition: width .2s ease, height .2s ease;
      z-index: 1; }
    .btn-anim:hover span.bg {
      --size: 400px; }
  .btn-red, .btn-turquoise, .btn-barionblue {
    border: none;
    border-radius: 80px; }
    .btn-red-mini, .btn-turquoise-mini, .btn-barionblue-mini {
      max-width: 254px;
      height: 50px; }
    .btn-red-extramini, .btn-turquoise-extramini, .btn-barionblue-extramini {
      max-width: 165px;
      height: 35px; }
      .btn-red-extramini span.text, .btn-turquoise-extramini span.text, .btn-barionblue-extramini span.text {
        font-size: 18px; }
  .btn-barionblue-mini span.text {
    font-size: 16px; }
  .btn-red {
    background-color: #FD3D5E; }
    .btn-red span.bg {
      background: radial-gradient(circle closest-side, #eb022a, transparent); }
  .btn-turquoise {
    background-color: #18B8C4; }
    .btn-turquoise span.bg {
      background: radial-gradient(circle closest-side, #647AA0, transparent); }
  .btn-barionblue {
    background-color: #0097db; }
    .btn-barionblue span.bg {
      background: radial-gradient(circle closest-side, #0074a8, transparent); }
    .btn-barionblue span.text {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center; }
      .btn-barionblue span.text img {
        margin: 0 5px; }
  .btn-border {
    position: relative;
    max-width: 254px;
    height: 52px;
    font-size: 18px;
    background-color: #FFF;
    border-width: 2px; }
    .btn-border-red .btn-icon, .btn-border-turquoise .btn-icon {
      margin-right: 16px; }
    .btn-border-red span.text, .btn-border-turquoise span.text {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px; }
    .btn-border-ghost {
      border-color: transparent; }
      .btn-border-ghost span.bg {
        background: transparent !important; }
      .btn-border-ghost span.text {
        color: #2CB9B0; }
    .btn-border-turquoise {
      border-color: #18B8C4; }
      .btn-border-turquoise span.bg {
        background: radial-gradient(circle closest-side, #B9EAED, transparent) !important; }
      .btn-border-turquoise span.text {
        color: #18B8C4; }
    .btn-border-red {
      border-color: #FD3D5E; }
      .btn-border-red span.bg {
        background: radial-gradient(circle closest-side, #F68598, transparent) !important; }
      .btn-border-red span.text {
        color: #FD3D5E; }
    .btn-border-gray {
      border-color: rgba(138, 138, 138, 0.8); }
      .btn-border-gray span.bg {
        background: radial-gradient(circle closest-side, #ECEDF0, transparent) !important; }
      .btn-border-gray span.text {
        color: rgba(138, 138, 138, 0.8); }
    .btn-border-mini {
      height: 37px; }
  .btn-circle {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    border-color: #647AA0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-clip: padding-box; }
    .btn-circle span.bg {
      background: radial-gradient(circle closest-side, #d0daec, transparent) !important; }
    .btn-circle span.text {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center; }
  .btn-big {
    height: 66px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background-clip: padding-box; }
  .btn-center {
    margin-left: auto;
    margin-right: auto; }
  .btn-upload {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 100; }

.upload-button {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  background-clip: padding-box;
  overflow: hidden; }
