.croppie-buttons,
.croppie-container .cr-slider-wrap {
  height: 35px;
  background-color: rgba(102, 102, 102, 0.8);
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0;
  z-index: 100; }

.croppie {
  position: relative; }
  .croppie-buttons {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-right: auto;
    margin-left: auto; }
    .croppie-buttons .rotate {
      cursor: pointer;
      display: inherit;
      padding: 0; }

.croppie-container .cr-slider {
  margin: 0 10px; }
  .croppie-container .cr-slider-wrap {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    bottom: 30px; }
    .croppie-container .cr-slider-wrap:before, .croppie-container .cr-slider-wrap:after {
      content: '';
      display: inline-block;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
    .croppie-container .cr-slider-wrap:before {
      width: 15px;
      height: 15px;
      background-image: url("../img/icons/white/image.svg"); }
    .croppie-container .cr-slider-wrap:after {
      width: 20px;
      height: 20px;
      background-image: url("../img/icons/white/image.svg"); }

.picture-container {
  max-width: 300px;
  margin: 0 auto 30px; }

input[type=range] {
  -webkit-appearance: none;
  width: 205px;
  background: transparent; }

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none; }

input[type=range]:focus {
  outline: none; }

input[type=range]::-ms-track {
  width: 205px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent; }

/* thumb */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  height: 26px;
  width: 26px;
  background: #18B8C4;
  border: 1px solid #FFF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  margin-top: -11.5px;
  box-shadow: none; }

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  height: 26px;
  width: 26px;
  background: #18B8C4;
  border: 1px solid #FFF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  margin-top: -11.5px;
  box-shadow: none; }

input[type=range]::-ms-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  height: 26px;
  width: 26px;
  background: #18B8C4;
  border: 1px solid #FFF;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  margin-top: -11.5px;
  box-shadow: none; }

/* Track */
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: none;
  background: #FFF;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-clip: padding-box;
  border: none;
  -moz-transition: background 150ms ease-in-out;
  -o-transition: background 150ms ease-in-out;
  -webkit-transition: background 150ms ease-in-out;
  transition: background 150ms ease-in-out; }

input[type=range]:active::-webkit-slider-runnable-track {
  background: #18B8C4; }

input[type=range]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: none;
  background: #FFF;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-clip: padding-box;
  border: none; }

input[type=range]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: none;
  background: #FFF;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-clip: padding-box;
  border: none; }

input[type=range]::-ms-fill-lower {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: none;
  background: #FFF;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-clip: padding-box;
  border: none; }

input[type=range]:active::-ms-fill-lower {
  background: #18B8C4; }

input[type=range]::-ms-fill-upper {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: none;
  background: #FFF;
  border: none; }

input[type=range]:focus::-ms-fill-upper {
  background: #18B8C4; }
