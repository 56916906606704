@media screen and (min-width: 768px) {
  .container, .headline .h1 {
    max-width: 720px; }
  .popup-buttons {
    flex-direction: row; }
    .popup-buttons .btn-anim:first-child {
      margin-right: 5px; }
    .popup-buttons .btn-anim:last-child {
      margin-left: 5px; }
  .page-main-compare {
    padding-top: 50px; }
  .page-main-change {
    padding-top: 50px; }
  .page-main-foryou {
    padding-top: 50px;
    padding-bottom: 50px; }
  .page-editprofile {
    padding: 50px 0; }
  .page-404 {
    padding-top: 50px;
    padding-bottom: 50px; }
    .page-404-text .container {
      padding: 0 15px; }
    .page-404-text .rounded {
      padding-top: 50px;
      padding-bottom: 50px; }
      .page-404-text .rounded .hero {
        margin-bottom: 50px; }
  .two-column-text h2 {
    margin-bottom: 14px; }
  .two-column-text p, .two-column-text ul, .two-column-text ol {
    margin-bottom: 20px; }
  .two-column-text.col-md-6 {
    padding-left: 15px; }
  .two-column-text .btn-anim {
    display: none; }
  .two-column-services-01 .two-column-text h2 {
    margin-bottom: 33px; }
  .fanpage-posts {
    padding-bottom: 50px; }
  .fanpage-avatar {
    width: 137px;
    height: 137px;
    margin: -70px auto 31px; }
  .page-compare-twocolumn {
    padding-top: 40px;
    padding-bottom: 50px; }
  .page-compare-v2 {
    padding-top: 40px; }
  .d-tab-item {
    font-size: 16px; }
  .page-content {
    padding-bottom: 50px; }
  .page-blog-list {
    padding-bottom: 50px; }
  .page-readpost {
    padding-bottom: 50px; }
  .recommender {
    padding-bottom: 50px; }
  .page-howtochange-useit {
    padding-top: 40px;
    padding-bottom: 50px; }
  .page-suspended {
    padding: 50px 0; }
  .content-header {
    height: 350px; }
  .content-area table thead tr th, .content-area table tfoot tr th {
    padding: 23px 30px; }
  .content-area table tbody tr td {
    padding-top: 10px;
    padding-bottom: 10px; }
    .content-area table tbody tr td:first-child {
      padding-left: 30px; }
    .content-area table tbody tr td:last-child {
      padding-right: 30px; } }
