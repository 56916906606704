.page-writepost {
  padding-top: 68px;
  padding-bottom: 100px; }
  .page-writepost-header {
    padding-bottom: 30px;
    margin-bottom: 31px; }
    .page-writepost-header h2 {
      text-align: center;
      padding: 0 15px;
      margin-bottom: 37px; }
    .page-writepost-header .post-video-iframe {
      max-width: 610px; }
      .page-writepost-header .post-video-iframe iframe {
        max-width: 610px;
        height: 343px;
        -webkit-border-radius: 18px;
        -moz-border-radius: 18px;
        border-radius: 18px;
        background-clip: padding-box; }
    .page-writepost-header .post-video-play {
      display: none; }
    .page-writepost-header .post-video-title {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100%;
      font-weight: 600;
      font-size: 18px;
      color: #18B8C4;
      text-align: center; }
  .page-writepost-picture {
    display: block;
    width: 50px;
    height: 50px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
    margin-bottom: 5px; }
  .page-writepost-buttons {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
  .page-writepost-button {
    cursor: pointer;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 2px dashed #ADADAD;
    padding-top: 16px;
    padding-bottom: 12px; }
    .page-writepost-button .btn-icon {
      margin-bottom: 13px; }
    .page-writepost-button .text {
      max-width: 100%;
      flex-basis: 100%;
      font-weight: 600;
      font-size: 18px;
      color: #18B8C4;
      line-height: 1.5;
      text-align: center; }
    .page-writepost-button input[type='file']:not(.dropzone-input) {
      display: none; }
    .page-writepost-button:hover, .page-writepost-button.active {
      border-color: #18B8C4;
      background-color: #E7F8F8; }
  .page-writepost-preview {
    position: relative;
    max-width: 640px;
    flex-basis: 640px;
    text-align: center;
    margin: 30px auto 15px; }
    .page-writepost-preview img {
      width: 100%;
      height: auto;
      -webkit-border-radius: 18px;
      -moz-border-radius: 18px;
      border-radius: 18px;
      background-clip: padding-box; }
    .page-writepost-preview-close {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1; }
    .page-writepost-preview-youtube {
      -webkit-border-radius: 18px;
      -moz-border-radius: 18px;
      border-radius: 18px;
      background-clip: padding-box; }
  .page-writepost-error {
    display: none;
    font-size: 14px;
    color: #FF0000;
    margin-top: 15px; }
  .page-writepost-video {
    position: relative;
    max-width: 610px;
    flex-basis: 610px;
    width: 100%;
    background-color: #F8FFFF;
    border: 2px dashed #18B8C4;
    margin: 30px auto 15px; }
    .page-writepost-video-close {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1; }
    .page-writepost-video-input {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100%;
      z-index: 1; }
    .page-writepost-video.sys {
      display: table;
      border-color: transparent; }
      .page-writepost-video.sys .page-writepost-youtube-input,
      .page-writepost-video.sys .page-writepost-vimeo-input {
        display: none; }
    .page-writepost-video.sys-full {
      display: block; }
      .page-writepost-video.sys-full .page-writepost-preview {
        width: 100%; }
  .page-writepost-preloader {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    z-index: 2; }
    .page-writepost-preloader.sys {
      display: flex; }
  .page-writepost-tab {
    max-width: 20%;
    flex-basis: 20%;
    padding-right: 15px;
    padding-left: 15px; }
  .page-writepost-box {
    display: none; }
    .page-writepost-box.sys {
      display: block; }
    .page-writepost-box-image {
      margin-top: 45px; }
    .page-writepost-box-youtube {
      margin-top: 45px; }
      .page-writepost-box-youtube .page-writepost-preview-youtube, .page-writepost-box-youtube .post-video-iframe iframe {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background-clip: padding-box; }
    .page-writepost-box-vimeo {
      margin-top: 45px; }
      .page-writepost-box-vimeo .page-writepost-preview-vimeo, .page-writepost-box-vimeo .post-video-iframe iframe {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background-clip: padding-box; }
    .page-writepost-box-audio-container {
      position: relative;
      margin-top: 45px; }
    .page-writepost-box-audio .page-writepost-audio-input {
      position: absolute;
      left: 50%;
      -ms-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      top: 179px;
      max-width: 550px;
      width: 100%;
      z-index: 1; }
    .page-writepost-box-audio #page-writepost-audio-url.hys {
      display: none; }
    .page-writepost-box-audio.show-player #dzupload-audio {
      max-height: 217px;
      overflow: hidden; }
    .page-writepost-box-audio.show-player .page-writepost-audio-input {
      display: none; }
    .page-writepost-box-audio.show-player .music-delete {
      display: block; }
    .page-writepost-box-ajax {
      width: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      visibility: hidden; }
    .page-writepost-box .post-video-thumbnail {
      min-height: 276px; }
  .page-writepost #page-writepost-image .page-writepost-picture {
    background-image: url("../img/icons/image.svg"); }
  .page-writepost #page-writepost-youtube .page-writepost-picture {
    background-image: url("../img/icons/youtube.svg"); }
  .page-writepost .form-group {
    max-width: 100%;
    flex-basis: 100%;
    padding: 0;
    margin-bottom: 34px; }
    .page-writepost .form-group:before, .page-writepost .form-group:after {
      left: 0; }
    .page-writepost .form-group:after {
      top: 3px; }
    .page-writepost .form-group.active:before, .page-writepost .form-group.active:after, .page-writepost .form-group.has-error:before, .page-writepost .form-group.has-error:after, .page-writepost .form-group.has-value:before, .page-writepost .form-group.has-value:after {
      width: 100%;
      left: 0; }
    .page-writepost .form-group.form-radio {
      margin-bottom: 10px; }
  .page-writepost .form-label {
    left: 15px; }
  .page-writepost .form-error {
    left: 15px; }
  .page-writepost .form-control, .page-writepost .form-label {
    font-size: 20px; }
  .page-writepost.float-toolbar .toolbar {
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translateX(-50%); }
  .page-writepost.float-toolbar .writty-content {
    padding-top: 94px; }
  .page-writepost .btn-sendpost {
    margin: 0 auto; }
  .page-writepost .chk-container {
    flex-wrap: wrap; }
    .page-writepost .chk-container, .page-writepost .chk-container a {
      font-size: 16px; }
    .page-writepost .chk-container span:not(.checkmark) {
      font-size: 16px;
      color: #ADADAD;
      margin-left: 5px; }
      .page-writepost .chk-container span:not(.checkmark):before, .page-writepost .chk-container span:not(.checkmark):after {
        display: inline-block;
        font-size: 16px;
        color: #ADADAD; }
  .page-writepost .chk-content {
    margin-left: 45px; }
    .page-writepost .chk-content #chamlist {
      margin-top: 30px; }
      .page-writepost .chk-content #chamlist .chamlist-error {
        display: none;
        color: #FF0000; }
      .page-writepost .chk-content #chamlist.has-error .chamlist-error {
        display: block;
        margin-bottom: 20px; }
      .page-writepost .chk-content #chamlist.has-error .chk-container .checkmark {
        border-color: #FF0000; }
  .page-writepost .chk-parent {
    max-width: 1170px;
    margin: 0 auto; }
    .page-writepost .chk-parent .form-radio:first-child .chk-content {
      display: block; }
  .page-writepost .tooltip-parent {
    max-width: 1170px;
    margin: 0 auto 30px; }

/*audio*/
#music-cover-upload {
  display: none; }

.music-player {
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
  display: none;
  flex-wrap: wrap;
  max-width: 792px;
  width: 100%;
  background-color: #E7F8F8;
  padding: 10px;
  margin: 0 auto;
  z-index: 90; }
  .music-player.sys {
    display: flex; }

.music-cover {
  position: relative;
  max-width: 194px;
  flex-basis: 194px;
  background-color: #FFF;
  margin-right: 10px; }

.music-delete {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1; }

.music-changecover {
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 10px; }
  .music-changecover.btn {
    max-width: 90%;
    min-width: 138px;
    height: 34px; }
    .music-changecover.btn .text {
      font-size: 14px;
      line-height: 1.2; }

.music-content {
  max-width: calc(100% - 204px);
  flex-basis: calc(100% - 204px);
  background-color: #FFF;
  padding-top: 25px; }
  .music-content-artist, .music-content-title {
    font-size: 18px;
    color: #666666;
    text-align: center; }
  .music-content-artist {
    margin-bottom: 10px; }
  .music-content-title {
    font-weight: 600; }
  .music-content-player {
    margin-top: 19px; }

.music-link {
  margin-top: 26px; }
  .music-link a {
    display: flex !important;
    align-items: center;
    justify-content: center !important;
    font-size: 18px;
    font-weight: 600;
    color: #647AA0;
    line-height: 21px; }
    .music-link a img {
      margin-right: 9px; }

.mejs__container {
  background: transparent; }

.mejs__controls {
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 386px;
  height: auto;
  background: none !important;
  background-image: none !important;
  margin: 0 auto; }

.mejs__skip-back-button {
  order: 1; }

.mejs__play, .mejs__pause {
  order: 2; }

.mejs__jump-forward-button {
  order: 3; }

.mejs__mute, .mejs__unmute {
  order: 5; }

.mejs__time-rail {
  order: 6; }

.mejs__button button {
  margin: 0; }
  .mejs__button button svg {
    display: none !important; }

.mejs__play, .mejs__pause {
  width: 54px;
  height: 54px; }
  .mejs__play button, .mejs__pause button {
    width: 54px;
    height: 54px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto; }

.mejs__play button {
  background-image: url("../img/icons/audio/button-play.svg"); }

.mejs__pause button {
  background-image: url("../img/icons/audio/button-pause.svg"); }

.mejs__jump-forward-button, .mejs__skip-back-button {
  width: 40px;
  height: 35px; }
  .mejs__jump-forward-button button, .mejs__skip-back-button button {
    width: 40px;
    height: 35px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto; }

.mejs__skip-back-button {
  margin-right: 18px; }
  .mejs__skip-back-button button {
    background-image: url("../img/icons/audio/button-backward.svg"); }

.mejs__jump-forward-button {
  margin-left: 18px;
  margin-right: 30px; }
  .mejs__jump-forward-button button {
    background-image: url("../img/icons/audio/button-forward.svg"); }

.mejs__mute, .mejs__unmute {
  width: 35px;
  height: 35px; }
  .mejs__mute button, .mejs__unmute button {
    width: 35px;
    height: 35px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto; }

.mejs__mute button {
  background-image: url("../img/icons/audio/button-volume.svg"); }

.mejs__unmute button {
  background-image: url("../img/icons/audio/button-mute.svg"); }

.mejs__horizontal-volume-slider {
  display: none !important; }

.mejs__currenttime, .mejs__duration {
  position: absolute;
  font-size: 18px;
  color: #666666; }

.mejs__currenttime {
  left: 0;
  bottom: -20px; }

.mejs__duration {
  right: 0;
  bottom: -20px; }

.mejs__time-rail {
  max-width: calc(100% - 96px);
  flex-basis: calc(100% - 96px);
  height: auto;
  padding-top: 0;
  margin: 22px 0 0; }

.mejs__time-buffering, .mejs__time-current, .mejs__time-loaded, .mejs__time-total {
  height: 12px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box; }

.mejs__time-total {
  background-color: #B9EAED;
  margin: 0; }

.mejs__time-loaded {
  background-color: #B9EAED; }

.mejs__time-current {
  background-image: linear-gradient(to right, #B9EAED, #9BDEE2, #7CD1D8, #56C5CE, #18B8C4); }

.mejs__time-handle {
  border: none; }

.mejs__time-hovered {
  display: none; }

.mejs__time-handle-content {
  top: -6.5px;
  left: -6.5px;
  width: 24px;
  height: 24px;
  background-color: #18B8C4;
  border: none;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  background-clip: padding-box;
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.mejs__time-float {
  display: none !important; }

/* dropzone */
.dropzone {
  position: relative;
  margin: 0 auto; }
  .dropzone-dict {
    display: none; }
  .dropzone .dz-message {
    margin: 18px 0; }
  .dropzone .dz-preview {
    width: 100%;
    min-height: 215px;
    margin: 0; }
    .dropzone .dz-preview.dz-error .dz-error-message {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center; }
    .dropzone .dz-preview .dz-image {
      width: auto;
      height: auto;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background-clip: padding-box; }
      .dropzone .dz-preview .dz-image img {
        max-width: 100%;
        width: 100%;
        height: auto; }
    .dropzone .dz-preview .dz-progress {
      display: none; }
    .dropzone .dz-preview .dz-remove {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 25px;
      z-index: 990; }
      .dropzone .dz-preview .dz-remove img {
        display: inherit;
        cursor: pointer; }
    .dropzone .dz-preview .dz-error-message {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-weight: 600;
      font-size: 18px;
      color: #FD3D5E;
      line-height: 1.5;
      background: none;
      background-color: #FFB2B2;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      background-clip: padding-box;
      opacity: 1; }
      .dropzone .dz-preview .dz-error-message .dz-error-icon {
        display: block;
        margin-bottom: 13px; }
      .dropzone .dz-preview .dz-error-message:after {
        display: none; }
    .dropzone .dz-preview .dz-error-mark {
      display: none; }

.dz-preloader {
  flex-direction: column;
  top: -2px;
  left: 50%;
  max-width: 792px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border: 2px dashed #18B8C4;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 10px 20px;
  z-index: 1002; }
  .dz-preloader img {
    margin-bottom: 20px; }
  .dz-preloader p {
    text-align: center; }

.dz-clickable {
  padding: 0;
  margin-bottom: 20px;
  background-color: #F8FFFF;
  border: 2px dashed #18B8C4; }
  .dz-clickable#dzupload-picture {
    max-width: 610px; }
  .dz-clickable#dzupload-audio {
    max-width: 792px;
    padding: 2.5px 0; }
    .dz-clickable#dzupload-audio .dz-defaultcontent-button.btn {
      margin-bottom: 92px; }

.dz-details {
  display: none; }

.dz-started {
  pointer-events: none; }

.dz-max-files-reached {
  pointer-events: none;
  border-color: transparent; }

.dz-remove {
  pointer-events: auto; }

.dz-defaultcontent {
  background-color: #F8FFFF; }
  .dz-defaultcontent-icon {
    margin-top: 4px;
    margin-bottom: 10px; }
  .dz-defaultcontent-title {
    font-weight: 500;
    font-size: 18px;
    color: #18B8C4;
    margin-bottom: 13px; }
  .dz-defaultcontent-button.btn {
    max-width: 296px;
    height: 50px;
    margin-bottom: 18px; }
  .dz-defaultcontent-text {
    font-size: 14px; }

@media screen and (min-width: 10px) {
  .page-writepost-buttons #page-writepost-image {
    margin-bottom: 30px; } }

@media screen and (min-width: 992px) {
  .page-writepost-buttons #page-writepost-image {
    margin-bottom: 0; } }

@media screen and (max-width: 1199px) {
  .music-content {
    max-width: 100%;
    flex-basis: 100%; }
  .page-writepost-button .text {
    font-size: 15px;
    line-height: 1.4; } }

@media screen and (max-width: 991px) {
  .page-writepost-button {
    min-height: 123px; } }

@media screen and (max-width: 768px) {
  .page-writepost-button {
    padding-left: 15px;
    padding-right: 15px; }
  .page-writepost-tab {
    max-width: 33.33333%;
    flex-basis: 33.33333%;
    margin-bottom: 15px; }
  .page-writepost-box .form-control-normal {
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px; } }

@media screen and (max-width: 575px) {
  .page-writepost-tab {
    max-width: 50%;
    flex-basis: 50%; } }
